@import 'styles/functions';
@import 'styles/variables';

.sort {
  height: setUnit(44);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  background-color: $white;
  border: 2px solid $light-gray;
  color: $text-color;
  text-align: right;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.18px;
  margin-right: 20px;

  span {
    font-weight: 600;
  }

  &:hover {
    border-color: $text--light-gray-color;
  }

  .icon {
    transition: transform 0.15s;
    &.asc {
      transform: rotate(180deg);
    }

    &.desc {
      transform: rotate(0deg);
    }

    svg {
      vertical-align: middle;
    }
  }
}

.popup {
  padding: 0 !important;
  left: 10.75rem;

  .popupInner {
    padding: setUnit(20) setUnit(24) 0;
  }
}
