@import 'styles/functions';
@import 'styles/variables';

.clientFavorites {
  min-height: 15.625rem;
  max-height: calc(100vh - 335px);
  overflow-y: auto;
  padding: 0 40px;

  .loaderContainer {
    min-height: 200px;
    display: flex;
    align-items: center;
    .loader {
      display: inline-flex;
      margin: setUnit(25);
      font-size: setUnit(8);
    }
  }

  .clientFavoritesContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .searchInput {
      [class*='inputHolder'] {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        background-color: $background-grey-2;
        border-radius: 8px;

        input {
          padding-left: 45px !important;
        }

        svg {
          position: relative;
          right: 2px;
          bottom: 3px;
        }
      }
      input {
        height: 52px;
        background-color: $background-grey-2;
        border: none !important;
      }
    }

    .clientFavItemWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .container {
        display: flex;
        align-items: center;
        gap: 12px;
        .avatar {
          width: 40px;
          height: 40px;
          font-size: 16px;
        }
        .textInfo {
          display: flex;
          flex-direction: column;
          .name {
            font-size: 16px;
            font-weight: 500;
          }
          .count {
            font-size: 14px;
            font-weight: 400;
            color: $dark-color;
            opacity: 0.8;
            .bold {
              font-weight: 600;
            }
          }
        }
      }

      .arrowRight {
        svg {
          width: 24px;
          height: 24px;
          path {
            stroke: $text-gray-color;
          }
        }
      }
    }
  }
}
