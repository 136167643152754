@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.selectedPartners {
  $height: 100;
  @include box-shadow;

  position: fixed;
  bottom: setUnit(-$height);
  width: 100%;
  background: #ffffff;
  height: setUnit($height);
  transition: bottom 0.1s ease;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 10;

  &.active {
    bottom: 0;
  }

  .cancel {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: $dark-color;
    border-bottom: 1px solid transparent;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      border-color: $dark-color;
    }
  }

  .btnHidden {
    visibility: hidden !important;
  }

  .continueBtn {
    max-width: 134px;
    width: 100%;
    height: 52px;
    border-radius: 8px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $white;
    }
    &:hover {
      background-color: $secondary-color-hover;
      .title {
        color: $white;
      }
    }
  }
}
