@import 'styles/variables';
@import 'styles/mixins';

.questionContainer {
  padding: 0px !important;
  min-width: 900px !important;
}

.mainContainer {
  @media screen and (max-width: 932px) {
    width: fit-content;
  }
}

.header {
  background: transparent !important;
  padding: 0px !important;
}

.documentPage {
  margin: 20px 0 0px 0px !important;

  canvas {
    border-radius: 0.5rem !important;
  }

  .pdfPage {
    z-index: 1;
    width: fit-content;
    margin: 0 auto;
  }
}

.pageScrollwiseDocument {
  margin-bottom: -15px !important;
  padding: 20px 0 16px 0px !important;
}

.editorWithBundle {
  display: flex;
}

.editorPdfDocument {
  margin-left: 19.85%;
}

.pdfDocumentClass {
  min-height: unset;
}

div > .webViewPageContainer {
  height: calc(100dvh - 100px) !important;
}

@media screen and (max-width: $tablet-horizontal) {
  .mainContainer {
    margin-top: 4.5rem;
  }

  .editorWithBundle {
    gap: 20px;
  }

  .editorPdfDocument {
    margin-left: 25%;
  }
}

@media screen and (max-width: 960px) {
  .mainContainer {
    margin-top: 4.2rem;
  }
}
