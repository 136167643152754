@import 'styles/functions';
@import 'styles/variables';

.emptyMessagesContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;

  .createSession {
    margin-top: 1rem;
    button {
      height: 52px;
      border-radius: 8px;
      width: 350px;
    }
  }
}
