@import 'styles/functions';
@import 'styles/variables';

.emailTemplateHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: setUnit(16);

  .headerText {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    gap: setUnit(16);

    button {
      border-radius: 100px;
      width: 80px;

      .btnTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: setUnit(4);

        .plusIcon {
          display: flex;
        }
      }
    }

    .searchInput {
      width: 220px;

      input {
        border-radius: 100px !important;
      }
    }
  }
}

.emailTemplateTabs {
  [class*='ant-tabs-tab-btn'] {
    text-shadow: none !important;
  }
  [class*='ant-tabs-content'] {
    padding: setUnit(8) 0;
  }

  &.singleTab {
    [class*='ant-tabs-ink-bar'] {
      background: transparent !important;
    }
  }
}
