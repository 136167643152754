@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.agentItem {
  cursor: pointer;
  .agentHeader {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--Grayscale-20, #e8e9ea);

    .agentInfo {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 2px;
      .avatar {
        width: 24px;
        height: 24px;
        margin-right: 1rem;
      }
      .agentDetails {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
      .agentName {
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
        letter-spacing: -0.18px;
      }
      .clientsCount {
        color: var(--Grayscale-50, #747475);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.18px;
      }
    }
  }
  .avatar {
    width: 1.5rem;
    height: 1.5rem;
    .avatarPlaceholder {
      font-size: 10px;
    }
  }
}

.clientList {
  padding-left: 38px;
}

.clientItem,
.allOption {
  .checkbox {
    label {
      margin: 0;
    }
    margin-right: 0.25rem;
  }
}

.clientAddressWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.addressList {
  margin-top: 14px;
  .addressRow {
    display: flex;
    justify-content: space-between;
    padding-left: 32px;
  }
}

.rightSection {
  cursor: pointer;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
