@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.mainPageContent {
  padding: 0px !important;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    max-height: calc(100vh - 150px);
  }
  .heading {
    position: absolute;
    top: 38px;
    @media screen and (max-height: 780px) {
      top: 20px;
    }
    color: $text-color;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.4px;
    width: 100%;
  }
  .content {
    position: absolute;
    top: calc(50% - 275px);
    left: calc(50% - 410px);
    .cardsContainer {
      display: grid;
      grid-template-columns: repeat(3, 260.279px);
      gap: 23.72px;
      height: fit-content;
      margin-top: 103px;
      .card {
        cursor: pointer;
        width: 260.279px;
        height: 160px;
        border-radius: 10px;
        background-color: $white;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 32px 80px 0px rgba(0, 0, 0, 0.04), 0px 8px 30px 0px rgba(0, 0, 0, 0.06);
        color: $text-color;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.33px;
        transition: $transition;
        position: relative;
        .subtitle {
          color: $text--light-gray-color;
          margin: 0px;
          font-size: 12px;
          position: absolute;
          bottom: 18px;
        }
        &.disabled {
          svg {
            transition: none !important;
            path {
              stroke: $text-gray-color !important;
            }
          }
          &:hover {
            box-shadow: 0px 32px 80px 0px rgba(0, 0, 0, 0.04), 0px 8px 30px 0px rgba(0, 0, 0, 0.06) !important;
            svg {
              transform: none !important;
            }
          }
        }
        svg {
          transition: $transition;
        }
        &:hover {
          box-shadow: 0px 32px 80px 0px rgba(0, 0, 0, 0.1), 0px 8px 30px 0px rgba(0, 0, 0, 0.2);
          svg {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
