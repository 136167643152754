.custom-radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: absolute;

  border: 1px solid;

  input {
    // height: 1.4cqw;
    width: 100%;
    height: 100%;
  }
}

.radioFieldTooltip {
  width: fit-content !important;
  height: fit-content !important;
}
