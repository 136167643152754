@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.statCard {
  text-align: center;
  .cardLabel {
    font-weight: 600;
    font-size: setUnit(16);
    line-height: setUnit(16);
    text-align: center;
    letter-spacing: -0.35px;
    color: $dark-color;
    margin-bottom: setUnit(9);
    display: flex;
    align-items: center;
    justify-content: center;
    .statIcon {
      margin-left: setUnit(9);
    }
  }
  .cardStat {
    font-weight: 700;
    font-size: setUnit(38);
    line-height: setUnit(38);
    text-align: center;
    letter-spacing: -0.35px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    &.notClickable {
      cursor: unset;
    }
    @media (min-width: 1200px) {
      font-size: setUnit(48);
      line-height: setUnit(48);
    }
    small {
      font-size: setUnit(22);
      line-height: setUnit(24);
      letter-spacing: -1px;
      margin-left: setUnit(4);
      margin-bottom: setUnit(6);
      @media (min-width: 1200px) {
        font-size: setUnit(26);
        line-height: setUnit(28);
      }
    }
  }
  &.yellow {
    .cardStat {
      color: $orange;
    }
  }
  &.lightBlue {
    .cardStat {
      color: $light-blue;
    }
  }
  &.indigo {
    .cardStat {
      color: $purple;
    }
  }
  &.green {
    .cardStat {
      color: $hover-green;
    }
  }
}
