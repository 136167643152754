@import 'styles/functions';
@import 'styles/variables';

.container {
  .question {
    margin: setUnit(24) 0 setUnit(64);
  }
  .contentWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
    .content {
      flex: 1 100%;
      max-width: setUnit(612);
      width: 100%;
    }
  }

  .containerFormToLink {
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: setUnit(16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $light-gray;

    .left {
      display: flex;
      align-items: center;
      gap: setUnit(12);

      .formName {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .deleteIcon {
      cursor: pointer;
    }
  }

  .linkFormsBtn {
    height: 3.25rem;
    width: 100%;
    border-radius: 8px;
  }
}

.modalContent {
  margin-top: setUnit(40);
  max-height: calc(100vh - #{setUnit(210)});
  overflow: auto;
}
