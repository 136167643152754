@import 'styles/functions';
@import 'styles/variables';

.multiSelectWrapperClassName {
  margin: 24px 0 !important;
}

.margin {
  margin-right: 16px !important;
}

.cross {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.saveSearchButton {
  .btn {
    width: setUnit(44);
    height: setUnit(44);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 2px solid;
    border-color: $light-gray;
    cursor: pointer;
    position: relative;

    svg {
      margin-top: 0.4rem;
    }

    &:hover {
      border-color: $text--light-gray-color;
    }

    &.active {
      background-color: $primary-color-light;
      border-color: $primary-color;
      &:hover {
        border-color: $hover-red;
      }
    }

    &.disabled {
      cursor: not-allowed;
      border-color: $grey-color;
      background-color: $white;
      &:hover {
        border-color: $grey-color;
      }
    }
  }
}

.savedSearch {
  svg {
    fill: $dark-color;
  }
}

.popup {
  border-radius: setUnit(24);
  max-width: setUnit(420) !important;
  width: 100%;

  .popupInner {
    padding: 40px 40px 32px;
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: setUnit(24);
      &.noMargin {
        margin-bottom: setUnit(16);
      }

      .icon {
        margin-right: setUnit(5);
        svg {
          vertical-align: middle;
        }
      }
    }
    .subText {
      text-align: center;
      color: $text-color;
    }

    .label {
      color: $text-color;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.09px;
      margin-top: 20px;
    }

    .button {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
      cursor: pointer;
      .add {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          position: relative;
          top: 3px;
        }
      }
      .label {
        color: $text-color;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.09px;
        margin: 0;
      }
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: -0.33px;
      color: $text-color;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;
      gap: 0 24px;
      .loader {
        font-size: setUnit(3);
      }
      .action {
        height: setUnit(52);
        flex: 1;
        transition: $transition;
        .buttonTitle {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.18px;
        }
        &.cancel {
          background-color: $background-grey-2;
          color: $text-color;
          &:hover {
            background-color: $light-gray;
          }
        }
        &.save {
          &:hover {
            background-color: $secondary-color-hover;
          }
        }
      }
    }
  }
}

.multiSelectSectionDropdown {
  max-height: 180px;
  overflow-y: auto !important;
  margin-top: 50px !important;
}
