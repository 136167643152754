@import 'styles/variables';
@import 'styles/mixins';

.addOptionIcon {
  display: block;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);

  cursor: pointer;
  &:hover {
    filter: brightness(0.85) contrast(1.2);
  }
}
