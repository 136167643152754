@import 'styles/functions';
@import 'styles/variables';

$actions-container-height: 76px;

.skeleton {
  display: block;
  margin-bottom: 8px;
  width: 140px !important;
  height: 16px;
}

.actionsContainer {
  z-index: 10;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  right: 32px;
  left: 32px;
  width: 100%;
  padding: 0.875rem 1.5rem;

  .favHeader {
    display: flex;
    align-items: center;
    gap: 12px;
    .avatar {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }
    .textInfo {
      display: flex;
      flex-direction: column;
      .name {
        font-size: 16px;
        font-weight: 500;
      }
      .count {
        font-size: 14px;
        font-weight: 400;
        color: $dark-color;
        opacity: 0.8;
        .bold {
          font-weight: 600;
        }
      }
    }
  }

  .total {
    font-weight: bold;
  }

  .actions {
    display: flex;
    align-items: center;

    .noBorder {
      border: none;
    }

    .multiple {
      border: none;
      transition: $transition;
    }
    .active {
      border: 1px solid;
      border-color: $primary-color;
      background-color: $primary-color-light;
      border-radius: 12px !important;
    }
  }
}

.clientProperties {
  position: relative;
  padding: setUnit(24);
  padding-top: 0;
  height: calc(100% - #{$actions-container-height});
  scroll-margin: 80px;

  .pendingWrapper {
    height: calc(100vh - #{setUnit(287)});
    @media (min-width: $tablet) {
      height: 100%;
    }
  }
  .pagination {
    padding: setUnit(16) 0;
  }

  .mlsDisclaimer {
    margin-top: auto;
    text-align: left;
  }

  .content {
    text-align: left;

    p {
      margin: 0;
    }
  }
}
