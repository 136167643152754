@import 'styles/functions';
@import 'styles/variables';

.ctaPageHeader {
  width: 100%;
  display: flex;
  background: $white !important;
  padding: 0px !important;
  z-index: 98;
  top: 0;
  height: $header-height !important;
  align-items: center;
  .headerContainer {
    height: $header-height;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px !important;
    border-bottom: 1px solid $light-gray;
    background: $white !important;
    @media screen and (max-width: $responsive-menu) {
      padding: setUnit(16) !important;
    }
    @media (min-width: $tablet) {
      position: fixed;
    }
    .loginButton {
      width: setUnit(86);
      cursor: pointer;
      outline: none;
      font-weight: 500;
      font-size: setUnit(14);
      line-height: setUnit(20);
      height: setUnit(44);
      border-radius: 8px;
      text-decoration: none;
      transition: $transition;
      background-color: #252d44;
      color: $white;
      border: none;
      &:hover {
        background-color: #1d4f76;
      }
    }
  }
}

.cta {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &Content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 40px;

    @media (max-width: 768px) {
      margin-top: 70px;
    }

    &Heading {
      color: #000000;
      font-weight: 700;
      font-size: setUnit(32);
      line-height: setUnit(36);
      font-family: $font-family;
      letter-spacing: setUnit(-0.4);
    }

    &Area {
      border-radius: setUnit(20);
      background: var(--Primary-Navy-10, #f4f6fb);
      border-radius: setUnit(16);
      padding: setUnit(32);
      width: setUnit(560);
      height: setUnit(255);
      padding: 32px 28px 39px 28px;

      @media (max-width: 768px) {
        width: 324px;
        height: 295px;
      }

      &Inner {
        display: flex;
        align-items: center;
        border-radius: 16px;
        background: #fff;
        width: 100%;
        height: 100%;
        padding: 10px 24px;

        .text {
          color: var(--REBRAND-Primary-Navy, #252d44);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.09px;
        }

        .contact {
          cursor: pointer;
          color: var(--REBRAND-Secondary-Link-Blue, #2b59da);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.09px;
        }

        .marginShort {
          margin-top: 0em;
          margin-bottom: 0em;
        }
      }
    }
  }
  .ctaFooter {
    background: white;
    footer {
      background: white;
    }
  }
}
