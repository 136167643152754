@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.content {
  .antCollapse {
    background-color: transparent;
    border: none;

    [class*='ant-collapse-item'] {
      border: none !important;
    }

    &Panel {
      background-color: #fff;
      border-radius: 16px !important;
      padding: 20px 20px 32px 20px;

      &Areas {
        padding-bottom: 20px !important;
      }

      &Note {
        padding-bottom: 24px !important;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      [class*='ant-collapse-content-box'] {
        padding: 0 !important;
      }

      [class*='ant-collapse-header'] {
        padding: 0 !important;
      }

      &Open {
        [class*='ant-collapse-header'] {
          display: none !important;
        }
      }

      &Close {
        padding-bottom: 20px !important;
      }

      .sectionHeader {
        color: $text-color;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 20px;

        &Label {
          margin-bottom: 16px !important;
        }
      }

      &Header {
        display: flex;
        align-items: center;

        .iconPlaceholder {
          div {
            display: flex;
          }
        }

        .image {
          height: 40px;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          height: 32px;
          width: 32px;
        }

        .info {
          margin-left: 8px;

          .name {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: $text-color;
          }

          .subHeading {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: $dark-color;
            width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .valuesWrapper {
        div:nth-of-type(1) {
          padding-left: 6px;
        }

        div:nth-of-type(2) {
          border: 2px solid $border-light-gray-2 !important;
          border-radius: 12px !important;
        }
      }

      .textArea {
        font-size: 16px;
        line-height: 20px;
        border: 1px solid $light-grey-color;
        border-radius: 12px;
        transition: all 0.2s;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 14px;
        padding-bottom: 14px;
        height: 112px !important;
        min-height: 112px !important;
        max-height: 112px !important;

        &::placeholder {
          font-size: 16px !important;
          color: $text--light-gray-color !important;
          font-weight: 400;
          line-height: 20px !important;
          letter-spacing: -0.18px;
        }

        &:focus {
          border-color: $text-gray-color;
          box-shadow: none !important;
        }
      }

      .footNote {
        margin-top: 16px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: $text-gray-color;

        svg {
          margin-right: 6px;
        }
      }
    }
  }

  .addMoreDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $text-color;
    margin-top: 24px;
    position: relative;
    background-color: $white;
    border-radius: 16px;
    width: 100%;

    &DD {
      padding: 12px;
      width: 100%;
      cursor: pointer;
    }

    .box {
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        margin-left: 8px;
      }
    }
  }
}

.menuContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto) auto;
    gap: 6px;
    border-radius: 16px !important;
    padding: 20px !important;
    width: 550px;
    background-color: $white;

    .menuItemSelected {
      opacity: 0.3 !important;
      pointer-events: none !important;
      cursor: default !important;
    }

    .menuItem {
      display: flex;
      align-items: center;
      border-radius: 12px;
      padding: 10px 0 10px 0;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 32px;
        width: 32px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .labelBox {
        margin-left: 8px;
        .label {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          color: $dark-color;
        }

        .subText {
          margin-top: 4px;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: $text-gray-color;
        }
      }
    }

    .footer {
      display: flex;
      border-radius: 12px;
      background-color: $background-gray;
      grid-column: 1 / -1;
      padding: 16px;

      .text {
        margin-left: 8px;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        color: $dark-color;
      }
    }
  }
}
