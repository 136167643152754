@import 'styles/functions';
@import 'styles/variables';

.threadOption {
  display: flex;
  column-gap: 9px;
  margin-left: 5px;
  align-items: center;

  .threadOptionIcon {
    margin-top: 3px;
    position: relative;
    top: 1px;
  }
}
