@import 'styles/functions';
@import 'styles/variables';

.footerContainer {
  &Hide {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    height: 2px;
    width: calc(100% - 80px);
    margin: auto;
    background-color: $light-gray;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background: $background-ligh-gray-3;
    width: 100%;
    height: $responsive-footer-height;
    padding: setUnit(12) setUnit(24);
    @media (min-width: $tablet) {
      height: $footer-height;
      padding: setUnit(12) setUnit(40);
    }
  }

  .links {
    ul {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      list-style-type: none;
      li {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-left: setUnit(16);
          &:before {
            content: '';
            position: relative;
            width: setUnit(4);
            height: setUnit(4);
            border-radius: setUnit(50);
            background-color: $dark-color;
            margin-right: setUnit(16);
          }
        }
        a {
          font-weight: 400;
          font-size: setUnit(14);
          line-height: setUnit(16);
          letter-spacing: setUnit(-0.09);
          color: $dark-color;
          text-decoration: none;
          &:after {
            content: '';
            width: 0;
            height: 1px;
            background-color: $dark-color;
            display: block;
            margin-top: setUnit(4);
            transition: $transition;
          }
          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .copyRight {
    font-weight: 400;
    font-size: setUnit(14);
    line-height: setUnit(16);
    letter-spacing: setUnit(-0.09);
    color: $dark-color;
    margin-top: setUnit(16);
    flex: 0 0 100%;
    @media (min-width: $mobile) {
      flex: 0 0 auto;
      margin-top: 0;
    }
  }
}

.wrapperBackgroundAppointment {
  background: $white !important;
}
