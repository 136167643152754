@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.inputWrapper {
  width: 100%;
  .inputHolder {
    position: relative;
    .icon {
      padding-left: 10px;
      position: absolute;
      right: 10px;
      top: 13px;
      background-color: $white;
    }
  }

  &.textArea {
    height: auto !important;
  }

  &.light {
    @include inputLight;
    position: relative;
    width: 100%;
    .removeBtn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: setUnit(20);
      height: setUnit(20);
      background-color: transparent;
      border: 0;
      outline: none;
      right: setUnit(10);
      transition: all 0.1s;
      cursor: pointer;
      svg {
        width: setUnit(20);
        height: setUnit(20);
      }
      &:hover {
        opacity: 0.85;
      }
    }

    label {
      font-size: setUnit(12);
      line-height: setUnit(16);
      color: $text-gray-color;
      font-weight: 400;
      margin-bottom: setUnit(8);
    }

    .passwordIcon {
      position: absolute;
      cursor: pointer;
      &.text {
        right: setUnit(0);
        top: 50%;
        transform: translateY(-50%);
        svg {
          vertical-align: middle;
          path,
          circle {
            stroke: $text--light-gray-color;
          }
        }
      }

      &.password {
        right: setUnit(0);
        top: 50%;
        transform: translateY(-50%);
        svg {
          vertical-align: middle;
          path {
            stroke: $text--light-gray-color;
          }
        }
      }
    }

    input[type='password'] {
      padding-right: setUnit(50);
    }
    .error {
      color: $error-color;
      font-size: setUnit(12);
      padding-top: setUnit(8);
      &::before {
        content: '\0021';
        font-weight: bold;
        color: $white;
        width: setUnit(14);
        height: setUnit(14);
        display: inline-block;
        background: $error-color;
        text-align: center;
        padding: 0.2em;
        border-radius: 100%;
        font-size: setUnit(12);
        margin-right: 0.5em;
      }
    }
  }

  &.lightFull {
    @include inputLightFull;
    .searchInput {
      padding-left: setUnit(38);
    }
    .searchIcon {
      position: absolute;
      top: setUnit(15);
      left: setUnit(15);
    }
  }

  &.lightRound {
    @include lightRound;

    .passwordIcon {
      position: absolute;
      cursor: pointer;
      &.text {
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        svg {
          vertical-align: middle;
          path,
          circle {
            stroke: $text--light-gray-color;
          }
        }
      }

      &.password {
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        svg {
          vertical-align: middle;
          path {
            stroke: $text--light-gray-color;
          }
        }
      }
    }
  }

  &.lightRounded {
    label {
      @include label;
    }
    input {
      @include lightRounded;
    }
    textarea {
      @include lightRounded;
      height: auto !important;
    }

    .searchInput {
      padding-left: setUnit(38) !important;
    }
    .searchIcon {
      position: absolute;
      top: setUnit(15);
      left: setUnit(15);
    }
  }

  &.full {
    @include inputFull;
    position: relative;
    .removeBtn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: setUnit(20);
      height: setUnit(20);
      background-color: transparent;
      border: 0;
      outline: none;
      right: setUnit(10);
      transition: all 0.1s;
      cursor: pointer;
      svg {
        width: setUnit(20);
        height: setUnit(20);
      }
      &:hover {
        opacity: 0.85;
      }
    }

    .altLabel {
      font-size: setUnit(14);
      color: $border-middle-gray;
      font-weight: 400;
    }
    .passwordIcon {
      position: absolute;
      cursor: pointer;
      &.text {
        right: setUnit(0);
        top: 50%;
        transform: translateY(-50%);
        svg {
          vertical-align: middle;
          path,
          circle {
            stroke: $text--light-gray-color;
          }
        }
      }

      &.password {
        right: setUnit(0);
        top: 50%;
        transform: translateY(-50%);
        svg {
          vertical-align: middle;
          path {
            stroke: $text--light-gray-color;
          }
        }
      }
    }

    input[type='password'] {
      padding-right: setUnit(50);
    }

    .searchInput {
      padding-left: setUnit(38);
      height: setUnit(44);
    }

    .searchIcon {
      position: absolute;
      top: 56%;
      transform: translateY(-50%);
      left: setUnit(15);
    }
  }

  .error {
    @include inputError;
  }
}
