@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.addAnother {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: $dark-color;
  display: flex;

  div {
    cursor: pointer;
  }
}

.serviceArea {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  height: 52px;

  .radius {
    border-radius: 12px;
    margin-left: 12px;
    border: 1px solid $light-grey-color !important;
    padding-left: 16px;
    padding-right: 16px;
    transition: all 0.2s;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.8px;
    letter-spacing: -0.18px;
    color: $text-color;

    &:focus-within {
      border-color: $text-gray-color !important;
    }

    .label {
      color: $text--light-gray-color;
    }

    input {
      border: none;
      outline: none;
      height: 100%;
      width: 44px;

      &::placeholder {
        font-size: 16px;
        color: $text--light-gray-color;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.18px;
      }

      &[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}

.locationsBlock {
  border-radius: 12px !important;
  border: 1px solid $light-grey-color;
  background: $white;
  width: 100%;

  &:focus-within {
    border-color: $text-gray-color;
  }

  input {
    border-bottom: none !important;
    height: initial !important;
    padding: 14px 0px 14px 44px !important;
    border-radius: 12px !important;
  }

  .searchIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50% !important;
    left: 10px !important;
  }
}

.valuesWrapper {
  display: flex;
  align-items: center;
  border-radius: 12px !important;
  border-bottom: none !important;

  div:nth-of-type(2) {
    border-bottom: none !important;
  }
}

.placeholder {
  border-bottom: none !important;
  padding: 14px 0px 14px 44px !important;
  padding-left: 0 !important;
  border-radius: 12px !important;
}

.dropdown {
  bottom: auto !important;
}

.validation {
  font-size: 0.875rem;
  color: $error-color;
  margin-top: 0.1875rem;
}
