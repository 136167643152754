@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';
@import '../../styles.module';

.stageWrap {
  flex-basis: 100%;
  padding: 0 40px;
  height: calc(100vh - $header-height);

  .row {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  .partnerQuestion {
    text-align: center;

    & span {
      max-width: 540px;
      display: inline-block;
    }
  }

  .partnerRow {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .textCol {
    align-self: center;
  }

  .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;
  }

  .partnerHeading {
    color: $text-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.09px;
  }

  .sideNote {
    color: $dark-grey-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.09px;
  }

  .partnerPhoneWrapper {
    div {
      & input {
        border-radius: 8px !important;
      }
    }
  }

  .partnerFormInput {
    &:first-of-type {
      @media (max-width: $mobile) {
        margin-bottom: 16px;
      }
    }

    input {
      border-radius: 8px !important;
      border: 1px solid $light-grey-color !important;
      padding: 14px 16px !important;
      width: 100% !important;

      &::placeholder {
        color: $text-gray-color !important;
        font-feature-settings: 'clig' off, 'liga' off !important;
        font-family: Inter !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 20.8px !important;
        letter-spacing: -0.18px !important;
      }

      &:focus {
        border-bottom-color: $light-grey-color !important;
      }
    }
  }

  .partnerbtnContainer {
    margin: 40px auto 0;
  }
}
