@import 'styles/functions';
@import 'styles/variables';

.searchModalWrapper {
  z-index: 999 !important;
}

.savedSearches {
  margin-top: 0 !important;
}

.drawerInner {
  position: relative;
  margin-top: 32px;
  margin-bottom: 40px;
  .tabs {
    justify-content: space-between;
    div[class~='ant-tabs-nav'] {
      &:before {
        content: none;
      }
      div[class~='ant-tabs-nav-wrap'] {
        margin: 0 setUnit(32);
        div[class~='ant-tabs-tab'] {
          padding: 18px;
        }
      }
    }
  }
}

.tabs {
  justify-content: space-between;
  border-bottom: 2px solid $light-gray;
  div[class~='ant-tabs-nav'] {
    &:before {
      content: none;
    }
    div[class~='ant-tabs-nav-wrap'] {
      margin: 0 setUnit(32);
      border-bottom: 2px solid $light-gray;
    }
  }
}

.opacityLayer {
  backdrop-filter: blur(20px);
  background-color: $white !important;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  max-width: 700px !important;
  max-height: 85vh !important;
  @media screen and (max-height: 1000px) {
    max-height: 95vh !important;
  }
}

.header {
  justify-content: center !important;
  padding-top: 32px !important;
}

.back {
  position: absolute;
  top: -32px;
  left: 40px;
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .avatar {
    width: 64px;
    height: 64px;
    font-size: 27.429px;
  }
  .heading {
    color: $dark-gray-3;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.33px;
    margin-top: 12px;
  }
  .actionButtons {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 8px;
    height: 84px;
    .button {
      cursor: pointer;
      &.disabled {
        cursor: auto !important;
      }
      padding: 10px 12px;
      width: 145px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      .icon {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        &.blue {
          background: rgba(146, 140, 218, 0.08);
        }
        &.red {
          background: rgba(255, 87, 109, 0.08);
        }
        &.yellow {
          background: rgba(251, 145, 58, 0.08);
        }
        svg {
          position: relative;
          top: 2px;
        }
      }
      .buttonText {
        color: $text-color;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 100% */
        letter-spacing: -0.09px;
        span {
          color: $text--light-gray-color;
        }
      }
    }
  }
  .text {
    color: $text-gray-color;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.18px;
  }
}

.innerHolder {
  padding: 0 !important;
  overflow: hidden !important;
  @media screen and (max-width: 700px) {
    padding: 0 15px !important;
  }
}
