@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.detailField {
  .input {
    input::placeholder {
      font-size: 16px !important;
      color: $text--light-gray-color !important;
      font-weight: 400;
      line-height: 20px !important;
      letter-spacing: -0.18px;
    }
  }

  input::placeholder {
    font-size: 16px !important;
    color: $text--light-gray-color !important;
    font-weight: 400;
    line-height: 20px !important;
    letter-spacing: -0.18px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .detailLabel {
    margin-bottom: 12px !important;
  }

  .addressInput {
    input {
      padding-left: 13px !important;
      padding-right: 13px !important;
    }
  }

  .validation {
    font-size: 0.875rem;
    color: $error-color;
    margin-top: 0.1875rem;
  }

  .unitApt {
    margin-bottom: 0 !important;

    &Hide,
    &Show {
      button {
        visibility: hidden !important;
        opacity: 0 !important;
        cursor: default !important;
        transition: opacity 0.3s ease-in-out;
      }
    }

    &Show:hover {
      button {
        visibility: visible !important;
        opacity: 1 !important;
        cursor: pointer !important;
        transition: opacity 0.3s ease-in-out;
      }
    }

    label {
      span:nth-of-type(2) {
        display: none;
      }
    }

    input {
      padding-left: 10px;
      height: auto !important;
      margin-top: 2px !important;
      border: 1px solid $border-light-gray-2 !important;
      border-radius: 12px !important;

      &::placeholder {
        font-size: 16px !important;
        color: $text--light-gray-color !important;
        font-weight: 400;
        line-height: 20px !important;
        letter-spacing: -0.18px;
      }

      &:focus {
        border-color: $text-gray-color !important;
      }
    }
  }

  .clientPhoneInput {
    @include lightRounded;
    border-color: $light-grey-color !important;
    transition: all 0.2s !important;
    display: flex;
    align-items: center;

    &Hide,
    &Show {
      button {
        visibility: hidden !important;
        opacity: 0 !important;
        cursor: default !important;
        transition: opacity 0.3s ease-in-out;
      }
    }

    &Show:hover {
      button {
        visibility: visible !important;
        opacity: 1 !important;
        cursor: pointer !important;
        transition: opacity 0.3s ease-in-out;
      }
    }

    &:focus-within {
      border-color: $text-gray-color !important;
    }

    input {
      padding: 0 !important;
      height: auto !important;
      border: none !important;
      margin-top: 2px !important;
    }

    button {
      top: 14px !important;
    }
  }
}
