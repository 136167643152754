@import 'styles/functions';
@import 'styles/variables';

.filterWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: $white;
  border-radius: setUnit(16);

  .searchInput {
    background-color: $white !important;
    border: 1px solid $border-light-gray-2 !important;
  }

  .filterInputShort {
    width: calc(100% - 64px); //52px (width of svg) + 12px (margin)
  }

  .multiSelect {
    &:hover {
      cursor: pointer;
    }

    border-radius: 8px;
    background-color: $background-grey-2;
    height: 52px;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .multiSelectActive {
    border: 1px solid $primary-color;
    background-color: $primary-color-light;
  }

  .activeIcon {
    path {
      stroke: $text-color;
    }
  }
}
