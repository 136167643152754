@import 'styles/functions';
@import 'styles/variables';

.priorToInvitation {
  .iconBox {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      background-color: $white;
      border-radius: 50%;
      padding: 8px;
    }
  }

  .icon,
  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heading,
  .note {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $text-color;
  }

  .note {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: $text-color;

    .bold {
      font-weight: 600;
    }

    .link {
      font-weight: 500;
      color: $link-blue;
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    border-radius: 16px;
    background-color: $white;
    padding: 10px;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .iconLeft {
        border-radius: 50%;
        background-color: rgba(81, 191, 225, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
      }

      .iconRight {
        transform: translateY(3px);
      }

      .text {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: $text-color;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
