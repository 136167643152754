@import 'styles/variables';
@import 'styles/functions';

.taskTitle {
  position: relative;

  .arrowBackIcon {
    position: absolute;
    left: 0;
    display: flex;
    top: 8px;
    cursor: pointer;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .titleText {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
  }

  .subtitle {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: $text-gray-color;

    &.isAggregratedSubtitle {
      justify-content: center !important;
      margin-top: 0.5rem !important;
    }

    .streetAddress,
    .category {
      font-size: 15px;
      font-weight: 500;
      margin: 0;
      text-align: right;
    }
    .seperatorContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 25px;
      margin: 0 0.75rem;

      .seperator {
        width: setUnit(6);
        height: setUnit(6);
        border-radius: 50%;
        background-color: $text-gray-color;
      }
    }
    .transactionClients {
      margin-bottom: 0;
      display: flex;
      .label {
        margin-right: 0.5rem;
      }
      .label,
      div {
        font-size: 15px;
        font-weight: 500;
      }
      .marginLeft {
        margin-left: 0.25rem;
      }
    }
  }
}
