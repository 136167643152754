@import 'styles/functions';
@import 'styles/variables';

.item {
  transition: all 0.1s;
  display: flex;
  align-items: center;
  padding: setUnit(12) setUnit(32);
  cursor: pointer;
  color: $text-color;
  position: relative;

  &:hover {
    background-color: $hover-light-gray;
  }
  .avatarContainer {
    position: relative;
  }

  .onlineStatus {
    position: absolute;
    top: 62%;
    left: 55%;
    display: flex;
    z-index: 2;
    padding: 5px;
    background: white;
    border-radius: 50%;

    div {
      width: setUnit(9);
      height: setUnit(9);
      background-color: $green-color;
      border-radius: 50%;
    }
  }

  .personalTitleSpan,
  .offerTitleSpan {
    padding: setUnit(3) setUnit(6);
    margin-left: setUnit(8);
    color: $text-color;
    border-radius: setUnit(20);
    font-size: setUnit(14);
    line-height: setUnit(16);
    font-weight: 500;
  }

  .name {
    color: $text-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
    letter-spacing: -0.18px;
  }

  .offerTitleSpan {
    background: $yellow-color;
  }

  .personalTitleSpan {
    background: $light-gray;
  }

  &.unread {
    &::before {
      content: '';
      position: absolute;
      background: $primary-color content-box;
      border-radius: 50%;
      width: setUnit(8);
      height: setUnit(8);
      left: setUnit(12);
    }

    .itemContentTitle {
      font-weight: 600;
    }

    .itemContentText,
    .itemContentTitleTime {
      font-weight: 600;
      color: $dark-color;
    }
  }

  .itemContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    flex: 1;

    .itemContentTitle {
      font-size: setUnit(16);
      line-height: setUnit(21);
      display: flex;

      .itemContentSubTitle {
        color: $text-color;
        padding-top: 3px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
      }

      .itemContentTitleTime {
        margin-left: auto;
        color: $text-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
        white-space: nowrap;
        padding-top: 2px;
      }
    }

    .itemContentText {
      padding-top: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;
      color: $dark-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.09px;

      .tagHyperLink {
        background-color: #2b59da1a;
        padding: setUnit(2);
        border-radius: setUnit(4);

        span {
          color: $link-blue;
          font-weight: 500;
          font-size: setUnit(14);
          line-height: setUnit(16);
        }
      }
    }
  }

  .avatars {
    position: relative;
    margin-right: setUnit(16);

    .avatarWrapper {
      margin-left: setUnit(-35);
      &:first-child {
        margin-left: 0;
      }
      &.isNotSingle {
        left: 10px;
        &:first-child {
          top: -10px !important;
          left: 0px !important;
        }
      }
    }

    .avatar {
      width: 40px;
      height: 40px;
      font-size: 17.143px;

      &.isNotSingle {
        width: 28px;
        height: 28px;
        font-size: 12px;
      }
    }

    .overflow {
      width: 28px;
      height: 28px;
      top: 5px;
      left: 8px;
    }
  }
}
