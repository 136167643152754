@import 'styles/functions';
@import 'styles/variables';

.assgnInfo {
  position: relative;
  .marginBottom {
    margin-top: 0 !important;
    margin-bottom: setUnit(8);
  }
  .assignLabel {
    font-size: 14px;
    font-weight: 400;
    color: $dark-color;
    opacity: 80%;
    margin-bottom: 0;
    margin-left: setUnit(47);
    &.marginLeft {
      margin-left: 56px;
    }
    .bold {
      font-weight: 500;
    }
  }
  &:hover {
    .editIcon {
      visibility: visible;
    }
  }

  .editIcon {
    visibility: hidden;
    border-radius: 50%;
    padding: 0.5rem;
    display: flex;
    background: $background-grey-2;
    position: absolute;
    right: 5px;
    top: 0px;
    cursor: pointer;
  }

  .footerBtns {
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      border-radius: setUnit(8);
      font-weight: 500;
      border-width: 1px !important;
      &.ant-btn[disabled] {
        opacity: 0.7 !important;
      }
    }
  }
}
