@import 'styles/functions';
@import 'styles/variables';

.timeInput {
  margin-right: -1.75rem;
  width: setUnit(190);
  display: flex;
  align-items: center;

  .inputTime {
    border: 1px solid $light-grey-color;
    padding: 0.5rem;
    width: 3.8rem;
    border-radius: 8px !important;
    height: 48px;
    margin: 0 setUnit(8);
  }

  .timeSelect {
    .timeDropdown {
      padding: 0;
      background: $white;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      width: 71px !important;
      div[class~='ant-select-item-option'] {
        padding: 12px 16px;
        display: flex;
        flex-flow: row-reverse;
        position: relative;
        div[class~='ant-select-item-option-content'] {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.09px;
          color: $text-color;
          display: flex;
          align-items: center;
        }
        &[class~='ant-select-item-option-selected'] {
          background-color: $background-grey-2;
          [class~='ant-select-item-option-state'] {
            border-color: $primary-color;
            background-color: $primary-color;
            color: $white;
          }
          [class~='ant-select-item-option-content'] {
            &:before {
              content: none;
            }
          }
        }
      }
    }
  }
}

.timezoneContainer {
  position: absolute;
  right: 2px;
  bottom: -40px;
  z-index: 10;
}
.timezonePopover {
  [class*='options'] {
    top: -15px !important;
    left: -75px !important;
    min-width: 270px !important;
    height: 200px !important;
    overflow-y: scroll;
  }
}
.timezoneTitle {
  font-weight: 400 !important;
  font-size: 14px;
  color: $text-gray-color;
  padding-right: 0 !important;

  &:hover {
    background: none !important;
    color: $text-color !important;
  }
}

.timezoneOptionLabelClassName {
  width: 100%;
  .label {
    display: flex;
    justify-content: space-between;
    svg {
      * {
        stroke: $success-color;
      }
    }
  }
}
