@import 'styles/functions';
@import 'styles/variables';

.statusWrapper {
  padding: 6px 12px;
  background: $white;
  color: $text-color;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.09px;
  border-radius: 100px;
  &.Active {
    background: $white;
    color: $text-color;
  }
  &.Pending {
    background: $primary-color;
    color: $white;
  }
  &.ActiveUnderContract {
    background: $primary-color;
    color: $white;
  }
}
