@import 'styles/functions';
@import 'styles/variables';

.uploadDocument {
  float: right;
  margin-right: 0.5rem;
  cursor: pointer;
  background: #e2e2e2;
  border-radius: 50%;
  height: setUnit(26);
  width: setUnit(26);
  div {
    position: relative;
    left: setUnit(5);
  }
}

.files {
  .spacing {
    padding: 1rem;
  }

  .addFilesBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: setUnit(8);
    width: 100%;
    height: 52px;
    border-radius: 8px;
    margin-top: setUnit(16);

    .btnIcon {
      display: flex;
    }
  }

  .title {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: $black !important;
  }

  .editIcon {
    display: flex;
  }

  .label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .fileUploadIcon {
    float: right;
    margin-right: 0.5rem;
  }

  .addOptionDiv {
    display: flex;
    margin-bottom: 1.5rem;
    cursor: pointer;

    .addLabel {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
    }
  }
}
