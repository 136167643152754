@import 'styles/mixins';
@import 'styles/variables';

.fullContainer {
  height: calc(100vh - 80px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #ffffff 0%, rgba(244, 245, 246, 0.4) 100%);
  backdrop-filter: blur(20px);
  padding: 2rem;
  position: absolute;
  z-index: 10;
  top: 0;

  .closeContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 1;
  }

  .searchContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -80px;
    left: 0;
    bottom: 0;
    right: 0;

    .formWrapper {
      width: min(100%, 600px);

      .valuesWrapper {
        border-radius: 16px;
        & > [class*='values'] {
          max-width: 89%;
          padding-left: 42px;
        }
        & > [class*='suffixIcon'] {
          right: 6px;
        }

        & > [class*='geoTagIcon'] {
          & > svg > path {
            stroke: #515151 !important;
          }
        }
      }

      .searchWrapper {
        padding-left: 8px;
      }

      .locations {
        box-sizing: border-box;
        font-weight: normal;
        font-size: setUnit(16);
        line-height: setUnit(21);
        margin-bottom: setUnit(15);
        @media (min-width: $mobile) {
          margin-bottom: 0;
        }
        .locationsValues {
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
          border: 1px solid #fff;
          height: setUnit(56);
          & > div + div {
            border: 0;
          }
        }

        .locationsPopup {
          .locationsDropdown {
            margin-top: setUnit(8);
            border-radius: setUnit(8);
            border: none;
          }
          input {
            border-radius: setUnit(100);
            height: setUnit(56);
          }
        }
        .locationsIcon {
          position: absolute;
          top: 1rem;
          left: 1.25rem;
        }

        .valuesWrapper {
          border-radius: 16px;
        }
      }
      .subText {
        margin-top: 42px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        cursor: pointer;

        .iconWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }
        .wrapper {
          display: flex;
          align-items: center;
          .mainText {
            color: $dark-color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 125% */
            letter-spacing: -0.18px;
          }
          .mutedText {
            color: $text--light-gray-color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.18px;
          }
        }
      }
    }
    .subText {
      margin-top: 42px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      cursor: pointer;

      .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }
      .wrapper {
        display: flex;
        align-items: center;
        .mainText {
          color: $dark-color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 125% */
          letter-spacing: -0.18px;
        }
        .mutedText {
          color: $text--light-gray-color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.18px;
        }
      }
    }

    .savedSearchesWrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      padding: 28px 0px;
      border-radius: 16px;
      background: $white;
      box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.06);
      max-width: 600px;
      max-height: 390px;
      width: 100%;
      row-gap: 20px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 32px;

        div:first-child {
          color: $text-color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 100% */
          letter-spacing: -0.09px;
        }

        div:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0;
          cursor: pointer;
        }
      }
      .pillsContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 8px;
        padding-inline: 32px;

        .pill {
          display: flex;
          padding: 8px 12px;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          border: 1.5px solid $light-grey-color;
          background: var(--White, #fff);
          color: $text-color;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          cursor: pointer;

          /* Mobile/Desktop/Caption/Regular */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
          letter-spacing: -0.09px;

          &.active {
            border: 1.5px solid $primary-color;
            background: $primary-color-light;
          }
        }
      }
      .itemsContainer {
        margin-top: 4px;
        display: flex;
        flex: auto;
        flex-direction: column;
        row-gap: 24px;
        overflow-y: scroll;
        padding-inline: 32px;

        .message {
          text-align: center;
        }

        .CardsContainer {
          width: 100%; // Ensure the container is full width
          border-radius: 8px;
          justify-content: space-between;

          .infoDesc {
            overflow: hidden;
          }
        }
      }
    }

    .input {
      border-radius: 10px;
    }
  }
}
