@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.validation {
  font-size: 0.875rem;
  color: $error-color;
  margin-top: 0.1875rem;
}

.servicesOffered {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;

  .offering {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background-gray;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: $secondary-color;
    margin-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 16px;
    margin-bottom: 8px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      word-break: break-all;
      margin-right: 4px;
    }

    svg {
      cursor: pointer;
    }
  }
}

.servicesInput {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 12px;
  height: 48px;
  border: 1px solid $light-grey-color;
  transition: all 0.2s;

  &:focus-within {
    border-color: $text-gray-color;
  }

  div:nth-of-type(1) {
    margin-right: 6px;
    border-bottom: none !important;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 24px !important;
      width: 24px !important;
    }
  }

  .servicesAutoComplete {
    border: none !important;

    [class*='ant-select-selector'] {
      border: none !important;
    }

    [class*='ant-select-clear'] {
      top: 35% !important;
      height: 24px;
      width: 24px;
      right: 0 !important;
    }

    [class*='ant-select-selection-placeholder'] {
      font-size: 16px !important;
      color: $text--light-gray-color !important;
      font-weight: 400;
      line-height: 20px !important;
      letter-spacing: -0.18px;
    }
  }
}

.autoCompleteTagDropdown {
  min-width: 450px !important;
  max-height: 256px !important;
  border-radius: 8px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-top: 4px !important;
  overflow-y: auto !important;

  [class*='ant-select-item'] {
    margin: 0 !important;
    padding: 0 !important;
  }

  [class*='ant-select-item-option-content'] {
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 48px;
    display: flex;
    align-items: center;
  }
}
