@import 'styles/functions';
@import 'styles/variables';

.noPartners {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px 0 7.5rem 0;
  width: 100%;

  .noPartnersText {
    margin-top: 24px;
    color: $text--light-gray-color;
    font-size: 16px;
    text-align: center;
  }
}

.partners {
  width: 100%;

  [class*='ant-collapse-header'] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .categoryHeader {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 32px;
    margin-bottom: 20px;

    &Container {
      display: flex;
      align-items: center;
      cursor: pointer;

      .title {
        color: $text-color;
        margin-right: 6px;
      }

      .count {
        color: $text--light-gray-color;
      }
    }
  }

  .antCollapse {
    width: 100%;

    [class*='ant-collapse-item'] {
      border: none !important;
      margin-bottom: 16px;
    }

    .antCollapsePanel {
      margin: 0 !important;
    }

    [class*='ant-collapse-content-box'] {
      padding: 0 !important;
    }
  }
}

.directoryNote {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $dark-color;
  background-color: #4673d10f;
  padding: 16px;
  border-radius: 16px;
  margin-top: 16px;
  margin-bottom: 16px;

  .text {
    flex: 1;
  }

  .icon {
    cursor: pointer;
    margin-left: 24px;
  }
}
