@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  color: $text-color;
}

.item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 0;
  color: $dark-color;
  border-bottom: 1px solid #eeefef;
  gap: 32px;

  &.last {
    &:last-child {
      border-bottom: none;
    }
  }

  .bold {
    color: $text-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    letter-spacing: -0.09px;
    width: 50%;
    text-align: left;
  }

  .value {
    color: $dark-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.09px;
    width: 50%;
    text-align: left;
  }
  .value :last-child {
    margin-bottom: 0px;
  }
}
