@import 'styles/functions';
@import 'styles/variables';

.markerCluster {
  color: #000000;
}

.countBoxOnMap {
  width: fit-content;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(12px);
  color: $text-color;
  margin: 20px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
}

.countBoxOnMap span {
  font-size: 0.9rem;
  font-weight: 500;
}
.googleMap {
  position: absolute !important;
}
