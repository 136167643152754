@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.container {
  .assignToRolesQuestion {
    margin-bottom: 16px;
  }
  .add {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    height: 2rem;
    padding: 0.5rem 0.125rem;
    margin-top: 12px;
    .addIcon {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    .addText {
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
      letter-spacing: -0.01125rem;
      color: $dark-color;
    }
    &:hover {
      border-radius: 0.5rem;
      background: $background-grey-2;
    }
  }

  .disclaimer {
    color: $dark-grey-color;
    margin-top: 2.25rem;
  }

  .customName {
    width: 100%;
  }

  .inviteBtnSpacing {
    margin-bottom: 16px !important;
  }

  .clientPhoneInput {
    @include lightRounded;
    margin-top: 12px !important;
  }

  .clientEmailInput {
    margin-bottom: 19px;
    margin-top: 12px;
    input {
      @include lightRounded;
    }
  }

  .emailInput {
    input {
      padding: setUnit(10) 0;
    }
  }

  .fullAccessContainer {
    .checkbox {
      display: inline-block;
      margin-top: 1.2rem;
      margin-bottom: -5px;
    }

    .checkboxLabel {
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem; /* 150% */
      letter-spacing: -0.00563rem;
      color: $text-color;
    }
  }
}

.wrap {
  border: 2px solid $light-gray;
  border-radius: 8px;
  padding: 1.25rem 1.25rem;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.inviteCheckbox {
  display: flex;
  margin-right: 8px;
  margin-bottom: -10px;
  margin-top: 19px;
}

.inviteCheckboxLabel {
  margin-left: setUnit(12);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.00563rem;
  color: $text-color;
}

.buttonCon {
  margin-top: 31px !important;
}

.displayInputLabelWrap {
  display: flex;
}

.inputLabel {
  @include inputLabelContainer;
  color: $text-color;
  margin-bottom: 0;
  font-weight: 600;
}

.inputLabelOptional {
  @include inputLabelContainer;
  color: $text--light-gray-color;
  margin-bottom: 0;
  font-weight: 400;
  margin-left: 3px;
}

.errorClass {
  font-size: 0.75rem !important;
  color: $error-color;
  padding-top: 0.5rem;
  &::before {
    content: '\0021';
    font-weight: bold;
    color: $white;
    width: setUnit(14);
    height: setUnit(14);
    display: inline-block;
    background: $error-color;
    text-align: center;
    padding: 0.2em;
    border-radius: 100%;
    font-size: setUnit(12);
    margin-right: 0.5em;
  }
}
