@import 'styles/variables';
@import 'styles/functions';

.wrapper {
  padding: setUnit(40) setUnit(40);

  .searchWrapper {
    display: flex;
    padding: 1rem;
    background-color: $white;
    border-radius: setUnit(16);

    .searchInput {
      background-color: $white !important;
      border: 1px solid $border-light-gray-2 !important;
    }
  }

  .templatesContainer {
    overflow: auto;
    max-height: 320px;
    margin-top: setUnit(32);
    background-color: $white;
    padding: setUnit(4) setUnit(24);
    border-radius: setUnit(16);

    &.templatesHeight {
      max-height: 520px;
    }
  }

  .group {
    cursor: pointer;
    padding: setUnit(2) 0;
    margin-left: setUnit(-12);
    margin-right: setUnit(-12);

    .groupContainer {
      padding: setUnit(22) setUnit(13);
      border-radius: setUnit(16);
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background-color: #f4f5f680;
      }
    }

    .category {
      font-size: setUnit(16);
      font-weight: 600;
      color: $text-color;
      display: flex;
      align-items: center;
      line-height: setUnit(21);
      margin: 0;

      .counter {
        color: $text--light-gray-color;
        margin-left: setUnit(8);
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $light-gray;
    margin: 0.2rem 0;
  }

  .groupTemplates {
    .template {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: setUnit(16) setUnit(13);
      border-radius: setUnit(16);
      cursor: pointer;
      margin-left: setUnit(-12);
      margin-right: setUnit(-12);

      &:hover {
        background-color: #f4f5f680;
      }

      .templateName {
        font-size: setUnit(16);
        line-height: setUnit(21);
        font-weight: 500;
        color: $text-color;
        margin-bottom: 0.1rem;
      }

      .information {
        display: flex;
        align-items: center;

        .seperator {
          height: 14px;
          width: 1px;
          margin: 0 setUnit(7);
          background-color: $border-light-gray-2;
        }

        .label,
        .value {
          font-size: 14px;
          font-weight: 400;
        }

        .label {
          color: $text-gray-color;
        }
      }

      .checkbox {
        display: inline-flex;

        [class*='ant-checkbox-wrapper'] {
          &:hover {
            [class*='ant-checkbox-inner'] {
              border-color: $primary-color !important;
            }
          }
        }

        [class*='ant-checkbox-checked'] {
          &::after {
            border-color: $primary-color;
          }

          [class*='ant-checkbox-inner'] {
            background-color: $primary-color;
            border-color: $primary-color !important;
          }
        }
      }
    }
  }

  .arrowIcon {
    display: flex;
    svg {
      vertical-align: middle;
      height: setUnit(24);
      width: setUnit(24);

      path {
        stroke: $text-gray-color;
      }
    }

    &:hover {
      svg {
        path {
          stroke: $text-color;
        }
      }
    }
  }

  .submitBtn {
    margin-top: 1.5rem;
    min-width: setUnit(168);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .noTemplates {
    padding: 0;
    border: none;
    margin: setUnit(60) 0;
  }

  .multiBtn {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    width: 168px;
    height: 52px;
  }
}
