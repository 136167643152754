@import 'styles/functions';
@import 'styles/variables';

.adminKits {
  position: relative;

  .filtersWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: setUnit(32);

    .searchButton {
      width: setUnit(46);
      height: setUnit(46);
      margin-left: setUnit(32);
      background-color: $background-ligh-gray !important;
      border-radius: 50% !important;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: $light-gray !important;
      }

      svg {
        width: setUnit(24);

        path {
          stroke: $text-color;
        }
      }
    }

    .searchInput {
      max-width: setUnit(250);
    }
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .newKit {
      background-color: $secondary-color !important;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 20px !important;
      padding: setUnit(12);
      border-radius: setUnit(100) !important;
      height: 42px;

      .iconAdd {
        display: flex;
        margin-right: setUnit(5);
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }

    .heading {
      font-weight: 700;
      font-size: setUnit(32);
      line-height: setUnit(36);
      font-family: $font-family;
      letter-spacing: setUnit(-0.4);
      color: $text-color;
    }
  }
}
