@import 'styles/functions';
@import 'styles/variables';

.locationWrapper {
  width: 100%;
  max-width: 250px;
  display: block;
  border-radius: 8px !important;
  border: 1px solid $border-light-gray-2 !important;
  transition: all 0.2s;

  &:focus-within {
    border: 1px solid $text-gray-color !important;
  }

  .placeholderClass {
    border: none !important;
    text-align: left;
    color: $text--light-gray-color !important;
    height: initial !important;
  }
  .activeInputClassName {
    border: none !important;

    & input {
      border: none !important;
      height: initial !important;
    }
  }
  .valuesWrapperClassName {
    border: none !important;
    background: none !important;
  }
  .valuesContainer {
    border: none !important;
  }
}
