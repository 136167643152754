@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.taskTemplateModal {
  [class='ant-modal-content'] {
    padding: 40px;
    padding-bottom: 0px;
    background-color: $background-gray;
  }

  .searchInput {
    margin-top: 36px;
    margin-bottom: 34px;
  }

  .assignToContainer {
    width: 100%;
    overflow-y: auto;
    max-height: 50vh;

    @media (min-width: $tablet) {
      overflow-x: hidden;
    }

    .assignToRow {
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .userData {
        display: flex;
        align-items: center;

        .avatar {
          width: 32px !important;
          height: 32px !important;
          margin-right: 12px;
          font-family: $font-family;
          font-size: 14px !important;
          font-weight: 600 !important;
          line-height: 18px !important;
        }
      }

      [class*='ant-checkbox-wrapper'] {
        &:hover {
          [class*='ant-checkbox-inner'] {
            border-color: $primary-color !important;
          }
        }
      }

      [class*='ant-checkbox-checked'] {
        &::after {
          border-color: $primary-color;
        }

        [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color !important;
        }
      }
    }
  }
}

.assignToHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: $background-gray;

  .headerContainer {
    text-align: center;

    .headerTitle {
      font-family: $font-family;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      color: $text-color;
      margin: 0;
    }
  }

  .headerIcon {
    &:hover {
      cursor: pointer;
    }
  }
}

.taskTemplate {
  padding: 0px !important;
  padding-bottom: 20px !important;
}
