@import 'styles/functions';
@import 'styles/variables';

.actions {
  display: flex;
  align-items: center;

  .btn {
    width: setUnit(85);
    height: setUnit(44);
    border-radius: setUnit(100);
    font-weight: 500;

    .loader {
      font-size: setUnit(3);
      margin: 0;
    }

    &.cancel {
      background: $background-ligh-gray;
      color: $dark-color;
      margin-left: setUnit(8);

      &:hover {
        background: $border-light-gray;
      }
    }
  }
}
