@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.status {
  position: relative;
  margin-right: setUnit(8);

  .value {
    height: setUnit(40);
    padding: 0 setUnit(16);
    border-radius: setUnit(100);
    background: $background-ligh-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 500;

    &.new {
      background: $background-ligh-gray;
    }

    &.inProgress {
      background: $background-ligh-gray;
    }

    &.stuck {
      background: $primary-color-light;
      color: $primary-color;
    }

    &.done {
      background: rgba($green-color, 0.2);
      color: $green-color;
    }

    &.overdue {
      background: rgba($error-color, 0.1);
      color: $error-color;
    }

    &.na {
      background: $border-light-gray-2;
    }

    .icon {
      margin-left: setUnit(4);
      position: relative;
      right: setUnit(-4);
    }
  }

  .options {
    @include box-shadow;

    position: absolute;
    top: setUnit(46);
    right: 0;
    background: $white;
    border-radius: setUnit(8);
    min-width: setUnit(198);
    padding: setUnit(8) 0;
    z-index: 10;

    ul {
      list-style: none;
      margin-bottom: 0;
    }

    .item {
      padding: setUnit(9) setUnit(24);
      cursor: pointer;

      &:hover {
        background: $background-ligh-gray;
      }

      .optionStatus {
        width: setUnit(12);
        height: setUnit(12);
        border-radius: 50%;
        display: inline-block;
        margin-right: setUnit(14);

        &.new {
          background: $confetti-blue;
        }

        &.inProgress {
          background: $orange;
        }

        &.stuck {
          background: $primary-color;
        }

        &.done {
          background: $green-color;
        }

        &.overdue {
          background: $error-color;
        }

        &.na {
          background: $border-light-gray-2;
        }
      }
    }
  }

  .optionsScroll {
    top: -15rem !important;
  }

  .optionsScrollLess {
    top: -7rem !important;
  }

  .hideDropdownOptions {
    cursor: default;
  }
}

.statusOnly {
  border-radius: 20px !important;
  text-align: center;
  color: $white !important;
  font-size: 14px;
  font-weight: 600 !important;

  .statusText {
    white-space: nowrap;
    overflow: hidden;
  }

  &.new {
    background-color: $confetti-blue !important;
  }

  &.inProgress {
    background-color: $orange !important;
  }

  &.stuck {
    background-color: $primary-color !important;
  }

  &.done {
    background-color: $hover-green !important;
  }

  &.overdue {
    background: $error-color !important;
  }
}
