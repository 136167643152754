@import 'styles/functions';
@import 'styles/variables';

.messages {
  height: calc(100vh - 275px);
  overflow: auto;
  padding: 0 setUnit(24);
  overflow-x: hidden;

  .innerHolder {
    padding: 0 setUnit(0);
    min-height: calc(100% - #{setUnit(40)});
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .groupMessages {
    margin: setUnit(24) 0;

    .message {
      margin-top: setUnit(2);
      margin-bottom: setUnit(2);
    }
  }

  .groupedDate {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.09px;
    text-align: center;
    margin: 24px 0;
    color: $text-gray-color;
  }
}
