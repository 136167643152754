@import 'styles/functions';
@import 'styles/variables';

.LeftPanel {
  margin-top: setUnit(32);
  h5 {
    font-weight: 700;
    font-size: setUnit(24);
    line-height: setUnit(38);
    margin-bottom: setUnit(24) !important;
  }
  .LeftPanelLinks {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      &:not(:last-child) {
        margin-bottom: setUnit(8);
      }
      .link {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: setUnit(7) setUnit(8);
        background-color: transparent;
        border-radius: setUnit(8);
        transition: $transition;
        .linkIcon {
          display: flex !important;
          align-items: center;
          justify-content: center;
          margin-right: setUnit(16);

          &.emailTemplateIcon {
            margin-right: 18px;
            margin-left: 3px;
          }

          svg {
            * {
              stroke: $text--light-gray-color;
              transition: $transition;
            }
          }
        }
        .linkTitle {
          font-weight: 400;
          font-size: setUnit(16);
          line-height: setUnit(21);
          color: $text-color;
          transition: $transition;
        }
        &.linkActive {
          background-color: $background-grey-2;
          .linkTitle {
            font-weight: 600;
          }
          .linkIcon {
            display: flex !important;
            align-items: center;
            justify-content: center;

            &.emailTemplateIcon {
              margin-right: 18px;
              margin-left: 3px;
            }

            svg {
              * {
                stroke: $primary-color;
                transition: $transition;
              }
            }
          }
        }
      }
    }
  }
}
