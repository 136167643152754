@import 'styles/mixins';
@import 'styles/variables';

.taskSectionContainer {
  padding: setUnit(15) setUnit(20);
  border-radius: 12px;
  background-color: $white;
  display: flex;
  gap: setUnit(16);
  margin-bottom: setUnit(16);
  justify-content: space-between;

  &.alignCenter {
    align-items: center;
  }

  &.disabled {
    opacity: 0.5;
  }

  .content {
    width: 100%;

    .title {
      font-size: 14px;
      font-weight: 400;
      color: $dark-color;
      opacity: 80%;
      margin-bottom: 0;
    }
  }
}
