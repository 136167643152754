@import 'styles/variables';
@import 'styles/functions';

.inboundIteration {
  .title {
    margin: 0 0 setUnit(16) 0;
    color: $text-color;
    font-weight: bold;
    font-size: setUnit(16);
    font-weight: 600;
    line-height: 20.8px; /* 130% */
    letter-spacing: -0.18px;
  }

  .notes {
    background: $primary-color-light;
    border-radius: setUnit(8);
    padding: setUnit(16);
    line-height: 20px;
    color: $text-color;
    font-weight: 400;
    letter-spacing: -0.18px;
    word-break: break-word;
  }

  .disclaimer {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.18px;
    margin-bottom: 0;
    color: $text--light-gray-color;
  }
}

.marginTop {
  margin-top: setUnit(24);
  @media (min-width: 992px) {
    margin-top: 0;
  }
}
