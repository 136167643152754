@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.commentWrapper {
  @include action-icon();
  background-color: $white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 30px;
  &:hover {
    background: $background-grey-2;
  }

  .count {
    font-size: 14px;
    font-weight: 600;
    margin-left: 4px;
  }

  .icon {
    position: relative;
    display: flex;
    align-items: center;

    svg {
      vertical-align: middle;
    }

    &.isUnread {
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $primary-color;
        position: absolute;
        top: -2px;
        right: -1px;
        border: 2px solid $background-ligh-gray;
        z-index: 1;
      }
    }
  }
}
