@import 'styles/functions';
@import 'styles/variables';

.dueDateContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dueDateContainer {
  position: relative;
  .dueDate {
    &.marginTop {
      margin-top: setUnit(5);
    }
    font-size: setUnit(16);
  }

  .overdueLabel {
    justify-content: flex-start !important;
    margin-top: 4px;
  }

  &:hover {
    .editIcon {
      visibility: visible;
    }
  }

  label {
    margin-bottom: 0.5rem !important;
  }

  .editIcon {
    visibility: hidden;
    border-radius: 50%;
    padding: 0.5rem;
    display: flex;
    background: $background-grey-2;
    position: absolute;
    right: 5px;
    top: 0px;
    cursor: pointer;
  }

  .footerBtns {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 1rem !important;

    button {
      border-radius: setUnit(8);
      font-weight: 500;
      border-width: 1px !important;
      &.ant-btn[disabled] {
        opacity: 0.7 !important;
      }
    }
  }
}
