@import 'styles/functions';
@import 'styles/variables';

.collapsedTaskDetails {
  padding: setUnit(16);
  border-radius: 16px;
  background-color: $white;
  margin-bottom: setUnit(16);
  &,
  .left {
    display: flex;
    align-items: center;
    gap: setUnit(12);
  }

  .left {
    width: 100%;
  }

  .right {
    .editIcon {
      display: flex;
      cursor: pointer;
    }
  }

  .taskSummary {
    .title,
    .dueDate {
      margin: 0;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
    }
    .dueDate {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
