@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  border-radius: 16px;
  background: $white;
  padding: 32px 0;
  margin-top: 24px;
}

.list {
  padding: 0 18px 0 32px;
  max-height: 450px;
  overflow-y: auto;
}

.exploreItems {
  padding: 0 32px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 26px;
  margin-bottom: 20px;
  .pill {
    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid $light-grey-color;
    color: $text-gray-color;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.18px;
    cursor: pointer;
    &.active {
      background: linear-gradient(90deg, #ff576d -92.22%, #fb913a 99.9%);
      color: $white;
      border: 1px solid linear-gradient(90deg, #ff576d -92.22%, #fb913a 99.9%);
    }
  }
}

.titles {
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $text--light-gray-color;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.09px;
  p {
    margin-bottom: 0;
  }
}

.header {
  padding: 0 32px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: setUnit(16);
  line-height: setUnit(21);
  color: $text-color;
  margin-bottom: setUnit(24);

  .headerTitle {
    color: $text-color;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20.8px; /* 115.556% */
    letter-spacing: -0.18px;
  }

  .changeModeBtn {
    margin-left: auto;
    border: 0;
    outline: none;
    transition: all 0.1s;
    background-color: $hover-light-gray;
    border-radius: setUnit(8);
    width: setUnit(40);
    height: setUnit(40);
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }

    .changeModeBtnIcon {
      height: setUnit(24);

      svg {
        width: setUnit(24);
        height: setUnit(24);
      }
    }
  }
}

.listMode {
  .noData {
    font-size: setUnit(14);
    line-height: setUnit(16);
    color: $dark-grey-color;
    text-align: center;
    width: 100%;
    padding-top: 14px;
  }

  div:not(:last-child) {
    margin-bottom: setUnit(16);
  }

  .listModeItem {
    display: flex;

    &Content {
      transition: all 0.2s;
      padding-left: setUnit(8);
      padding-right: setUnit(8);
      flex-grow: 1;

      div:not(:last-child) {
        margin-bottom: setUnit(6);
      }

      &Top {
        display: flex;
        justify-content: space-between;
        align-self: center;

        p {
          margin: 0;
        }

        .category {
          font-weight: 600;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.09);
          color: $text-color;
        }

        .distance {
          color: $dark-color;
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
          letter-spacing: -0.09px;
        }
      }

      &Middle {
        display: flex;
        align-self: center;

        p {
          margin: 0;
        }

        .rating {
          font-weight: 500;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.09);
          color: $text-color;
          margin-right: setUnit(6);
        }

        .count {
          font-weight: 400;
          font-size: setUnit(14);
          line-height: setUnit(16);
          font-family: $font-family;
          letter-spacing: setUnit(-0.09);
          color: $dark-grey-color;
          margin-left: setUnit(6);
        }
      }

      &Last {
        p {
          margin: 0;
          margin-right: setUnit(6);
        }

        display: flex;
        align-self: center;
        font-weight: 400;
        font-size: setUnit(14);
        line-height: setUnit(16);
        font-family: $font-family;
        letter-spacing: setUnit(-0.09);
        color: $dark-grey-color;
      }
    }
  }
}
.mapContainerClassName {
  border-radius: 12px;
  width: calc(100% - 64px) !important;
}
.mapWrapper {
  padding: 0 32px;
  overflow: hidden;
  position: relative;
  height: setUnit(250);
  @media (min-width: $mobile) {
    height: setUnit(380);
  }
  .map {
    width: 100%;
  }
}

.yelpLogo {
  width: setUnit(50);
  height: setUnit(25);
  margin-left: auto;

  svg {
    width: setUnit(50);
    height: setUnit(25);
  }
}

.popupClassName {
  width: setUnit(176) !important;
}

.propertyPreview {
  .info {
    margin-top: setUnit(8);
  }
}
