@import 'styles/functions';
@import 'styles/variables';

.assignedTo {
  margin-bottom: 1rem;
  position: relative;

  .assignToWrapper {
    margin: 0 !important;
  }

  .assignToInputLabel > div > label {
    margin-bottom: 0.5rem;
  }

  .taskCC {
    position: absolute;
    top: -5px;
    right: -5px;
    button {
      padding: 0 4px;
      font-weight: 600;
      font-size: 14px;
      color: $text-gray-color;
      display: flex;
      align-items: center;
      gap: 4px;

      .plusIcon {
        display: flex;
        svg {
          height: 15px;
          width: 15px;
          path {
            stroke: $text-gray-color;
          }
        }
      }
    }
  }

  .label,
  .assignToInputLabel > div > label > span,
  .fieldWrapper > label > span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
}
