.wrapperPreview {
  [class='ant-modal-content'] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.contentWrapper {
  display: flex;
  column-gap: 24px;
  overflow-y: auto;
  max-height: 80vh;
  padding: 0 40px;
  &.details {
    padding: 36px 40px 20px 50px;

    .detailsContent {
      list-style-position: inside;
      max-height: 500px;
      overflow-y: auto;
    }

    .flexContainer {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .videoContent {
      display: flex;
      justify-content: center;
      align-items: center;
      &.start {
        justify-content: start;
      }
    }

    .detailsContent,
    .videoContent {
      flex: 1;
      min-width: 0;
    }

    .videoIframe {
      width: 85%;
      height: 80%;
      border-radius: 8px;
      min-width: 350px;
      min-height: 350px;
      &.full {
        min-width: 100%;
        min-height: 500px;
      }
    }
  }

  .leftContainer {
    display: flex;
    flex-grow: 1;
    row-gap: 32px;
    border-radius: 16px;
    background: #fff;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 20px;

    .header {
      display: flex;

      .left {
        flex: 1 0 auto;
        display: flex;
        column-gap: 16px;
        padding-block: 20px;

        .imgWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 56px;
          height: 56px;

          border-radius: 50%;
          background: #f4f5f6;
        }

        .titleWrapper {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          justify-content: center;
          .title {
            color: var(--REBRAND-Grayscale-100, #262626);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: 'Helvetica Neue';
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 120% */
          }
          .subTitle {
            color: var(--REBRAND-Grayscale-60, #676767);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: 'Helvetica Neue';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
          }
        }
      }
      .right {
        flex: 1 0 auto;
      }
    }

    .body {
      color: var(--REBRAND-Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: 'Helvetica Neue';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
    }
  }

  .rightContainer {
    display: flex;
    flex-grow: 1;
    height: fit-content;
    width: 100%;
    row-gap: 24px;
    flex-direction: column;
    position: sticky;
    align-self: start;
    top: 0px;
  }
}

.readMore {
  margin-left: 4px;
  cursor: pointer;
  color: var(--Secondary-Link-Blue, #2b59da);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.09px;
}

.card {
  border-radius: 16px;
  background: #fff;
  padding: 20px;
  display: flex;
  column-gap: 12px;
  width: 100%;

  .imgWrapper {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 6px;

    display: flex;
    justify-content: center;
    align-items: center;

    &.lightBlue {
      background: rgba(81, 191, 225, 0.12);
      color: #51bfe1;
    }

    &.darkBlue {
      background: rgba(70, 115, 209, 0.12);
      color: #4673d1;
    }

    &.purple {
      background: rgba(146, 140, 218, 0.12);
      color: #928cda;
    }

    &.blue {
      background: rgba(70, 115, 209, 0.12);
      color: #4673d1;
    }

    &.green {
      background: rgba(4, 164, 81, 0.12);
      color: #04a451;
    }

    &.orange {
      background: rgba(251, 145, 58, 0.12);
      color: #fb913a;
    }
  }

  .infoWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    row-gap: 8px;

    .title {
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.09px;
    }

    .pillsContainer {
      display: flex;
      gap: 8px;

      .locationContainer {
        display: flex;
        column-gap: 16px;
        width: 100%;
        gap: 8px;
        flex-wrap: wrap;

        .restricted {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
        }
      }
    }
  }
}

.pill {
  padding: 4px 8px;
  border-radius: 20px;
  display: flex;
  height: fit-content;
  width: fit-content;

  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.18px;
  white-space: nowrap;

  &.lightBlue {
    background: rgba(81, 191, 225, 0.12);
    color: #51bfe1;
  }

  &.purple {
    background: rgba(146, 140, 218, 0.12);
    color: #928cda;
  }

  &.blue {
    background: rgba(70, 115, 209, 0.12);
    color: #4673d1;
  }

  &.green {
    background: rgba(4, 164, 81, 0.12);
    color: #04a451;

    &.outlinedgreen {
      color: var(--Tertiary-Green-Success-80, #00b152);
      border: 1px solid var(--Tertiary-Green-Success-80, #00b152);
      background: none !important;
    }
  }

  &.orange {
    background: rgba(251, 145, 58, 0.12);
    color: #fb913a;
  }
}

.modalSubTitle {
  color: var(--Grayscale-100, #262626);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.47px;
  margin-bottom: 20px;
}

.text {
  color: var(--Tertiary-Green-Success-80, #00b152);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}
.pillRestricted {
  padding: 4px 8px;
  border-radius: 20px;
  display: flex;
  height: fit-content;
  width: fit-content;
  white-space: nowrap;

  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.18px;

  &.green {
    background: rgba(4, 164, 81, 0.12);
    color: #04a451;

    &.outlinedgreen {
      color: var(--Tertiary-Green-Success-80, #00b152);
      border: 1px solid var(--Tertiary-Green-Success-80, #00b152);
      background: none !important;
    }
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.backBtn {
  cursor: pointer;
  position: absolute;
  left: 40px;
  .leftIcon {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
