.dragHandle {
  background-color: transparent !important;
  padding: 0 !important;
}

.dragBtn {
  padding-left: 5px !important;
  width: 100% !important;
  cursor: 'move';
  height: 88px !important;
  background-color: transparent;

  &:focus,
  &:active {
    background-color: transparent;
  }
  &:hover {
    background-color: #fafafa;
  }
}

.emptyPlaceholder {
  height: 88px;
  width: 100%;
}
