@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.title {
  @include step-title;
  text-align: center !important;
  font-size: setUnit(32);
}

.subtitle {
  margin-bottom: 0;
  margin-top: 20px;
  font-size: 18px;
  text-align: left;
  padding-left: 5px;
}

.subtext {
  color: $text-color;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.09px;
}

.typetext {
  margin-bottom: 20px;
}

.schoolsDistrictInputWrapper {
  margin-top: 0;
}

.holder {
  flex-wrap: nowrap !important;
  gap: 10px;
  .case {
    &:not(:last-child) {
      margin-bottom: 0 !important;
    }
  }
}

.answer {
  border-radius: setUnit(8) !important;
  box-shadow: none !important;
  border: 2px solid $light-gray !important;
  cursor: pointer;
  margin-bottom: 1rem !important;

  .label {
    font-weight: 400 !important;
    color: $dark-grey-color !important;
    font-size: 18px !important;
    line-height: 24px !important;
    margin-top: -2px !important;
  }

  &:hover {
    border: 2px solid $text--light-gray-color !important;
    color: $text-color !important;
  }
}

.answerActive {
  background-color: $orange-lighter !important;
  border: 1px solid $orange !important;

  .label {
    font-weight: 500 !important;
    color: $text-color !important;
  }

  &:hover {
    border: 1px solid $primary-color-hover !important;
    background-color: #ffebed !important;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  .button {
    width: 100px;
    height: 45px;
    &.cancel {
      background-color: $white;
      color: $text-color;
      width: fit-content !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  .titleClassName {
    font-size: 16px;
    font-weight: 600;
    position: relative;
    bottom: 1px;
  }
}

.next {
  margin: setUnit(40) auto setUnit(0) auto;
  width: 212px !important;
  height: 52px !important;

  &.overflow {
    margin-top: 20px;
    margin-bottom: -20px;
  }
}

.counter {
  margin: setUnit(68) 0;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  max-width: 650px !important;
}

.close {
  background: none !important;
  width: setUnit(58) !important;
  height: setUnit(58) !important;
  right: 1rem !important;
  top: 1rem !important;

  span {
    height: setUnit(3) !important;
    width: 40% !important;
    border-radius: setUnit(2) !important;
  }
}

.opacityLayer {
  background: linear-gradient(
    180deg,
    rgba(244, 245, 246, 0.8) 0%,
    rgba(244, 245, 246, 0.4) 50%,
    rgba(244, 245, 246, 0.6) 75%,
    rgba(244, 245, 246, 0.9) 100%
  );
  backdrop-filter: blur(20px);
  background-color: transparent !important;
}

.innerHolder {
  padding: 0 35px !important;
  overflow: hidden !important;
}

.checkboxOption {
  width: calc(50% - 6px) !important;
  margin-bottom: 12px !important;
  &:nth-child(odd) {
    margin-right: 12px;
  }
}

.caseClassname {
  width: calc(50% - 5px) !important;
  margin: 20px 0;
}
