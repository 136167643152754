@import 'styles/functions';
@import 'styles/variables';

.overdueLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .icon {
    display: flex;
    margin-right: setUnit(10);
    svg {
      vertical-align: middle;
    }
  }

  .text {
    color: $error-color;
    font-weight: 500;
  }
}
