@import 'styles/functions';
@import 'styles/variables';

.container {
  width: 100%;
}

.accordion {
  border: 0 !important;
  background: white;
  border-radius: 16px;
  margin: 24px 0;
  padding: 32px;
}

.contentS {
  margin-top: setUnit(32);
  font-size: setUnit(16);
  line-height: setUnit(24);
  color: $dark-color;
  word-break: break-word;
}

.previewClassName {
  padding: 0 !important;
}

.titleClassName {
  color: $text-color;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.8px; /* 115.556% */
  letter-spacing: -0.18px;
}

.iconContainer {
  background-color: $background-grey-2 !important;
  svg {
    path {
      stroke: $dark-color !important;
    }
  }
}

.mlsData {
  .title {
    font-weight: 700;
    font-size: 20px;
  }
  .json {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .column {
      width: 50%;
      .content {
        max-height: 70vh;
        overflow-y: auto;
      }
    }
  }
}

.contentWrapper {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 setUnit(15);
  @media (min-width: $tablet) {
    padding: 0;
  }
}

.contentBlock {
  flex: 0 0 100%;
  margin-bottom: setUnit(80);
  @media (min-width: $tablet) {
    flex: 1;
  }
}

.sidebarBlock {
  flex: 0 0 100%;
  @media (min-width: $tablet) {
    flex: 0 0 setUnit(314);
    padding-left: 24px;
  }
}

.evaluateBlock {
  display: flex;
  padding-top: setUnit(15);
  align-items: center; /* Aligns checkbox and text vertically */
  margin-bottom: setUnit(16); /* Adjust the spacing below the block if needed */
}

.mlsBlock {
  display: flex;
  padding-top: setUnit(15);
  align-items: center; /* Aligns checkbox and text vertically */
}

.checkBoxIcon {
  display: flex;
  align-items: center;
  flex: 0 0 setUnit(24);
  margin-right: setUnit(8);
  & [class*='ant-checkbox'] {
    top: 0;
    & [class*='ant-checkbox-inner'] {
      border: 1.5px solid $text--light-gray-color;
      border-radius: 4px;
      width: setUnit(24);
      height: setUnit(24);
      background-color: #fff;
      &:after {
        transform: rotate(45deg) scale(1.1) translate(-50%, -50%);
        left: 30%;
      }
    }
    & + span {
      font-size: setUnit(14);
      line-height: setUnit(16);
      font-weight: 400;
      letter-spacing: -0.09px;
      color: $dark-grey-color;
      padding-right: 0;
    }
  }
  & [class*='ant-checkbox-checked'] {
    &:after {
      border-color: $primary-color;
    }
    & [class*='ant-checkbox-inner'] {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    & + span {
      color: $text-color;
    }
  }
}

.checkBoxText {
  line-height: 1;
  display: flex;
  align-items: center;
  margin-bottom: setUnit(5);
  margin-left: setUnit(10);
}
