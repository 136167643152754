@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.content {
  .section {
    background-color: $white;
    border-radius: 16px;
    padding: 20px 20px 24px 20px;

    .validation {
      font-size: 0.875rem;
      color: $error-color;
      margin-top: 0.1875rem;
    }

    input::placeholder {
      font-size: 16px !important;
      color: $text--light-gray-color !important;
      font-weight: 400;
      line-height: 20px !important;
      letter-spacing: -0.18px;
    }
  }

  .selection {
    background-color: $white;
    border-radius: 16px;
    margin-top: 20px;

    > .option:first-of-type {
      border-bottom: 1px solid $background-gray;
    }

    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .checkboxDisabled {
          opacity: 0.5;
          pointer-events: none;
        }

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          label {
            margin: 0 !important;
          }
        }
      }

      .right {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: $text-gray-color;
      }
    }
  }

  .watchVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 24px;

    .text {
      margin-left: 8px;
      color: $text-color;
      font-weight: 400;
    }

    .watch {
      margin-left: 8px;
      color: $link-blue;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.publishProfileTooltip {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $white;

  .italic {
    font-style: italic;
    font-weight: 400;
    color: $border-light-gray-2;
  }
}

.tooltipIcon {
  margin-left: 8px;
  cursor: pointer;
}

.infoTooltip {
  div[class~='ant-tooltip-content'] {
    div[class~='ant-tooltip-inner'] {
      max-width: 350px;
      padding: 16px;

      p {
        color: white !important;
        margin: 0;
        white-space: auto;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.18px;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}
