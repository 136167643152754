@import 'styles/functions';
@import 'styles/variables';

:root {
  --shared-font-style: normal;
  --shared-font-family: Inter;
  --shared-font-feature-settings: 'clig' off, 'liga' off;
}

.schoolModal {
  overflow-x: clip;
  [class*='ant-modal-content'] {
    border-radius: 0.75rem !important;
    padding: 40px 0px 0px 0px !important;
  }

  [class*='ant-modal-close'] {
    top: 10px !important;
    right: 10px !important;
  }

  @media (max-width: $mobile) {
    [class*='ant-modal-content'] {
      height: 100%;
      overflow-y: clip;
    }
  }
  [class*='ant-modal-header'] {
    margin-bottom: 0 !important;
  }
  [class*='ant-modal-body'] {
    height: auto;
  }
  @media (max-width: $mobile) {
    [class*='ant-modal-body'] {
      height: 98%;
      width: 100%;
    }
  }
}

.community {
  color: $dark-color;
  font-size: setUnit(20);
  line-height: setUnit(26);
  font-weight: 700;
  margin: setUnit(27) 0;
}

.ratingSectionWrapMobile {
  display: 'flex';
  width: '100%';
  box-sizing: border-box;
  gap: 2px;
}

.blockWithoutLine {
  padding-bottom: setUnit(27);
}

.block {
  margin-top: 24px;
  border-radius: 16px;
  background: $white;
  padding: 37px;

  .blockTitle {
    font-weight: 700;
    font-size: setUnit(16);
    line-height: setUnit(21);
    margin-bottom: 40px;
  }

  .blockInfo {
    color: $dark-grey-color;
    font-size: setUnit(12);
    line-height: setUnit(16);
    margin-top: setUnit(25);
  }
}
.schoolWrapMobile {
  margin-bottom: setUnit(2);
}

.schoolWrap {
  margin-bottom: setUnit(16);
}

.school {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  .detail {
    margin-left: 1px;
  }

  .detailWidthMobile {
    width: 80%;
  }

  .detailWidth {
    width: 58%;
  }

  .distance {
    display: flex;
    justify-content: center;
  }

  .distanceMobile {
    display: flex;
    justify-content: space-around;
  }

  .distanceWidth {
    width: 9%;
  }

  .distanceWidthMobile {
    width: 20%;
  }

  .greatSchools {
    width: 20%;
    display: flex;
    justify-content: space-around;
  }
  .niche {
    width: 12%;
    display: flex;
    margin-right: 10px;
    justify-content: flex-end;
  }
}

.school:hover .listingSchoolNameTypography {
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.transportationWrapper {
  height: setUnit(190);
}

.error {
  color: $error-color;
  text-align: center;
}

.transportation {
  display: flex;

  .transportationBlock {
    width: 33%;
    text-align: center;
    font-size: setUnit(14);
    line-height: setUnit(16);

    .title {
      color: $text-color;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      letter-spacing: -0.18px;
      margin-bottom: 4px;
      margin-top: 20px;
    }

    .desc {
      color: $dark-grey-color;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.09px;
    }
  }

  .percent {
    font-weight: 600;
    font-size: setUnit(20);
    line-height: setUnit(26);
  }
}

.header {
  width: 100%;
  height: 7rem;
  border-bottom: 1px solid $light-gray;
  padding: 0 0 1.2rem 2rem;
}

.schoolText {
  margin-bottom: 2px;
  color: $text-color;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.375rem;
  letter-spacing: -0.02938rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 88%;
  white-space: nowrap;
}

.admission::after,
.grades::after {
  color: $dark-grey-color;
  content: '•';
  padding: 0 setUnit(7);
}

.address {
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 70%;
  white-space: nowrap;
}

.subheader {
  display: flex;
  margin-bottom: -7px;
}

.headerText {
  color: $text-color;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem;
  letter-spacing: -0.01125rem;
}

.contentWrap {
  gap: 17px;
  width: 100%;
  background-color: $background-ligh-gray-5;
  border-radius: 0 0 0.75rem 0.75rem;
  padding-top: 15px;
  padding-bottom: 14px;
}

.contentWrapMobile {
  display: grid;
}

.contentWrapNotMobile {
  display: flex;
}

.contentWrapBigMap {
  min-height: 30vh;
  height: 30vh;
}

@media (min-width: 601px) and (max-width: 1024px) {
  .contentWrapBigMap {
    height: 60%;
  }
}

@media (max-width: 600px) {
  .contentWrapBigMap {
    height: unset;
    min-height: unset;
  }
}

.contentWrapMap {
  height: 68.7vh;
  @media (max-width: 600px) {
    position: relative;
    flex-grow: 1;
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    height: 90%;
  }
}

.leftContentWrap {
  padding-left: 2rem;
  width: 65%;
  max-width: 65%;
}

.leftContentWrapMobile {
  width: 100%;
  position: absolute;
}

.rightContentWrap {
  width: 34%;
  max-width: 34%;
  gap: 2rem;
  max-height: 67.8vh;
  overflow-y: auto;
}

.tabWrapper {
  [class~='ant-tabs'] {
    height: 100% !important;
  }

  [class*='ant-tabs-content-holder']::-webkit-scrollbar {
    width: 5px !important;
  }

  [class='ant-tabs-nav']::before {
    border-bottom: 2px solid $light-gray !important;
  }

  [class~='ant-tabs-nav-list'] {
    width: 100%;
  }

  [class~='ant-tabs-tab'] {
    margin: 0 !important;
    justify-content: center;
  }

  [class~='ant-tabs-nav-operations'] {
    display: none !important;
  }
}

.tabWrapperNotMobile {
  [class*='ant-tabs-content-holder']::-webkit-scrollbar-track {
    margin-left: 10px !important;
  }

  [class*='ant-tabs-content-holder']::-webkit-scrollbar-thumb {
    margin-left: 10px !important;
  }

  [class='ant-tabs-nav'] {
    padding-right: 32px !important;
  }

  [class~='ant-tabs-tab'] {
    width: 30%;
  }

  [class~='ant-tabs-ink-bar'] {
    width: 30% !important;
    background: $orange !important;
  }
}

.nicheWrapperNotMobile::-webkit-scrollbar-track {
  margin-left: 10px !important;
}

.nicheWrapperNotMobile::-webkit-scrollbar-thumb {
  margin-left: 10px !important;
}

.tabWrapperMobile {
  height: 100%;

  [class*='ant-tabs-content-holder']::-webkit-scrollbar-track {
    margin-left: 1rem !important;
  }

  [class*='ant-tabs-content-holder']::-webkit-scrollbar-thumb {
    margin-left: 1rem !important;
  }

  [class='ant-tabs-nav'] {
    padding-right: unset !important;
  }

  [class~='ant-tabs-tab'] {
    width: 50%;
  }

  [class~='ant-tabs-ink-bar'] {
    margin-left: 1rem;
    width: 42% !important;
    background: $orange !important;
  }
}

.nicheWrapperMobile {
  height: 100%;
}

.nicheWrapperMobile::-webkit-scrollbar-track {
  margin-left: 1rem !important;
}

.nicheWrapperMobile::-webkit-scrollbar-thumb {
  margin-left: 1rem !important;
}

.tabWrapperMap {
  [class*='ant-tabs-content-holder'] {
    max-height: 59vh !important;
    overflow-y: auto !important;
  }
  @media (max-width: 600px) {
    [class*='ant-tabs-content-holder'] {
      overflow-y: auto !important;
    }
  }
}
.GreatTabWrapperMapOnly {
  max-height: 63vh !important;
  overflow-y: auto !important;

  @media (max-width: 600px) {
    overflow-y: auto !important;
  }
}

.GreatTabWrapperMapOnlyMobile {
  max-height: 56vh !important;
  overflow-y: auto !important;

  @media (max-width: 600px) {
    overflow-y: auto !important;
  }
}

.NicheTabWrapperMapOnly {
  max-height: 62vh !important;
  overflow-y: auto !important;

  @media (max-width: 600px) {
    overflow-y: auto !important;
  }
}

@media (max-width: 600px) {
  .tabWrapperNiche {
    [class*='ant-tabs-content-holder'] {
      width: calc(100% - 1.5rem) !important;
      margin-left: 1rem !important;
    }
  }
}

@media (max-width: 600px) {
  .wrapperNiche {
    width: calc(100% - 1.5rem) !important;
    margin-left: 1rem !important;
  }
}

@media (max-width: 600px) {
  .wrapperTabGreat {
    width: calc(100% - 1.6rem) !important;
    margin-left: 1rem !important;
  }
}

.wrapperGreat {
  margin-top: 8px;
}

@media (min-width: 420px) and (max-width: 440px) {
  .tabWrapperGreat {
    [class*='ant-tabs-content-holder'] {
      width: calc(100% - 1.6rem) !important;
      margin-left: 1rem !important;
    }
  }
}

@media (max-width: 419px) {
  .tabWrapperGreat {
    [class*='ant-tabs-content-holder'] {
      width: calc(100% - 1.5rem) !important;
      margin-left: 1rem !important;
    }
  }
}

.greatTabWrapperBigMapOnly {
  max-height: 24vh !important;
  overflow-y: auto !important;

  @media (max-width: 600px) {
    max-height: 28vh !important;
    overflow-y: auto !important;
  }
}

.nichTabWrapperBigMapOnly {
  max-height: 24vh !important;
  overflow-y: auto !important;

  @media (max-width: 600px) {
    max-height: 44vh !important;
    overflow-y: auto !important;
  }
}

.tabWrapperBigMap {
  [class*='ant-tabs-content-holder'] {
    max-height: 20vh !important;
    overflow-y: auto !important;
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    [class*='ant-tabs-content-holder'] {
      max-height: 76.5vh !important;
      overflow-y: auto !important;
    }
    [class*='ant-tabs-content-holder'] {
      max-height: 49vh !important;
      overflow-y: auto !important;
    }
  }

  @media (max-width: 600px) {
    [class*='ant-tabs-content-holder'] {
      max-height: 44vh !important;
      overflow-y: auto !important;
    }
  }
}

.tabRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summaryWrapper {
  display: flex;
  min-height: fit-content;
  padding: 1rem 1rem 1.25rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid $background-grey-2;
  background: #fff;
}

.ratingCardTitleMobile {
  color: $dark-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-style: var(--shared-font-style);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.01125rem;
}

.ratingSectionWrapMobile {
  margin-bottom: 15px;
  display: flex;
  gap: 7px;
  box-sizing: border-box;
}

.rightPanelRatingWrap {
  width: 89%;
  max-width: 89%;
  gap: 0 !important;
  padding-bottom: 1rem !important;
  margin-bottom: 17px !important;
}

.cardHeading {
  margin-bottom: -1px;
  color: $text-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 1rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1.625rem;
  letter-spacing: -0.02063rem;
}

.cardSubheading {
  border: 1px solid var(--shared-color);
  color: $dark-gray-3;
  margin-bottom: 0px;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.02063rem;
}

.cardSubheadingWrapper {
  margin-left: 0.61rem;
  align-items: center;
}

.cardSubheadingAccWrapper {
  margin-left: 0.61rem;
  align-items: center;
  display: flex;
}

.cardSubheadingTypography {
  margin-top: 3px;
  border: 1px solid var(--shared-color);
  color: $text--light-gray-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 0.875rem;
  letter-spacing: -0.02063rem;
}

.cardTypography {
  color: $dark-grey-color;
  width: -webkit-fill-available;
  white-space: break-spaces;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.01125rem;
}

.ratingText {
  color: black;
  text-align: center;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 1rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.01125rem;
}

.cardRatingWrapper {
  display: flex;
  margin-bottom: -10px;
}

.accordion {
  border: 1px solid $background-grey-2 !important;
  border-radius: 0.5rem !important;
  background-color: $white !important;
  margin-top: 1rem !important;
  padding: 10px 1rem 7px 1rem !important;
  min-height: fit-content !important;
}

.openAccordian {
  padding: 10px 1rem 0.5rem 1rem !important;
}

.accordionPreview {
  padding-top: 0 !important;
  padding-bottom: 0.5rem !important;
}

.iconContainer {
  background-color: transparent !important;
  width: 1rem !important;
}

.indicatorsWrap {
  display: flex;
  justify-content: space-between;
}

.indicatorTitle {
  color: $dark-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.02938rem;
  word-break: break-word;
  margin-right: 2px;
}

.indicatorValue {
  margin-right: 1px;
  color: $text-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.02938rem;
}

.divider {
  height: 0.8px;
  background-color: $background-grey-2;
  margin-top: -4px;
  margin-bottom: 9px;
}

.dividerListing {
  margin-bottom: 15px;
  margin-top: -2px;
  height: 0.8px;
  background-color: $light-gray;
}

.indicatorContainer {
  margin-top: 34px;
}

.emptyRatingContainer {
  margin-top: 13px;
}

.iconWrap {
  display: flex;
}

.indicatorSubheaderTitle {
  margin-top: -14px;
  color: $dark-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.02938rem;
}

.indicatorSubheaderValue {
  margin-top: -14px;
  color: $text-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.02938rem;
}

.summaryCardPositionNoData {
  margin-top: 0px;
}

.ratingContainer {
  margin-right: 5px;
}

.equityTestTitle {
  margin-bottom: 21px;
  margin-top: -3px;
}

.ratingTitle {
  margin-bottom: 0px !important;
  color: $text-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 1rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1.625rem;
  letter-spacing: -0.02063rem;
}

.ratingTypography {
  color: $dark-gray-3;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.02063rem;
}

.ratingWrap {
  display: flex;
  align-items: flex-end;
  width: 50%;
}

.indicatorWrap {
  display: flex;
  width: 100%;
}

.summaryLink {
  a {
    color: black !important;
    font-weight: bold !important;
    text-decoration: none !important;
  }

  a:hover {
    cursor: pointer;
  }
}

.rightPanelRatingCardTitle {
  margin-bottom: 5px;
  color: $dark-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem !important;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1.3rem;
  letter-spacing: -0.01125rem;
}

.rightPanelSummaryWrap {
  padding: 0.5rem 0.5rem 0 0.5rem !important;
  gap: 0 !important;
  width: 100% !important;
}

.rightPanelRatingTypography {
  color: $text--light-gray-color !important;
  font-feature-settings: var(--shared-font-feature-settings) !important;
  font-family: var(--shared-font-family) !important;
  font-size: 0.5625rem !important;
  font-style: var(--shared-font-style) !important;
  font-weight: 400 !important;
  line-height: 1.7rem !important;
  letter-spacing: -0.01125rem !important;
}

.detailLabel {
  color: $text--light-gray-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.01125rem;
  margin-bottom: 0.2rem !important;
}

.detailValue {
  color: $text-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.01125rem;
  word-break: break-word;
  margin-bottom: 0.3rem !important;
}

.rightPanelInfoWrap {
  width: 89%;
  max-width: 89%;
  gap: 0.3rem !important;
}

.mapWrap {
  height: 34%;
  min-height: 34%;
  margin-top: 0.5rem;
  width: 89%;
  margin-bottom: 17px;
}

.mapWrapMobile {
  height: 100%;
  width: 30%;
}

.ratingWrapMobile {
  background: $background-ligh-gray-5;
  background-color: $background-ligh-gray-5;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ratingMobileContainer {
  width: 100%;
}

.summaryCardPosition {
  margin-top: 2rem;
  @media (max-width: 600px) {
    margin-top: 1rem;
  }
}

.nicheSummaryWrapper {
  margin-right: 5px;
  margin-top: 2rem;
  @media (max-width: 600px) {
    margin-top: 1rem;
  }
}

.mapWrapper {
  border-radius: setUnit(8);
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: 0.3s ease all;
}

.mapDistanceWrap {
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02938rem;
  margin-top: 4px;
  margin-left: 6px;
  position: relative;
  z-index: 100;
  display: inline-flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 3.125rem;
  background: $primary-color;
  color: $white;
  box-shadow: 0px 8px 16px 0px rgba(61, 61, 61, 0.15);
}

.popupClassName {
  width: setUnit(176) !important;
}

.customTooltip {
  background: black;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  text-overflow: ellipsis;
  display: flex;
  width: auto;
  height: auto;
  z-index: 999999 !important;
}

.customTooltipListing {
  border-radius: 0.75rem;
  box-shadow: 0px 32px 80px 0px rgba(0, 0, 0, 0.06), 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
  background: $white;
  padding: 17px 45px 17px 17px;
  color: black;
  font-size: 12px;
  position: relative;
  text-overflow: ellipsis;
  display: flex;
  width: auto;
  height: auto;
  z-index: 999999 !important;
}

.toolTipSchoolName {
  margin-bottom: 3px;
  color: $text-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.toolTipAddress {
  color: $dark-grey-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem;
  font-style: var(--shared-font-style);
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.positionBigMapProperty {
  transform: translate(-50%, -175%);
}

.positionBigMapSchool {
  transform: translate(-50%, -185%);
}

.positionMapProperty {
  transform: translate(-50%, -170%);
}

.positionMapSchool {
  transform: translate(-50%, -180%);
}

.positionMapSchoolListing {
  transform: translate(-50%, -120%);
}

.tooltipArrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltipArrowListing {
  width: 13px;
  height: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid white;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.mapExpandWrap {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 5px;
  display: inline-flex;
  cursor: pointer;
}

.bigMapWrap {
  background-color: $background-ligh-gray-5;
  height: 17.68rem;
  padding-top: 1.2rem;
  width: 100%;
  margin-bottom: 0px;
  padding-left: 2rem;
  padding-right: 2rem;
  transition: all 1s;
}

.bigMapWrapMobile {
  background-color: $background-ligh-gray-5;
  height: 14.5rem;
  width: 100%;
  margin-bottom: 0px;
  transition: all 1s;
}

.groupTitle {
  margin-left: 1rem;
  margin-top: 0.4rem;
  color: $text--light-gray-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.labelClassName {
  margin-bottom: 0rem;
}

.checkboxWrapperClassName {
  width: 1rem !important;
  height: 1rem !important;
}

.listingSchoolNameTypography {
  margin-bottom: 4px;
  color: $text-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 1rem;
  font-style: var(--shared-font-style);
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
  text-decoration-line: unset;
}

.listingSchoolAddressTypography {
  margin-bottom: 4px;
  color: $dark-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.ratingWrapListing {
  display: unset !important;
  min-height: unset !important;
  padding: unset !important;
  flex-direction: unset !important;
  align-items: unset !important;
  gap: 0 !important;
  border: unset !important;
  background: unset !important;
}

.listingColumnsWrap {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
}

.listingColums {
  display: flex;
  color: $text-gray-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.reportIncorrectTypography {
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: $background-blue;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.InfoWrap {
  color: $dark-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem;
  font-style: var(--shared-font-style);
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 0.5rem;
  padding: 1rem 1.22rem 1.37rem 1.22rem;
  margin-bottom: 35px;
  background: $background-grey-2;
}

.infoWrapMarginMobile {
  margin-top: 16px;
}

.infoWrapMargin {
  margin-top: 29px;
}

.container {
  position: relative;
  width: fit-content;
  margin-bottom: 31px;
  margin-top: 17px;
}

.dropdownItems {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.reportWrap {
  margin-top: 31px;
  display: flex;
}

.schoolHeading {
  margin-left: 1px;
  margin-bottom: 14px;
  color: var(--REBRAND-Grayscale-100, #262626);
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 1.25rem;
  font-style: var(--shared-font-style);
  font-weight: 700;
  line-height: 1.3rem;
  letter-spacing: -0.01125rem;
}

.ratingListingMobile {
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 6.25rem;
  background: $background-grey-2;
}

.ratingListingMobileWrap {
  display: flex;
  margin-bottom: 15px;
  gap: 7px;
}

.ratingListingMobileTypography {
  color: $dark-color;
  text-align: right;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.8125rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.addressMobileTypography {
  color: $text-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.8125rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.01125rem;
  margin-top: 4px;
}

.infoMobileTypography {
  color: $text-gray-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.8125rem;
  font-style: var(--shared-font-style);
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.01125rem;
}

.wrapper {
  position: relative;

  .openBtn {
    font-weight: normal;
    font-size: setUnit(16);
    line-height: setUnit(20);
    padding: setUnit(10) setUnit(16);
    background: $hover-light-gray;
    border-radius: setUnit(8);
    cursor: pointer;
    display: flex;
    align-items: center;

    .openBtnIcon {
      padding-left: setUnit(8);
      margin-left: auto;
      height: setUnit(16);

      svg {
        height: setUnit(16);
        width: setUnit(16);
      }
    }
  }

  .itemsList {
    z-index: 10;
    position: absolute;
    top: setUnit(44);
    margin-left: 3px;
    display: flex;
    flex-wrap: wrap;
    color: $text-color;
    background-color: $white;
    box-shadow: 0px 2px setUnit(12) rgba(0, 0, 0, 0.15);
    border-radius: setUnit(8);
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s;
    width: 20.68rem;
    &[data-open='true'] {
      opacity: 1;
      visibility: visible;
    }

    .item {
      width: 100%;
      font-size: setUnit(16);
      line-height: setUnit(21);
      font-weight: normal;
      padding: setUnit(10) 1rem;
      transition: all 0.1s;
      cursor: pointer;
      display: flex;
      align-items: center;

      .itemIcon {
        margin-left: auto;
        visibility: hidden;
        opacity: 0;
        height: setUnit(16);

        svg {
          width: setUnit(16);
          height: setUnit(16);
        }
      }

      &:hover {
        background-color: $hover-light-gray;
      }

      &[data-selected='true'] {
        font-weight: 500;

        .itemIcon {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .ItemListWrap {
    width: 50%;
    border: 1px solid $background-ligh-gray-4;
  }

  .itemListPosition {
    padding: setUnit(12) 0;
  }
}

.disabled {
  cursor: not-allowed;
}

.listingSchoolNameTypography {
  margin-bottom: 4px;
  color: $text-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 1rem;
  font-style: var(--shared-font-style);
  font-weight: 600;
  line-height: 19px;
  word-break: break-word;
  letter-spacing: -0.00563rem;
}

.listingSchoolNameTypography:hover {
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.listingSchoolAddressTypography {
  word-break: break-word;
  margin-bottom: 4px;
  color: $dark-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.ratingWrapListing {
  display: unset !important;
  min-height: unset !important;
  padding: unset !important;
  flex-direction: unset !important;
  align-items: unset !important;
  gap: 0 !important;
  border: unset !important;
  background: unset !important;
}

.listingColumnsWrap {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
}

.listingColums {
  display: flex;
  color: $text-gray-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.875rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.reportIncorrectTypography {
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: $background-blue;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem;
  font-style: var(--shared-font-style);
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.InfoWrap {
  color: $dark-color;
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 0.75rem;
  font-style: var(--shared-font-style);
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 0.5rem;
  padding: 1rem 1.22rem 1.37rem 1.22rem;
  margin-top: 29px;
  margin-bottom: 35px;
  background: $background-grey-2;
}

.reportWrap {
  margin-top: 31px;
  display: flex;
}

.schoolHeading {
  margin-bottom: 14px;
  color: var(--REBRAND-Grayscale-100, #262626);
  font-feature-settings: var(--shared-font-feature-settings);
  font-family: var(--shared-font-family);
  font-size: 1.25rem;
  font-style: var(--shared-font-style);
  font-weight: 700;
  line-height: 1.3rem;
  letter-spacing: -0.01125rem;
}

.wrapperHeight {
  height: -webkit-fill-available;
  background-color: $background-ligh-gray-5;
  border: 1px solid transparent;
  border-radius: 0.75rem;
}

.rightPanelInfoWrapNoData {
  width: 100%;
  gap: 0.3rem !important;
  margin-top: 1rem;
}

.wrapperHeightMobile {
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  background-color: $background-ligh-gray-5;
  height: -webkit-fill-available;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.topSectionMobile {
  width: 100%;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 20px;
  background-color: $background-ligh-gray-5;
}

.topSectionWrapper {
  background-color: $background-ligh-gray-5;
  padding-bottom: 20px;
}

.topSectionMobilePlacementBigMap {
  display: block;
  height: 48%;
  max-height: 50%;
}

.topSectionMobilePlacement {
  display: flex;
  height: 14%;
  max-height: 16%;
}

.mapActionButtonWrap {
  cursor: pointer;
  margin-top: 12px;
}

.listingMapWrap {
  width: 100%;
  height: 17.6875rem;
  margin-top: -10px;
  margin-bottom: 35px;
}

.changeModeBtn {
  margin-left: auto;
  border: 0;
  outline: none;
  transition: all 0.1s;
  background-color: $hover-light-gray;
  border-radius: setUnit(8);
  width: setUnit(40);
  height: setUnit(40);
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  .changeModeBtnIcon {
    height: setUnit(24);

    svg {
      width: setUnit(24);
      height: setUnit(24);
    }
  }
}

.titleClassName {
  margin-top: 4px !important;
}

.nicheSummaryWrapperNoGreatSchool {
  margin-right: 5px;
  margin-top: -16px;
}
