@import 'styles/functions';
@import 'styles/variables';
@import 'pages/Quotes/styles.module';

.select {
  margin: 0 !important;
}

.activityTypes {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  .activity {
    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    border: 1px solid $light-grey-color;
    background: $white;
    color: $text-gray-color;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.18px;
    cursor: pointer;
    transition: $transition;
    &.active {
      background: $primary-color;
      border: 1px solid $primary-color;
      color: $white;
    }
  }
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background: #f4f5f6;

  .pageContent {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    padding-block: 24px;
    gap: 24px;

    .activityWrapper {
      max-width: 600px;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      .clarityActivityLogCard {
        border-radius: 16px;
        margin-bottom: 0 !important;
        overflow: hidden;
        padding-bottom: 20px;

        [class='ant-card-head'] {
          min-height: initial;
          padding: 24px 0 13px 0;

          [class*='ant-card-head-wrapper'] {
            margin: 0 auto;
            [class='ant-card-head-title'] {
              color: var(--Grayscale-100, #262626);
              text-align: center;
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: Inter;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 133.333% */
              letter-spacing: -0.33px;
            }
          }
        }
      }

      .noDataFound {
        width: 100%;
        background: white;
        border-radius: 16px;
        padding: 24px;
        text-align: center;
        color: var(--Black-100, #303030);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 112.5% */
        letter-spacing: -0.18px;
      }
    }

    .filterWrapper {
      background: white;
      padding: 4px 8px;
      max-width: 356px;
      width: 100%;
      border-radius: 16px;
      box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
      height: fit-content;

      .filterSection {
        padding: 4px 0;
        [class*='ant-collapse-item-active'] {
          padding-top: 0 !important;
        }
        .title {
          color: var(--Black-100, #303030);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 112.5% */
          letter-spacing: -0.18px;
        }

        .dataRangeWrapper {
          margin: 0 !important;
        }

        .multiSelectWrapper {
          margin: 0 !important;

          [class='ant-select-selector'] {
            border-radius: 8px !important;
            border: 1px solid var(--grayscale-25-outline, #d6d6d7) !important;
          }

          h4 {
            display: none !important;
          }
        }
      }
    }
  }
}
