@import 'styles/functions';
@import 'styles/variables';

.group {
  &:not(:last-child) {
    margin-bottom: setUnit(30);
  }
}

.taskTemplatesCollapse {
  .panelHeader {
    [class*='ant-collapse-header-text'] {
      font-weight: 600;
      font-size: 20px;
      .counterText {
        color: $text--light-gray-color;
      }
    }

    [class*='ant-collapse-content-box'] {
      padding-top: 0 !important;
    }

    [class='ant-collapse-header'] {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 16px !important;

      &:hover {
        background-color: #f4f5f680;
      }
    }

    [class*='ant-collapse-content'] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $light-gray;
    margin: 0.5rem 0;
  }
}
