@import 'styles/functions';
@import 'styles/variables';

.messageContainer {
  .userMessageContainer {
    display: flex;
    justify-content: flex-end;

    .userMessage {
      padding: 0.75rem;
      border: 1px solid $light-gray;
      border-radius: 8px;
      background-color: #f0f0f0;
      max-width: 450px;
    }
  }

  .aiResponseContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: setUnit(12);
    margin: setUnit(12) 0;

    .aiMessage {
      padding: 0.75rem;
      border: 1px solid $light-gray;
      border-radius: 8px;
      background-color: $white;
      max-width: 550px;
      display: flex;
      flex-direction: column;

      .reportLinks {
        margin-top: setUnit(12);
        display: flex;
        align-items: center;
        gap: setUnit(36);
      }
    }
  }
}
