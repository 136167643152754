@import 'styles/functions';
@import 'styles/variables';

.historySection {
  background-color: $white;
  width: 25%;
  padding: setUnit(48) setUnit(36);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .icon {
      &:hover {
        svg {
          g {
            path {
              stroke: $text--light-gray-color;
            }
          }
        }
      }
    }
  }

  .searchContainer {
    margin: setUnit(24) 0;
  }

  .historyContainer {
    .title {
      font-weight: 600;
      font-size: setUnit(16);
      margin: setUnit(5) 0;
    }

    .historyItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: setUnit(12);
      border-radius: 8px;
      padding: setUnit(8);
      margin-left: -8px;

      &:hover,
      &.active {
        background-color: $background-grey-2;
      }
    }
  }
}
