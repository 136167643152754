@import 'styles/functions';
@import 'styles/variables';

.label {
  font-size: 1rem;
  text-align: center;
  line-height: 1.3125rem;
  color: $border-middle-gray;
  padding: 3.125rem 0;
  height: calc(100vh - 400px);
  display: flex;
  align-items: center;
  justify-content: center;
}
