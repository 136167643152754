@import 'styles/functions';
@import 'styles/variables';

.taskViewModal {
  [class*='ant-modal-content'] {
    background-color: $background-gray;
    padding: 40px 14px 32px 40px !important;
    max-height: 697px;
  }

  &.descriptionTaskModal {
    [class*='ant-modal-content'] {
      background-color: $white;
    }
  }

  &.descriptionTaskModal {
    [class*='ant-modal-content'] {
      background-color: $white;
      padding: 40px 14px 32px 40px !important;
    }
  }
  .view {
    .notFoundMessage {
      color: $text-gray-color;
      height: 300px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: setUnit(16);
    }
  }

  .content {
    .descriptionView {
      margin-top: setUnit(16);
      width: 100%;
      max-height: 587px;
      color: var(--Primary-Navy, #252d44);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.09px;
      overflow-y: auto;
      padding-right: 21px;
    }
    .maxContentWidth {
      max-width: 360px;
    }
    .taskActions {
      justify-content: center !important;
      margin: setUnit(16) 0;
    }
    .optionDotsClassName {
      background-color: white;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      &:hover {
        background-color: $light-gray;
      }
    }
    .containerEmailTemplate {
      position: relative;
      .text {
        margin: 0;
        width: 320px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .iconEmailTemplate {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: -10px;
      }
    }
    .sectionsRow {
      margin-top: setUnit(24);
      height: 500px;

      .leftSection {
        overflow-y: auto;
        max-height: 500px;

        div:nth-last-child(1) {
          margin-bottom: 0;
        }
      }

      .taskLinks {
        width: 100%;
        margin-top: 0 !important;
      }
    }
  }
}
