@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  font-weight: 600;
  font-size: setUnit(14);
  line-height: setUnit(16);
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: setUnit(8);
}

.labelBlock {
  display: flex;
  align-items: center;
  .avatar {
    margin-right: setUnit(8);
    .avatarName {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
  .name {
    color: $text-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    letter-spacing: -0.09px;
    margin-bottom: 0;
  }
}
