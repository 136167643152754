@import 'styles/functions';
@import 'styles/variables';

$chatInputHeight: 420px;

.overflowHidden {
  overflow: hidden !important;
}

.clarityPage {
  padding: 0 !important;
  display: flex;

  .pageWrapper {
    display: flex;
    width: 100%;
  }

  .chatSection {
    background-color: #f9f9f9bf;
    overflow: scroll;
    width: 75%;
    padding: setUnit(48) setUnit(36);
    position: relative;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
        gap: 12px;

        .title {
          font-weight: 600;
          font-size: 24px;
        }
      }

      .right {
        .closeBtn {
          cursor: pointer;

          &:hover {
            svg {
              circle {
                fill: $light-gray;
              }
            }
          }
        }
      }
    }

    .conversationContainer {
      margin-left: 16%;
      margin-top: setUnit(24);
      width: 700px;
      overflow-y: scroll;
      height: calc(100vh - #{$chatInputHeight});
      &::-webkit-scrollbar {
        display: none; /* Hide the scrollbar in Webkit browsers (Chrome, Safari) */
      }
    }
  }
}
