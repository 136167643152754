@import 'styles/functions';
@import 'styles/variables';

.chatInputContainer {
  position: fixed;
  bottom: setUnit(90);
  left: 35%;
  width: 700px;
  display: flex;
  justify-content: center;

  .chatAreaContainer {
    position: relative;
    width: 100%;

    .chatTextArea {
      border-radius: 12px;
      height: setUnit(100);
      font-size: setUnit(16);
      padding: setUnit(16);
      padding-right: setUnit(80);
    }

    .sendBtn {
      position: absolute;
      right: setUnit(18);
      bottom: setUnit(18);
      opacity: 50%;
      border-radius: 12px;
      width: setUnit(44);
      height: setUnit(44);
      display: flex;
      align-items: center;
      justify-content: center;

      .flexIcon {
        display: flex;
        svg {
          height: 24px;
          path {
            stroke: $white !important;
          }
        }
      }
    }
  }
}
