@import 'styles/functions';
@import 'styles/variables';

.details {
  padding-bottom: 1.5rem;
  .vendorWrap {
    display: flex;
    align-items: center;

    @media screen and (max-width: 850px) {
      flex-direction: column;
    }

    .icon {
      width: setUnit(48);
      height: setUnit(48);
    }
    .info {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .block {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .line1 {
          padding-left: setUnit(10);
          font-size: setUnit(20);
          font-weight: 600;
        }
        .line2 {
          padding-left: setUnit(10);
          font-size: setUnit(16);
          font-weight: 400;
          color: $text-gray-color;
        }
      }
    }
  }
  .propertyInfo {
    margin-top: setUnit(25);
    .title {
      font-size: setUnit(14);
      font-weight: 600;
    }
    .contentFirstRow {
      font-size: setUnit(16);
      font-weight: 400;
    }
    .contentSecondRow {
      font-size: setUnit(16);
      font-weight: 400;
      color: $text-gray-color;
    }
  }

  .clientInfo {
    margin-top: setUnit(25);
    .title {
      font-size: setUnit(14);
      font-weight: 600;
    }
    .contentFirstRow {
      font-size: setUnit(16);
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 768px) {
  .details .appointmentInfo {
    display: block;
    margin-top: 0.5rem;

    .dateTime {
      width: 100%;
    }

    .requestor {
      display: block;
      margin-top: 1rem;
    }
  }
}

.clickable {
  cursor: pointer;
}
