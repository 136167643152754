@import 'styles/functions';
@import 'styles/variables';

.states {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: $text--light-gray-color;
}

.vendorDrafts {
  margin-top: 32px;
}

.draftCard {
  padding: 16px;
  background-color: $white;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .left {
    display: flex;
    align-items: center;

    .logo {
      margin-right: 16px;
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }

    .logoIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(70, 115, 209, 0.1);
    }

    .avatar {
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }

    .info {
      .name {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: $text-color;
      }

      .date {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: $dark-color;
      }
    }
  }

  .right {
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: $background-grey-2;
    }

    .menu {
      position: absolute;
      box-shadow: 0px 4px 16px 0px #00000024;
      border-radius: 8px;
      padding: 8px;
      background-color: $white;
      width: max-content;
      top: 24px;
      right: 8px;
      z-index: 1000;

      > .item:first-of-type {
        margin-bottom: 10px;
      }

      .item {
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 4px;

        &:hover {
          background-color: $background-grey-2;
        }

        .icon {
          color: $primary-color;
        }

        .label {
          margin-left: 6px;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: $text-color;
        }
      }
    }
  }
}
