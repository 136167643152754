@import 'styles/functions';
@import 'styles/variables';

.comments {
  height: 100%;
  .heading {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.47px;
    color: $text-color;
    margin: 0.1rem auto 0;
    max-width: 75%;
    span {
      position: relative;
      display: inline-block;
    }
  }

  &.commentsModal {
    height: 550px;
  }

  .holder {
    position: relative;
    height: 100%;

    &.holderModal {
      height: 100%;
    }

    .commentContent {
      margin: 12px 0;
      &.emptyContent {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .avatarPlaceholder {
    font-size: setUnit(14);
  }

  .pendingWrapper {
    height: 100%;

    .loader {
      font-size: setUnit(5);
      margin: setUnit(75) auto;
    }
  }
  .CommentsViewWrapper {
    height: 100%;
    width: 100%;

    .messages {
      max-height: 100%;
      width: 100%;
      overflow-y: auto;
    }
  }
  .messages,
  .pendingWrapper {
    &::-webkit-scrollbar {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $border-light-gray-2;
      }
    }
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;

    &.emptyModal {
      display: flex;
      justify-content: center;
      align-items: center;
      height: -webkit-fill-available;
    }

    .message {
      font-size: setUnit(16);
      font-weight: 100;
      color: $text--light-gray-color;
    }
  }

  .commentsForm {
    .footer {
      max-width: 100%;
      background: none;
      position: relative;
      align-items: flex-start;
      padding: 1rem 1.875rem 0 0 !important;
      .inputGroup {
        max-width: 100% !important;
        margin-top: 5px;
        & > div {
          padding-left: 0;
          padding-right: 0;
          & > div {
            height: auto;
          }
        }
      }

      .footerInner {
        padding: 0 !important;
      }
    }
    .footer::before {
      content: '';
      position: absolute;
      top: 0;
      width: calc(100% - 21px);
      height: 0.5px;
      background-color: $light-gray;
      box-sizing: border-box;
    }
  }

  .commentsFormModal {
    height: 100%;
  }

  .sendButton {
    background: none !important;
    bottom: 0;
    align-items: flex-end !important;
    svg {
      path {
        stroke: $dark-grey-color !important;
      }
    }
  }
}
