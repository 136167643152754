@import 'styles/functions';
@import 'styles/variables';

.table {
  tr:hover {
    td {
      background-color: $white !important;
      cursor: auto !important;
    }
  }
}

.placeholder {
  display: flex;
  width: 100%;
  height: 240px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid $background-grey-2;
  background: $white;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.06);
  color: $text--light-gray-color;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.33px;
}

.tabWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  column-gap: 40px;

  .tabItem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 4px;
    padding: 14px 0 12px 0;
    cursor: pointer;
    transition: $transition;

    color: var(--Grayscale-50, #747475);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;

    border-bottom: 3px solid transparent;
    .iconWrapper {
      display: flex;
    }

    &.active {
      color: var(--Grayscale-100, #262626);
      border-bottom: 3px solid var(--Primary-50, #ff576d);
      div {
        color: #ff576d;
      }
    }
  }
}

.cursor {
  cursor: pointer !important;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  background: rgba(0, 177, 82, 0.1);
  width: 32px;
  height: 32px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.descInfo {
  padding: 10px 4px;
  color: var(--White, #fff);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.09px;

  p {
    margin-bottom: 0 !important;
  }
  .heading1 {
    font-weight: 600;
  }
  .heading2 {
    font-weight: 500;
  }
  .heading3 {
    font-weight: 400;
    opacity: 0.8;
  }
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    padding: 12px 16px;
    border-radius: 100px;
    background: var(--Primary-Navy, #252d44);

    color: var(--White, #fff);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.09px;

    cursor: pointer;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.errorTableText {
  color: var(--Tertiary-Error-Red, #ec455e);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.33px;
}

.iconButton {
  background-color: transparent !important;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
  &:hover {
    background-color: transparent !important;
    border: 0 !important;
  }
  &:focus {
    border: 0 !important;
  }
}
