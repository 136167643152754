@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.cellClassName {
  color: purple;
}

.gridContainer {
  margin-top: setUnit(20);
}

.mlsDashboardTabs {
  [class='ant-tabs-tab-btn'] {
    text-shadow: none !important;
  }
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: setUnit(16);

  .searchWrapper {
    width: 100%;
    .inputWrap {
      padding-left: 0 !important;
    }
    input {
      background: $white !important;
      border: 2px solid $border-light-gray-2 !important;
      font-size: setUnit(16);
      height: 44px;
      border-radius: 100px !important;
      &:hover {
        border: 2px solid $text--light-gray-color;
      }
      &::placeholder {
        color: $text--light-gray-color;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    gap: setUnit(12);

    .refetchBtn,
    .errorsFilter {
      border-radius: 8px;
      height: 44px;
    }

    .errorsFilter {
      &:hover {
        background-color: transparent !important;
        color: $hover-red !important;
      }

      &.active {
        background-color: $hover-red !important;
        border-color: $hover-red !important;
        color: $white !important;
        svg {
          path {
            stroke: $white !important;
          }
        }
      }
    }
  }

  .refetchBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      path {
        stroke: $white !important;
      }
    }
  }
}

.jobDetail {
  display: flex;
  flex-direction: column;
  align-items: normal;
  margin: 10px 0;

  .errorContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .errorText {
      max-width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .errorTooltip {
      [class='ant-tooltip-inner'] {
        width: fit-content;
        max-width: 250px;
        white-space: normal;
      }

      .infoIcon {
        height: 20px;
        width: 20px;

        svg {
          path {
            stroke: red;
          }
          circle {
            fill: red;
          }
        }
      }
    }
  }
}

.mlsModal {
  padding: setUnit(20);
  .header {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
  }
  .content {
    .label {
      font-weight: 600;
    }
  }
}
