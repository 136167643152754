@import 'styles/mixins';
@import 'styles/variables';

.wrapper {
  .subtitle {
    color: var(--REBRAND-Grayscale-100, #262626);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    letter-spacing: -0.47px;
    margin-bottom: 22px;
  }
  [class='ant-modal-title'] {
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--REBRAND-Grayscale-100, #262626);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.47px;
  }

  [class='ant-modal-header'] {
    background: #f4f6fb;
    padding-top: 32px;
    padding-bottom: 2px;
  }

  [class='ant-modal-content'] {
    background: #f4f6fb;
    padding: 0 40px 32px 40px;
    border-radius: 24px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  }

  [class='ant-modal-close'] {
    top: 20px;
    right: 20px;
  }

  [class='ant-modal-body'] {
    padding: 0 !important;
  }

  .contentWrapper {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    border-radius: 16px;
    background: #fff;
    padding: 20px 21px 40px 20px;

    .emailMetaData {
      color: var(--Grayscale-100, #262626);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.18px;
    }
  }
  .content {
    margin-top: 24px;
    border-radius: 16px;
    background: #fff;
    white-space: pre-line;
    padding: 20px;
    margin-bottom: 20px;

    .inputWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 14px;

      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .inputContainer {
        flex-grow: 1;

        .input {
          padding: 0px !important;
          border: none !important;
          border-radius: 0 !important;

          color: var(--Grayscale-50, #747475);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 137.5% */
          letter-spacing: -0.18px;

          &:focus {
            border: none !important;
            box-shadow: none !important;
          }

          &::placeholder {
            color: var(--Grayscale-50, #747475);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
            letter-spacing: -0.18px;
          }
        }
      }
    }
  }
}

.cellWrapper {
  display: flex;
  align-items: center;
  gap: 20px;

  .multiSelect {
    .checkbox {
      [class*='ant-checkbox'] {
        [class*='ant-checkbox-inner'] {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          border-color: $text--light-gray-color;
        }
      }

      [class*='ant-checkbox-checked'] {
        [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color;
          border-radius: 4px;
        }
        [class*='ant-checkbox-inner']::after {
          width: 7px;
          height: 12px;
          left: 25%;
          top: 45%;
        }

        &::after {
          border-color: $primary-color;
          border-radius: 4px;
        }
      }
    }

    &:hover,
    &:focus {
      [class*='ant-checkbox-inner'] {
        border-color: $primary-color;
      }
    }
  }

  .name {
    color: var(--Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.33px;
  }
}

.actionIcons {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tableContainer {
  height: 434px;
  overflow-y: auto;
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    width: 180px;
  }
}

.subtitle {
  color: var(--Grayscale-100, #262626);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.47px;
  margin-bottom: 22px;
}

.mt {
  margin-top: 6px;
}

.alignRight {
  text-align: right !important;
}

.mainContent {
  position: relative;
  &.blur {
    filter: blur(10px);
  }

  .kitReplies {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .kitRepliesText {
      color: var(--REBRAND-Grayscale-100, #262626);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      letter-spacing: -0.47px;
    }
    .kitRepliesSort {
      width: 24px;
      height: 24px;
    }
  }
}
.mainContentBlur {
  filter: blur(10px);
}

.replyOverlay {
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  width: 100%;
  height: fit-content;
  box-shadow: 0px 24px 80px 0px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  max-height: 100%;
  overflow-y: hidden;

  .textEmail {
    max-height: 65% !important;
    overflow-y: auto;
    padding-bottom: 8px;
    &.contentShort {
      max-height: 49% !important;
    }
  }
}

.kitReplyMultiContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 511px;
  overflow-y: auto;

  .kitReplyAvatar {
    width: 10px;
    height: 10px;
  }

  .replyHeader {
    display: flex;
    padding: 20px;
    flex-direction: row;
    gap: 12px;
    border-radius: 16px;
    background: #fff;

    .senderInfoWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .senderInfo {
        display: flex;
        flex-direction: column;
        position: relative;
        .replyMinimize {
          position: absolute;
          right: 12px;
          top: 12px;
          cursor: pointer;
        }
        .senderInfoFrom {
          color: var(--Grayscale-100, #262626);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 137.5% */
          letter-spacing: -0.18px;
        }
        .senderInfoDate {
          color: var(--Grayscale-80, #515151);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: -0.18px;
        }
      }
    }
  }
}

.kitReplyContentWrapper {
  border-bottom: none;
  border-radius: 16px;
  background: #fff;
  padding: 20px 20px;

  .kitReplyAvatar {
    width: 10px;
    height: 10px;
  }

  .kitReplyContent {
    display: flex;
    gap: 12px;
    .senderInfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .replyMinimize {
        position: absolute;
        right: 0px;
        top: 0px;
        cursor: pointer;
      }

      .emailMetaData {
        color: var(--Grayscale-100, #262626);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.18px;

        .fw500 {
          font-weight: 500;
        }
      }
      .textEmail {
        margin-top: 24px;
        overflow-y: auto;
        max-height: 511px;
      }

      .contentWrapper {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        border-radius: 16px;
        background: #fff;
        padding: 0px;
      }

      .senderInfoFromName {
        display: flex;
        flex-direction: row;

        .senderInfoFrom {
          color: var(--Grayscale-100, #262626);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: -0.18px;
        }
        .senderInfoFromEmail {
          color: var(--Grayscale-80, #515151);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.1px;
        }
      }

      .senderInfoDate {
        color: var(--Grayscale-80, #515151);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.18px;
      }
    }
  }
}

.kitReplyDisclaimer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 8px;
  .disclaimerIcon {
    margin-top: 2px;
  }
  .disclaimerText {
    color: var(--Grayscale-50, #747475);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
  }
}

.kitReplyAvatarWrapper {
  position: relative;
  .kitReplyAvatar {
    width: 32px !important;
    height: 32px !important;
    font-size: 12px;
  }
  .kitReplyAvatarInner {
    position: absolute;
    top: 15px;
    left: 13px;
  }
}

.kitPreviewIconWrapper {
  .kitPreviewIcon {
    cursor: pointer;
  }
}
