@import 'styles/functions';
@import 'styles/variables';

.rightSectionTabs {
  height: 100%;
  min-height: 500px;

  &.disabled {
    opacity: 0.5;
  }

  .overlaySheet {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    opacity: 0;
  }

  .viewTaskTabs {
    padding: 8px 20px 0px 20px;
    height: 500px;
    background-color: $white;
    border-radius: 12px;

    [class*='ant-tabs-content'],
    [class*='ant-tabs-tabpane'] {
      height: 100%;
    }

    .linkedFormsContainer,
    .documentsContainer {
      margin: setUnit(16) 0;
    }

    .addFilesBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: setUnit(8);
      width: 100%;
      height: 52px;
      border-radius: 8px;
      margin-top: setUnit(16);

      .btnIcon {
        display: flex;
      }
    }

    .taskCommentsList {
      min-height: 309px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
