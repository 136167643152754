@import 'styles/functions';
@import 'styles/variables';

.savedSearchBtn {
  background-color: #f4f6fb !important;
  border-radius: 8px !important;
  gap: 2px !important;
  margin: 0 setUnit(16) !important;
  padding: 4px 15px !important;
  width: 236px !important;
  @media (max-width: 1250px) {
    width: 180px !important;
  }
}

.whiteLayerClassname {
  background-color: #f4f6fb !important;
}

.tabBtnText {
  display: flex;
  align-items: center;
  gap: 8px;
  .text {
    min-width: 120px;
    max-width: 190px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $secondary-color;
    font-weight: 600;
  }
  .icon {
    margin-top: setUnit(5);
  }
  &.rotateIcon {
    .icon {
      transform: rotate(-180deg);
    }
  }
}
