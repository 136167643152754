@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.detailField {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .input {
    @include lightRounded;
    border-color: $light-grey-color;
    transition: all 0.2s !important;

    &Hide,
    &Show {
      button {
        visibility: hidden !important;
        opacity: 0 !important;
        cursor: default !important;
        transition: opacity 0.3s ease-in-out;
      }
    }

    &Show:hover {
      button {
        visibility: visible !important;
        opacity: 1 !important;
        cursor: pointer !important;
        transition: opacity 0.3s ease-in-out;
      }
    }

    input::placeholder {
      font-size: 16px !important;
      color: $text--light-gray-color !important;
      font-weight: 400;
      line-height: 20px !important;
      letter-spacing: -0.18px;
    }

    &:focus-within {
      border-color: $text-gray-color !important;
    }

    input {
      padding: 0 !important;
      height: auto !important;
      border: none !important;
      margin-top: 2px !important;
    }

    button {
      top: 55% !important;
    }
  }

  .validation {
    font-size: 0.875rem;
    color: $error-color;
    margin-top: 0.1875rem;
  }
}
