@import 'styles/variables';
@import 'styles/mixins';

.removeRadio {
  position: absolute;
  top: -9px;
  right: -9px;
  width: 15px;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}
