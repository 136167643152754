@import 'styles/functions';
@import 'styles/variables';

.archiveContainer {
  position: absolute;
  bottom: 66px;
  right: 0;
  left: 0;
  padding-bottom: setUnit(24);
  margin-left: 5rem;
  background-color: $white;

  .content {
    display: flex;
    justify-content: center;
    margin-right: 158px;
    margin-left: 110px;
    padding-top: setUnit(24);
    border-top: 2px solid $background-grey-2;
    .wrapper {
      display: flex;
      justify-content: center;
      align-self: center;
      gap: setUnit(16);
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .archiveText,
      .countText {
        margin-bottom: setUnit(0);
      }
      .archiveText {
        font-size: 18px;
        font-weight: 600;
      }

      .countText {
        font-size: 14px;
        color: $light-gray-2;
      }
    }
  }
}
