@import 'styles/functions';
@import 'styles/variables';

.headerTitle {
  display: flex;
  .headerText {
    font-family: $font-family;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 14px;
  }
}

.kitsEditModal {
  .kitsFields {
    .detailsEditor {
      [class*='ql-editor'] {
        min-height: 130px !important;
        max-height: 600px !important;
        overflow-y: auto;
      }
    }
    .field {
      input::placeholder {
        color: $border-middle-gray;
      }
    }
  }
  .submitBtn {
    margin-top: setUnit(32);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .kitsNotes {
    margin-bottom: setUnit(16);
    display: flex;
    flex-direction: column;
    align-items: center;

    .notesButton {
      border-radius: 6px;
      padding-top: 6px;
      padding-bottom: 6px;
      margin-top: 16px;
    }
  }
  .name,
  .category,
  .frequency,
  .propertyTypes,
  .inAppReminder,
  .kitsFields,
  .eligibleLocations,
  .restrictedLocations {
    margin-bottom: setUnit(16);

    .sectionLabel {
      margin-right: setUnit(16);
      font-weight: 600;
      font-size: setUnit(16);
      line-height: setUnit(20);
      font-family: $font-family;
      letter-spacing: setUnit(-0.18);
      color: $text-color;
    }

    .error {
      color: $hover-red;
    }

    .selector {
      width: setUnit(288);
    }
  }
}
