@import 'styles/functions';
@import 'styles/variables';

.ccTo {
  margin-bottom: 2rem;

  .ccToWrapper {
    margin: 0 !important;
  }

  .ccToInputLabel > div > label {
    margin-bottom: 0.5rem;
  }

  .ccToInputLabel > div > label > span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
}
