@import 'styles/variables';
@import 'styles/mixins';

.lineHeight {
  line-height: 0px;
}

.hiddenTypeWrap {
  justify-content: center;
  width: 100%;
}

.fieldWrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: setUnit(14);
}

.editorFieldWrapper {
  font-size: setUnit(13);
}

.required::after {
  content: '*';
  color: $error-color;
  position: absolute;
  font-size: 24px;
  right: 5px;
  top: 45%;
}

.formEditorField {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 2px;

  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  container-type: size;

  &:has(.checkbox) {
    justify-content: center;
  }

  .icon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 6px !important;
  }

  .iconText {
    width: 12px !important;
    height: 12px !important;
    margin-right: 6px !important;
  }
}

/* Checkbox Field */
.checkboxField {
  padding-left: 0px;

  .checkbox {
    @include flexCenter;
    background-color: white;
    aspect-ratio: 1;
    width: 60cqw;
  }
}

.checkboxRequiredField::after {
  content: '*';
  color: $error-color;
  position: absolute;
  font-size: 24px;
  right: -22px;
  top: 45%;
}
