@import 'styles/variables';
@import 'styles/mixins.scss';

.settingInformation {
  padding: 0px 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;
  height: 100%;

  cursor: pointer;

  &:hover {
    background-color: rgba($dark-color, 0.5);
  }
}

.settingsDropdownWrapper {
  position: relative;
  height: 100%;

  @media (max-width: $mobile) {
    margin: setUnit(10) 0;
  }
}

.settingPopover {
  width: setUnit(216);

  .optionsItems {
    width: 100%;

    ul[class~='ant-dropdown-menu'] {
      border-radius: setUnit(8);
      box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
      padding: setUnit(8) 0;
      width: 100%;
      transition: $transition;
      background-color: $white;

      li:hover {
        background-color: #fff;
      }
    }
  }
}

.inputBlock {
  .label {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
    margin-bottom: 12px;
    color: $text-color;
  }

  .inputHolder {
    @include lightRound;
    border: none;
    width: 100%;
    padding: 0px !important;

    input {
      border: 1px solid $border-light-gray-2 !important;
      font-size: setUnit(16);
      line-height: 20.8px;
      border-radius: 8px;
      &:focus {
        border-color: $text--light-gray-color !important;
      }
    }

    [class*='inputField'] {
      padding-left: 0px !important;
    }

    .inputHolderDropdown {
      [class~='ant-select-item-option'] {
        padding: 16px 24px !important;
        height: setUnit(48);
        display: flex;
        align-items: center;
        [class~='ant-select-item-option-content'] {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.09px;
          color: $dark-color;
          padding: 0;
        }
        &[class~='ant-select-item-option-selected'] {
          background-color: $background-grey-2 !important;
        }
        [class~='ant-select-item-option-state'] {
          display: none;
        }
      }
    }
  }

  [class~='ant-select-selector'] {
    border: 1px solid $border-light-gray-2 !important;
    box-shadow: none !important;
    padding: setUnit(7) setUnit(13) !important;
    border-radius: 8px !important;
    font-size: setUnit(16) !important;
    height: setUnit(48) !important;

    &:focus {
      border-color: $text--light-gray-color !important;
    }
  }

  [class~='ant-select-open'] {
    [class~='ant-select-selector'] {
      border-color: $text--light-gray-color !important;
    }
  }
}

.validationValue {
  margin-top: 16px;
}

.rangeValidation {
  display: flex;
  align-items: center;
  gap: 8px;

  .separator {
    color: $text--light-gray-color;
  }
}
