@import 'styles/functions';
@import 'styles/variables';

.favWrapper {
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding-top: 2px !important;
  border-radius: 30px;
  &:hover {
    background: $background-grey-2;
  }

  .pendingWrapper {
    display: flex;
    align-items: center;

    .loader {
      margin: 0.6875rem;
      font-size: 2px;
    }
  }

  &.buyer {
    padding: 0;
    height: 2rem;
    min-width: 2rem;
    cursor: pointer;
    margin-right: 0;
  }

  .icon {
    display: inline-flex;
    svg {
      width: setUnit(25);
      height: setUnit(25);
    }
  }

  .count {
    font-size: setUnit(14);
    font-weight: 600;
    line-height: setUnit(16);
    letter-spacing: setUnit(-0.09);
  }
}
