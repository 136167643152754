@import 'styles/functions';
@import 'styles/variables';

.uploadLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  .uploaded {
    color: $hover-red;

    .remove {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .avatar {
      height: 56px;
      width: 56px;

      [class*='avatar'] {
        height: 56px !important;
        width: 56px !important;
      }
    }
  }

  .uploading {
    opacity: 0.7;
    cursor: default;
  }

  .upload {
    color: $text-color;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .icon {
      background-color: $background-grey-2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
      width: 56px;
    }
  }
}
