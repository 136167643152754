@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.highlights {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .clickable {
    cursor: pointer;
  }

  .recentQuoteTitle {
    font-size: setUnit(16);
    font-weight: 600;
  }

  .recentQuoteDate {
    font-size: setUnit(14);
    font-weight: 400;
    color: $text-gray-color;
  }

  .disclaimer {
    text-align: center;
  }

  .content {
    margin-top: setUnit(32);

    .itemBlock {
      .itemProperty {
        max-width: 400px !important;
      }

      .itemValue {
        max-width: 200px !important;
      }
    }
  }
}
