.pageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background: #f4f5f6;

  .headerPadding {
    max-height: 145px;
    min-height: 100px;
  }

  .titleInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-width: 384px;

    .editHeaderIcon {
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      position: absolute;
      top: 5px;
      right: -10px;
      width: 24px;
      height: 24px;
      color: var(--Grayscale-80, #515151);
      z-index: 30;
    }

    .title {
      text-align: center;
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;

      /* Page Title */
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 112.5% */
      letter-spacing: -0.4px;

      margin-bottom: 8px;

      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    .description {
      text-align: center;
      color: var(--Grayscale-40, #aaabab);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20.8px; /* 115.556% */
      letter-spacing: -0.18px;
    }

    .titleInput {
      position: relative;
      width: fit-content;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .hiddenSpan {
        visibility: hidden;
        white-space: nowrap;
        position: absolute;
        font-size: inherit;
        font-family: inherit;
        padding: 0;
      }

      &:hover .editHeaderIcon {
        opacity: 1;
      }
      .titleText {
        margin-bottom: 0;
        width: fit-content;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100vw - 245px);
      }
      .input {
        text-align: center;
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'liga' off, 'clig' off;

        /* Page Title */
        font-family: Inter;
        font-size: 32px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 36px; /* 112.5% */
        letter-spacing: -0.4px;

        border: none;
        padding: 0;

        overflow: hidden !important;
        text-overflow: ellipsis;

        &:focus,
        &:focus-visible {
          outline: none !important;
          box-shadow: none !important;
        }

        &::placeholder {
          text-align: center;
          color: var(--Grayscale-100, #262626);
          font-feature-settings: 'liga' off, 'clig' off;

          /* Page Title */
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px; /* 112.5% */
          letter-spacing: -0.4px;
        }

        &:disabled {
          color: var(--Grayscale-100, #262626) !important;
          background-color: white !important;
        }
      }
    }

    .descriptionInput {
      margin-top: 5px;

      &:hover .editHeaderIcon {
        opacity: 1;
      }

      .descriptionText {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0;
      }
      .input {
        text-align: center;
        color: var(--Grayscale-40, #aaabab);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20.8px; /* 115.556% */
        letter-spacing: -0.18px;

        border: none;
        padding: 0;

        &:focus,
        &:focus-visible {
          outline: none !important;
          box-shadow: none !important;
        }

        &::placeholder {
          text-align: center;
          color: var(--Grayscale-40, #aaabab);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 20.8px; /* 115.556% */
          letter-spacing: -0.18px;
        }

        &:disabled {
          color: var(--Grayscale-100, #262626) !important;
          background-color: white !important;
        }
      }
    }
  }

  .pageContent {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    padding-block: 24px;
    gap: 24px;
    flex-direction: column;

    .cardsWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
      width: 100%;
      max-width: 964px;
      width: 100%;
    }
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
