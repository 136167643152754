@import 'styles/functions';
@import 'styles/variables';

.emailTemplate {
  font-size: 16px;
  line-height: 20px;
  color: $text-color;

  .section:first-of-type {
    margin-bottom: 20px;
  }

  .section {
    background-color: $white;
    border-radius: 16px;
    padding-bottom: 32px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      height: 20px;

      .heading {
        font-weight: 600;
      }

      .copy {
        color: $text-gray-color;

        &:hover {
          background-color: $background-grey-2;
        }
      }

      .copied {
        color: $green-color;
        background-color: #04a45114;
      }

      .copy,
      .copied {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;
        border-radius: 12px;
        padding: 8px;

        div {
          display: flex;
        }
      }
    }

    .note {
      border-radius: 16px;
      font-weight: 400;
      border: 1px solid $border-light-gray-2;
      padding-bottom: 14px;
      padding-top: 14px;
      padding-left: 16px;
      padding-right: 16px;

      &Body {
        max-height: 285px;
        overflow-y: auto;
      }
    }
  }
}
