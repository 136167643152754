@import 'styles/functions';
@import 'styles/variables';

.dueDate {
  position: relative;

  &.marginBottom {
    margin-bottom: 2rem;
  }

  .dueDateWrapper {
    display: flex;
    position: relative;
    align-items: center;
    &.gap {
      gap: 12px;
    }
  }

  &.marginBottomSmall {
    margin-bottom: 1rem;
  }

  &.marginBottomSmall {
    margin-bottom: 1rem;
  }

  .marginTopRepeat {
    margin-top: 3.5rem !important;
  }

  .fieldWrapper > label {
    margin-bottom: 0.5rem;
  }

  .fieldWrapper > label > span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .fieldWrapper {
    .datePicker {
      display: flex;
      position: relative;
      width: 100%;
      .templateControlPicker {
        border-bottom: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        left: 5px;
        cursor: pointer;
        svg {
          position: relative;
          top: unset;
        }
      }
      .disabledPicker {
        cursor: default;
      }
      .datePickerInput {
        input {
          padding-left: 36px !important;
        }
      }
      .errorMsg {
        position: absolute;
        bottom: -1.6rem;
        font-size: 1rem;
        right: 0rem;
        width: fit-content;
        color: $error-color;
      }
      .tooltipContainer {
        position: absolute;
        top: 15px;
        right: 10px;
      }
      .infoText {
        position: absolute;
        right: 12px;
        font-size: 12px;
        top: 16px;
        color: $text--light-gray-color;
      }
    }

    .atText {
      color: $text-gray-color;
      font-size: 1rem;
      margin-left: setUnit(8);
    }
  }
}
