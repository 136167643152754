@import 'styles/functions';

.listBoxInput {
  width: 100% !important;
}

.tagSelector {
  width: 100% !important;

  [class*='ant-select-clear'] {
    right: 12px !important;
    width: 15px;
    height: 15px;
  }
}

.addListOptions {
  display: flex;
  flex-flow: row;
  width: 100% !important;
  padding: 0px 8px 4px;
}

.addOptionInput {
  width: 100% !important;
}

.addOptionBtn {
  margin: 0px 5px 5px;
  line-height: 32px;
}

.divider {
  margin: 8px 0px !important;
}

.link {
  white-space: nowrap;
}

.checkBoxIcon {
  width: 22px !important;
  height: 22px !important;
}

.popupClassname {
  z-index: 9999 !important;
  min-width: setUnit(144) !important;
  color: var(--Grayscale-100, #262626);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px; /* 130% */
  letter-spacing: -0.18px;
  [class*='ant-select-item-option-content'],
  [class*='ant-select-item-option-state'] {
    display: flex !important;
    align-items: center !important;
  }

  & [class*='ant-select-item-option-selected'] {
    background-color: unset !important;
    &:hover {
      background-color: #f4f5f6 !important;
    }
    [class*='ant-select-item-option-state'] {
      line-height: 0px;
    }
  }

  [class*='rc-virtual-list-holder'] {
    overflow-y: auto !important;
  }
}
