@import 'styles/functions';
@import 'styles/variables';

.tagSelectorContainer {
  .label {
    font-size: 14px !important;
  }
  .customTag {
    cursor: pointer !important;
    & [class~='ant-select-selector'] {
      box-shadow: none !important;
      border: none !important;
      border-bottom: 2px solid $border-light-gray-2 !important;
      padding: 0px !important;
      display: flex !important;
      align-items: center;
      input {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.09px;
        height: 52px !important;
        padding: 12px 0 16px;
        &::placeholder {
          color: $text-gray-color;
        }
      }
    }
    [class~='ant-select-selection-item'] {
      height: auto;
      margin: 0 setUnit(4) 0 0;
      display: flex;
      align-items: center;
      color: var(--Grayscale-100, #262626);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.8px; /* 130% */
      letter-spacing: -0.18px;
    }
    & [class~='ant-select-selection-search'] {
      left: 0px !important;
    }
    & [class~='ant-select-arrow'] {
      right: 0px !important;
      div > svg > path {
        stroke: #747475;
      }
    }
  }
}

.addOptionDiv {
  display: flex;
  margin-top: 1.675rem;
  cursor: pointer;

  .addLabel {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
}
