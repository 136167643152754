@import 'styles/functions';
@import 'styles/variables';

$space-around: 1rem;

.repeatContainer {
  margin-top: calc(#{$space-around} * 1.5);
  padding: $space-around;
  border: 1px solid $light-grey-color;
  border-radius: setUnit(8);
  background-color: $background-ligh-gray-5;

  .repeatOn {
    margin-top: $space-around;
  }

  .customRepeat {
    margin-top: $space-around;

    .repeatCounter {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      border: 1px solid $light-grey-color;
      border-radius: setUnit(7);
      height: 52px;

      .counterIcon {
        display: flex;
        height: 100%;
        align-items: center;
        background: $background-grey-2;
        padding: 0.85rem $space-around;
        border-radius: 6px;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
        &.minus {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
        &.plus {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
      .counterText {
        font-size: $space-around;
      }
    }
  }

  .endsContainer {
    margin-top: $space-around;
    .title {
      font-weight: 600;
      margin-bottom: 0;
    }

    .checkContainer {
      display: flex;
      align-items: center;
      margin-top: $space-around;

      .label {
        margin-left: 5px;
      }

      [class*='ant-checkbox-wrapper'] {
        margin-top: setUnit(2);
        font-size: setUnit(16);
        font-weight: 400;
        line-height: setUnit(20);
        letter-spacing: -0.18px;
        align-items: center;
        &:hover {
          [class*='ant-checkbox-inner'] {
            border-color: $primary-color !important;
          }
        }
      }

      [class*='ant-checkbox-disabled'] {
        [class*='ant-checkbox-inner'] {
          border-color: $primary-color !important;
        }
      }

      [class*='ant-checkbox'] {
        top: 0;
        [class*='ant-checkbox-inner'] {
          width: setUnit(24);
          height: setUnit(24);
          border-radius: setUnit(100);
          &:after {
            width: setUnit(6);
            height: setUnit(12);
            border-radius: 0;
            top: 45%;
            left: 28%;
          }
        }
        &::after {
          border-radius: setUnit(100);
        }
      }

      [class*='ant-checkbox-checked'] {
        &::after {
          border-color: $primary-color;
        }

        [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color !important;
        }
      }
    }
  }

  .dateInput {
    [class~='flatpickr-wrapper'] {
      width: 100%;
    }
  }

  .datePicker {
    display: flex;
    position: relative;
    width: 100%;
    margin-left: $space-around;

    .templateControlPicker {
      border-bottom: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      left: 5px;
      cursor: pointer;
      svg {
        position: relative;
        top: unset;
      }
    }
    .disabledPicker {
      cursor: default;
      opacity: 0.7;
    }

    .placeholderContainer {
      padding: 0.5rem 0.75rem;
      border: 1px solid $light-grey-color;
      border-radius: 8px;
      padding-left: 2.5rem;
      font-size: 16px;
      width: 165px;
      min-height: 44px;

      &.disabled {
        background-color: #f9f9f9;
        color: $text-gray-color;
      }
    }
  }

  .occurances {
    .inputField {
      margin-left: setUnit(2);
      width: 56px;
      input {
        height: 44px !important;
      }
    }

    .text {
      margin-left: 10px;
      font-size: 16px;
      &.disabled {
        color: $text-gray-color;
      }
    }
  }
}
