@import 'styles/functions';
@import 'styles/variables';

.mapContainer {
  position: relative;
  height: setUnit(164);
  overflow: hidden;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 6px solid #fff;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  background-color: $white;

  .map {
    height: setUnit(164);

    .mapControls {
      margin: setUnit(18);
    }
  }

  &Expand {
    position: absolute;
    top: setUnit(8);
    right: setUnit(8);
    cursor: pointer;

    svg {
      background-color: white;
    }
  }
}
