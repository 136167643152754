@import 'styles/functions';
@import 'styles/variables';

.links {
  .spacing {
    padding: 1rem;
  }

  .removeIcon {
    display: flex;
    margin-top: setUnit(32);
  }

  .linksContainer {
    width: 100%;
    color: $text-gray-color;
  }

  .editIcon {
    display: flex;
    cursor: pointer;
  }

  .title {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: $black !important;
  }

  .label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 0;
  }

  .addLinkIcon {
    float: right;
  }

  .linkInput,
  .labelInput {
    margin: 0 !important;

    .input {
      padding: 0;
    }
  }

  .removeBtn {
    right: 0.4rem !important;
  }

  .linkWrapper {
    margin-bottom: 1rem;
  }

  .inputBase {
    border-top-width: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    background-color: $white !important;
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    border-radius: 0 !important;
    border-bottom: 2px solid $border-light-gray-2;
  }

  .labelInput {
    margin: 0 !important;

    div > input {
      @extend .inputBase;
    }
  }

  .linkInput {
    margin: 0 !important;
    color: $link-blue;

    div > input {
      @extend .inputBase;
    }
  }

  .addOptionDiv {
    display: flex;
    margin-bottom: 1.5rem;
    cursor: pointer;

    .addLabel {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
    }
  }
}
