@import 'styles/functions';
@import 'styles/variables';

.recommendedToTooltip {
  [class*='ant-tooltip-inner'] {
    border-radius: setUnit(8);
    padding: setUnit(8);
    color: $text-color;
  }
}

.propertyCard {
  cursor: pointer;
  scroll-margin: 80px;
  margin: 14px 0;
  border: 1px;
  width: 100%;
  min-height: 420px;
  border-radius: setUnit(16);
  border: 1px solid $background-ligh-gray-4;
  position: relative;
  height: 420px;

  &.focusedProperty {
    border: 2px solid $primary-color;
  }

  .status {
    position: absolute;
    top: setUnit(16);
    left: setUnit(8);
    max-width: calc(100% - #{setUnit(16)});
  }

  .photo,
  .placeholderImg {
    border-top-right-radius: setUnit(16);
    border-top-left-radius: setUnit(16);
  }

  .photo {
    width: 100%;
    height: 200px;
    @media (min-width: 1000px) and (max-width: 1140px) {
      height: 175px;
    }
    object-fit: cover;
  }

  .placeholderImg {
    background-color: #f4f5f6;
    svg {
      width: 100%;
    }
  }

  .infoContainer {
    padding: 14px 14px 8px 14px;
    .topInfo {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .clientMatch {
        position: absolute;
        top: 0;
        right: 0;
      }
      .price {
        font-weight: 700;
        font-size: setUnit(18);
        columns: $text-color;
      }
    }
    .agentFavorite {
      margin-top: 8px;
      background-color: $white;
      border: 1px solid $light-grey-color;
    }

    .propertyStats {
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: setUnit(6);
      span {
        font-weight: 500;
      }
      .dotSeperator {
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: $text--light-gray-color;
      }
    }
    .address {
      margin-top: setUnit(5);
      .line,
      .city {
        margin: 0;
      }
      .city {
        color: $text-gray-color;
      }
    }
  }

  .lastRow {
    display: flex;
    gap: 20px;
    .statusPills {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 120px);
      max-width: fit-content;
      padding-left: 10px;

      .highlighted {
        background-color: #fff1b1;
        span {
          color: #643a17;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
        }
      }

      .grayText {
        color: $text-color;
        opacity: 0.5;
        white-space: nowrap;
      }

      .openHouseStatus {
        max-width: fit-content;
        width: 270px;
        height: fit-content;
        background: #51bfe11f;
        font-size: 13px !important;
        font-weight: 500;
        border-radius: 8px;
      }
    }
    .actions {
      margin-right: 10px;
      margin-bottom: 10px;
      margin-top: 35px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0;
      .pointerEventsNone {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}

.selected {
  border: 1.5px solid $primary-color;
}

.text {
  white-space: nowrap;
}
