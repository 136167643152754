@import 'styles/functions';
@import 'styles/variables';

.richTextEditor {
  [class*='rdw-inline-wrapper'] {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  border: 1px solid $border-light-gray-2;
  padding: 0.75rem;
  border-radius: 8px;

  .wrapper {
    display: flex;
    flex-direction: column-reverse;
    [class*='rdw-editor-toolbar'] {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      [class*='rdw-option-wrapper'] {
        height: 36px;
        width: 36px;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        border-radius: 3px;
        color: $text--light-gray-color;
        cursor: pointer;
        img {
          height: 100%;
          width: 100%;
        }

        &:hover {
          background-color: $light-gray;
        }

        &[class*='rdw-option-active'] {
          background-color: $light-gray;
        }
      }
    }
  }

  .editor {
    overflow-y: auto;
    height: 200px;
    ul {
      margin-left: 1rem;
    }
  }
}
