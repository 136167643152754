.generateHtml {
  list-style: inside;
  p {
    font-size: 14px !important;
    margin-bottom: 0 !important;
  }
  * {
    background-color: transparent !important;
  }
}
