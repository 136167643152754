@import 'styles/functions';
@import 'styles/variables';

.count {
  color: $text--light-gray-color !important;
}

.homeActionsContainer {
  position: relative;
  border-radius: 16px;
  border: 1px solid $background-grey-2;
  background: $white;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  padding: 24px;
  .price {
    font-weight: 700;
    font-size: setUnit(16);
    line-height: setUnit(20);
    margin-bottom: setUnit(11);
  }

  .address {
    font-weight: 400;
    font-size: setUnit(14);
    line-height: setUnit(20);
    color: $dark-grey-color;
    margin-bottom: setUnit(4);
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    margin-top: setUnit(24);
    gap: 12px;
    @media (min-width: $mobile-small) {
      flex-direction: row;
      margin: setUnit(24) setUnit(-8) 0;
    }
    @media (min-width: $tablet) {
      flex-direction: column;
      width: 100%;
      margin: setUnit(24) 0 0;
    }
    .homeActionsButton {
      flex-grow: 1;
      background: $white;
      border-radius: 12px;
      border: 1px solid $text-gray-color;
      color: $text-color;
      padding: setUnit(12) setUnit(24);
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 100%;
      height: 52px !important;
      &.recommended {
        border: 1px solid var(--Secondary-Orange, $orange) !important;
        background: rgba(251, 145, 58, 0.1) !important;
      }
      @media (min-width: $mobile-small) {
        width: 33.333%;
        padding: setUnit(12);
      }
      @media (min-width: $tablet) {
        width: 100%;
        padding: setUnit(12) setUnit(24);
      }
      &:hover {
        border-color: $text-color;
      }
      &.active {
        border: 1px solid $primary-color !important;
        background: rgba(255, 87, 109, 0.1) !important;
      }
      .buttonTitle {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        font-weight: 400;
        font-size: setUnit(16);
        line-height: setUnit(21);
        letter-spacing: -0.18px;
        color: $dark-color;
        @media (min-width: $mobile-small) {
          font-size: setUnit(14);
          line-height: setUnit(19);
        }
        @media (min-width: $tablet) {
          font-size: setUnit(16);
          line-height: setUnit(21);
        }
        & > .buttonIcon {
          &.recommended {
            svg {
              fill: $orange;
              path {
                stroke: $orange;
              }
            }
          }

          display: flex;
          margin-right: setUnit(8);
          @media (min-width: $mobile-small) {
            margin-right: setUnit(8);
          }
          @media (min-width: $tablet) {
            margin-right: setUnit(8);
          }
          svg {
            width: setUnit(24);
            height: setUnit(24);
            @media (min-width: $mobile-small) {
              width: setUnit(20);
              height: setUnit(20);
            }
            @media (min-width: $tablet) {
              width: setUnit(24);
              height: setUnit(24);
            }
          }
        }
      }
      &.lastButton {
        background: $background-blue;
        padding-left: setUnit(22);
        border: unset;
        .buttonTitle {
          color: $white;
        }
        &:hover {
          border-color: unset;
        }
      }
    }
  }
}

.commentIcon {
  position: relative;
  display: flex;
  align-items: center;

  &.isUnread {
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $primary-color;
      position: absolute;
      top: 1px;
      right: -2px;
      border: 2px solid $background-ligh-gray;
      z-index: 1;
    }
  }

  svg {
    position: relative;
    left: 3px;
    width: setUnit(24);
    height: setUnit(24);
  }
}

.clientsIcon {
  svg {
    width: setUnit(24);
    height: setUnit(24);
  }
}

.docIcon {
  svg {
    path {
      stroke: $white;
    }
  }
}
