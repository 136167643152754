@import 'styles/functions';
@import 'styles/variables';

.vendorModal,
.loaderModal {
  padding: 0;

  [class*='ant-modal-content'] {
    padding: 0 !important;
    box-shadow: 0px 24px 80px 0px #00000033;
    border-radius: setUnit(24);
  }

  [class*='ant-modal-content'] {
    background-color: $background-gray;
  }

  [class*='ant-modal-close'] {
    display: none;
  }

  [class*='ant-modal-body'] {
    min-height: 495px;
  }
}

.loaderModal {
  [class*='ant-modal-body'] {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contentWrapper {
  padding: 32px 40px;

  .header {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-family;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    height: 40px;

    .back {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .hidden {
      opacity: 0;
      pointer-events: none;
    }

    .close {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .innerWrapper {
    max-height: 532px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      opacity: 0;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
      transition: all 0.3s;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: $border-light-gray-2;
      box-shadow: 0 0 1px $background-ligh-gray;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 40px;

    &Centered {
      justify-content: center !important;
    }

    .drafts {
      top: 50%;
      left: 0;
      cursor: pointer;
      color: $secondary-color;
      border-bottom: 1px solid transparent;
      transition: all 0.2s;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $dark-color;

      &Disabled {
        opacity: 0.7;
        cursor: default;
        pointer-events: none;
      }

      &:hover {
        border-color: $secondary-color;
      }
    }

    .addVendorButton {
      padding: 0 60px 0 60px;

      &:hover {
        background-color: $secondary-color-hover;
      }
    }
  }
}

.saveDraftModal {
  position: relative;

  [class*='ant-modal-close'] {
    display: none !important;
  }

  .closeDrafts {
    cursor: pointer;
    position: absolute;
    height: 32px;
    width: 32px;
    right: 36px;
  }

  .draft {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &Icon,
    &Header,
    &Text,
    &Footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &Icon {
      background-color: $background-grey-2;
      border-radius: 50%;
      padding: 12px;
    }

    &Header {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      color: $text-color;
      margin-top: 24px;
      margin-bottom: 16px;
    }

    &Text {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: $dark-color;
      margin-bottom: 24px;
    }

    &Footer {
      justify-content: space-between;
      width: 100%;

      .buttonDiscard {
        background-color: $background-grey-2;
        color: $text-color;
      }

      .footerButton {
        width: 150px;
      }
    }
  }
}
