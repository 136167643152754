@import 'styles/functions';
@import 'styles/variables';

.matchBlock {
  background-color: $white;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid $background-grey-2;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.03);
  margin-top: 24px;
  .sub1 {
    color: $text-color;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20.8px; /* 115.556% */
    letter-spacing: -0.18px;
    margin-bottom: 4px;
  }
  .sub2 {
    color: $dark-grey-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.18px;
    margin-bottom: 16px;
  }
}

.matchPercentage {
  display: flex;
  align-items: center;
  svg {
    margin-right: setUnit(12);
  }
  .title {
    margin-right: setUnit(20);
    margin-bottom: 0;
    font-weight: bold;
    font-size: setUnit(20);
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  .header {
    color: $text-color;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20.8px; /* 115.556% */
    letter-spacing: -0.18px;
    margin-bottom: 24px;
  }
  .insights {
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $text-color;
    }

    .container {
      cursor: pointer;
      width: setUnit(38);
      height: setUnit(28);
      padding: 0.25rem 0.5rem;
      background-color: $background-grey-2;
      display: inline-flex;
      border-radius: 0.5rem;

      span {
        font-weight: 600;
        color: $text-color;
      }
    }
  }
}

.accordion {
  border-top: 1px solid $light-gray;
}

.matchContainer {
  margin-top: 24px;

  .matchContent {
    margin-top: setUnit(27);

    .matchItemsTitle {
      opacity: 0.8;
      color: $text-color;
      margin-bottom: 14px;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 153.846% */
      text-transform: uppercase;
    }
  }

  .grayText {
    font-weight: normal;
    font-size: setUnit(14);
    color: $dark-grey-color;
  }
}

.needsContainer,
.wantsContainer {
  margin-top: setUnit(24);
}

.buyersList {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: setUnit(24);
}

.noMatches {
  text-align: center;
  flex-grow: 1;
  font-weight: 400;
  font-size: setUnit(16);
  line-height: setUnit(20);
  font-family: $font-family;
  letter-spacing: setUnit(-0.18);
  color: $text--light-gray-color;
  margin-top: 4px;
}
