@import 'styles/functions';
@import 'styles/variables';

.emailTemplateModal {
  [class='ant-modal-content'] {
    margin: 4rem 0;
    background-color: $background-gray;
  }
  [class='ant-modal-header'] {
    background-color: transparent;
  }

  .modalHeading {
    color: var(--REBRAND-Grayscale-100, #262626);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.47px;
    margin-bottom: 36px;
  }
  .emailTemplate {
    font-size: 16px;
    line-height: 20px;
    color: $text-color;

    .section {
      background-color: $white;
      border-radius: 16px;
      padding: 20px;
      margin-top: 16px;
      margin-bottom: 16px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .heading {
          font-weight: 600;
        }

        .copy {
          color: $text-gray-color;

          &:hover {
            background-color: $background-grey-2;
          }
        }

        .copied {
          color: $green-color;
          background-color: #04a45114;
        }

        .copy,
        .copied {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
          cursor: pointer;
          border-radius: 12px;
          padding: 8px;

          div {
            display: flex;
          }

          span {
            margin-left: 6px;
          }
        }
      }

      .note {
        display: flex;
        align-items: center;
        padding: 14px 16px;
        border-radius: 12px;
        border: 1px solid var(--grayscale-25-outline, #d6d6d7);
        width: 555px;
        min-height: 48px;
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.8px; /* 130% */
        letter-spacing: -0.18px;
      }

      [class*='rsw-editor'] {
        display: flex;
        width: 560px;
        min-height: 48px;
        max-height: 123px;
        overflow-y: auto;
        padding-right: 5px;
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.8px; /* 130% */
        letter-spacing: -0.18px;
        border-radius: 12px;
        border: 1px solid var(--grayscale-25-outline, #d6d6d7);
        [class*='rsw-ce'] {
          padding: 14px 16px;
          ul {
            margin-left: 1rem;
          }
          &:focus {
            outline: none !important;
          }
        }
      }
    }
  }
  .emailTemplateFooter {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;

    &.flexCenter {
      justify-content: center;
    }

    .removeEmailTemplate {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
    }

    .removeBtnText {
      padding-bottom: 5px;
      color: var(--Tertiary-Error-Red, #ec455e);
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }

    .okBtn {
      width: 180px;
      height: 52px;
      color: var(--REBRAND-Secondary-White, #fff);
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
  }
}
