@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.taskModal {
  [class='ant-modal-content'] {
    padding: 2rem 1rem 2rem 2.5rem;
    margin: 4rem 0;
    background-color: $background-gray;
  }
  [class='ant-modal-header'] {
    background-color: transparent;
  }
}

.taskModalForm {
  .scrollable {
    max-height: calc(100vh - 360px);
    overflow-y: auto;
    padding-right: 8px;

    .mdDisclaimer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 2px;
      margin-top: 14px;
      .mdDisclaimerText {
        color: var(--Grayscale-50, #747475);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.09px;
      }
      .mdDisclaimerIcon {
        display: flex;
      }
    }

    .content {
      margin-bottom: 1rem;
      position: relative;
      background-color: $white;
      padding: setUnit(24);
      border-radius: setUnit(16);
      min-height: fit-content;

      .label {
        font-size: 1rem;
      }

      .rolesWrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 setUnit(-16);

        .fieldHalf {
          width: 50%;
          padding: 0 setUnit(20);
        }
      }
    }

    .options {
      position: absolute;
      background: #ffffff;
      border-radius: 0.5rem;
      width: 100%;
      z-index: 99999;
      bottom: 60px;
      left: 0;
      padding: 8px;
      box-shadow: 0px 32px 80px 0px #0000000f;

      ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
      }
    }

    .moreOptionsContainer {
      position: relative;

      .moreOptionsBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 500;
        padding: 0;
        width: 100%;
        background-color: white;
        height: 56px;
        border-radius: 12px;
        cursor: pointer;
      }
    }

    .tagSelect {
      padding: 0 0.5rem !important;
      border: 1px solid $border-light-gray-2 !important;

      [class*='ant-select-selector'] {
        border: none !important;
      }
      [class*='ant-select-arrow'] {
        right: 10px !important;
      }
    }

    .spacing {
      padding: 1rem;
    }

    .title {
      font-size: 1rem !important;
      font-weight: 600 !important;
      color: $black !important;
    }

    .editIcon {
      display: flex;
    }

    .viewTaskSectionContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: setUnit(12);

      span {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 460px;
        color: $text-gray-color;
      }
    }

    .viewTaskDescriptionSectionContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: setUnit(12);
    }
  }

  .mdDropdownContainer {
    margin-top: 1rem;
  }
}

.transactionTaskForm .content {
  max-height: unset;
}

.taskModalForm,
.taskModal {
  .content,
  .taskModalBody {
    .tagSelectorContainer {
      margin-bottom: 2rem;

      > label {
        margin-bottom: 0.25rem;
      }
    }
  }
}

.taskModal {
  .taskModalBody {
    max-height: calc(100vh - 22.5rem);
    overflow-y: auto;
    margin-bottom: 1rem;
    position: relative;

    .rolesWrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 setUnit(-16);

      .fieldHalf {
        width: 50%;
        padding: 0 setUnit(20);
      }
    }

    .moreOptionsContainer {
      position: relative;

      .moreOptionsBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 500;
        padding: 0;
        width: 100%;
        background-color: white;
        height: 56px;
        border-radius: 12px;
        cursor: pointer;
      }
    }

    .taskModalDetails {
      display: flex;
      padding: 20px 20px 0px 20px;
      flex-direction: column;
      align-self: stretch;
      border-radius: 16px;
      background: #fff;

      .taskModalDetailsHeading {
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
        letter-spacing: -0.09px;
        margin-bottom: 20px;
      }
    }
  }

  [class*='ant-modal-title'] {
    text-align: center !important;
    font-family: $font-family !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }
}

.taskModalFooter {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .footerButton {
    height: 52px;
    width: 152px;
    border-radius: 8px;
  }

  .disabledButton {
    opacity: 0.4;
  }
}
.multiSelectWrapper {
  margin: 0 0 setUnit(24);
  .multiSelect {
    & [class*='ant-select-selector'] {
      min-height: setUnit(40);
      height: auto;
      border: unset !important;
      border-bottom: 2px solid $border-input-middle-gray !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }
  }
}
