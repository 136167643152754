@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.assignToContainer {
  width: 100%;

  .searchContainer {
    margin-top: 24px;
    margin-bottom: 16px;
    background-color: $white;
    padding: 1rem;
    border-radius: setUnit(16);

    .searchInput {
      background-color: $white !important;
      border: 1px solid $border-light-gray-2 !important;
    }
  }

  .assignToList {
    display: block;
    width: 100%;
    padding: 0.75rem 1.5rem;
    border-radius: 16px;
    background-color: $white;

    overflow-y: auto;
    max-height: 40vh;

    @media (min-width: $tablet) {
      overflow-x: hidden;
    }

    .assignToRow {
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .userData {
        display: flex;
        align-items: center;

        .avatar {
          width: 32px !important;
          height: 32px !important;
          margin-right: 12px;
          font-family: $font-family;
          font-size: 14px !important;
          font-weight: 600 !important;
          line-height: 18px !important;
        }

        .userName {
          font-size: 1rem;
          font-weight: 500;
        }
      }

      [class*='ant-checkbox-wrapper'] {
        &:hover {
          [class*='ant-checkbox-inner'] {
            border-color: $primary-color !important;
          }
        }
      }

      [class*='ant-checkbox-checked'] {
        &::after {
          border-color: $primary-color;
        }

        [class*='ant-checkbox-inner'] {
          background-color: $primary-color;
          border-color: $primary-color !important;
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: $light-gray;
      margin: 0.2rem 0;
    }
  }

  .assignToFooter {
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
    width: 100%;

    .submitButton {
      width: 168px;
      height: 52px;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      border-radius: 8px !important;
    }
  }
}
