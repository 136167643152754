@import 'styles/functions';
@import 'styles/variables';

.emptyTemplatesContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: setUnit(8);
  margin-top: setUnit(150);

  svg {
    height: setUnit(45);
    width: setUnit(45);

    * {
      stroke: $border-light-gray-2;
      stroke-width: setUnit(1);
    }
  }
  p {
    color: $border-light-gray-2;
  }
}
