@import 'styles/variables';

.addOptionDiv {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;

  &:hover {
    background-color: $background-gray;
  }
}
