@import 'styles/functions';
@import 'styles/variables';

.links {
  margin-top: setUnit(15);

  .notAvailable {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
  }

  .link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 85%;
    &:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
      color: $link-blue;
    }
  }
}
