@import 'styles/functions';
@import 'styles/variables';

.heading {
  color: $text-color;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.47px;
}

.form {
  position: relative;
  border-radius: 12px;
  background: $background-grey-2;
  padding: 16px;
  gap: 12px;
  margin-bottom: 12px;
  .remove {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
  }
}

.modal {
  & [class*='ant-modal-content'] {
    padding: 40px 0px !important;
  }
}

.content {
  max-height: 75vh;
  overflow-y: auto;
  padding: 0 40px;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  padding: 0 40px;
  .submit {
    width: 124px;
    height: 52px;
    color: $white;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .addContainer {
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
    &.disabled {
      pointer-events: none;
      .addText {
        color: $text--light-gray-color;
      }
    }
    .addIcon {
      height: 24px;
      svg {
        height: 24px !important;
        width: 24px !important;
      }
      &.disabled {
        opacity: 0.6;
      }
    }
    .addText {
      color: $text-color;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.18px;
      margin: 0;
    }
  }
}

.border {
  border-radius: 8px 0 0 8px !important;
  .placeholder {
    border-radius: 8px 0 0 8px !important;
  }
  input {
    border-radius: 8px 0 0 8px !important;
  }
}

.checkbox {
  padding: 20px 0 0 0 !important;
}
