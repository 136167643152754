@import 'styles/variables';
@import 'styles/mixins';

.groupField {
  position: relative;
  width: 100%;
  height: 100%;
}

.grabPoint {
  border-radius: 100%;
  border: 4px solid #4673d1;
  background-color: white;
  transform: scale(0.5);
}
