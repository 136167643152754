@import 'styles/functions';
@import 'styles/variables';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;

    .text {
      font-size: setUnit(20);
      font-weight: 600;
      margin-left: setUnit(20);
    }
  }
}
