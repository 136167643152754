@import 'styles/functions';
@import 'styles/variables';

.emailTemplateSelectorContainer {
  .label {
    font-size: 14px !important;
  }
  .selectionContainer {
    display: flex;
    .emailTemplate {
      cursor: pointer !important;
      margin: 0;
      width: 100%;

      input {
        border: none;
      }

      .emailtTemplateSelectDropdown {
        color: var(--Grayscale-100, #262626);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.8px; /* 130% */
        letter-spacing: -0.18px;
        position: fixed;

        & [class*='ant-select-item-option-selected'] {
          background-color: unset;
          &:hover {
            background-color: #f4f5f6;
          }
        }

        [class*='rc-virtual-list-holder'] {
          overflow-y: auto !important;
        }

        & [class~='ant-select-selection-overflow'] {
          position: absolute;
          [class~='ant-select-selection-overflow-item'] {
            [class~='ant-select-selection-item'] {
              height: auto;
              margin: 0 setUnit(4) 0 0;
              display: flex;
              align-items: center;
              color: var(--Grayscale-100, #262626);
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 20.8px; /* 130% */
              letter-spacing: -0.18px;
            }
          }
        }
      }

      span[class~='ant-select-arrow'] {
        div > svg {
          width: 17px;
          height: 9px;
          path {
            stroke-width: 1.8;
            stroke: #747475;
          }
        }
      }
      &[class~='ant-select-focused'] {
        div[class~='ant-select-selector'] {
          border-color: #747475;
          box-shadow: none !important;
          display: flex !important;
          &:focus {
            border: 1px solid $text-gray-color !important;
          }
        }
      }

      div[class~='ant-select-selector'] {
        border-color: #d6d6d7;
        box-shadow: none !important;
        display: flex !important;

        span[class~='ant-select-selection-item'] {
          display: flex;
          align-items: center;
          color: var(--Grayscale-100, #262626);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20.8px; /* 130% */
          letter-spacing: -0.18px;
          max-width: 450px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      & [class='ant-select-selection-placeholder'] {
        display: flex;
        align-items: center;
      }
    }
    .viewEmailTemplate {
      display: flex;
      align-items: center;
      margin-left: 12px;
      margin-right: 4px;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}

.addOptionDiv {
  display: flex;
  margin-top: 1.675rem;
  cursor: pointer;

  .addLabel {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
}
