@import 'styles/functions';
@import 'styles/variables';

.userFoundModal {
  [class*='ant-modal-content'] {
    padding: 0 !important;
  }

  .userFound {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 284px;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 32px;

    &Avatar {
      height: 56px;
      width: 56px;
    }

    &Header {
      margin-top: 16px;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: $text-color;
    }

    &Text {
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $dark-color;
    }

    &Footer {
      margin-top: 32px;
      display: flex;
      align-items: center;
      width: 100%;

      .button {
        width: 158px;
        font-size: 16px;
        line-height: 20px;

        &Cancel {
          font-weight: 500;
          background-color: $background-grey-2;
          color: $dark-color;
        }

        &View {
          font-weight: 600;
          color: #fff;
          transition: all 0.2s;

          &:hover {
            background-color: $secondary-color-hover;
          }
        }
      }
    }
  }
}
