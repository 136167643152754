@import 'styles/functions';
@import 'styles/variables';

.description {
  .spacing {
    padding: 1rem;
  }

  .contentWrapper {
    width: calc(100% - 54px);
  }

  .label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 0.25rem;
  }

  .title {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: $black !important;
  }

  .editIcon {
    display: flex;
  }

  .textarea {
    background-color: $white !important;
    margin-top: 0.675rem;
    border-radius: 12px !important;
    height: setUnit(100) !important;
    padding: setUnit(16);
    font-size: 16px;

    &:hover {
      border-color: $border-light-gray-2 !important;
    }
    &:focus {
      border-color: $text-gray-color !important;
      box-shadow: none !important;
      outline: 0;
    }
  }

  .addOptionDiv {
    display: flex;
    margin-bottom: 1.5rem;
    cursor: pointer;

    .addLabel {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
    }
  }

  .threeLines {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: horizontal;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 18px;

    & > span {
      overflow: hidden;
      width: 457px;
      height: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      color: var(--Grayscale-80, #515151);
      font-feature-settings: 'liga' off, 'clig' off;
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.18px;
    }
  }
}
