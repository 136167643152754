@import 'styles/functions';
@import 'styles/variables';

.tabs {
  border-radius: 16px;
  background: $white;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  margin-top: 32px;
  .tab {
    width: 100%;
    border-radius: 16px;
    border: 1.5px solid $white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 10px;
    cursor: pointer;
    transition: $transition;
    svg {
      position: relative;
      top: 1px;
    }
    p {
      color: $text-gray-color;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 111.111% */
      letter-spacing: -0.18px;
      margin-bottom: 0;
    }
    &.active {
      border: 1.5px solid $primary-color;
      background: $primary-color-light;
      p {
        color: $text-color;
      }
      svg {
        path {
          stroke: $primary-color;
        }
        circle {
          stroke: $primary-color;
        }
      }
    }
  }
}
