@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.dropdownWrapper {
  display: flex;
  position: relative;
  .optionsDots {
    cursor: pointer;
    & > div {
      display: flex;
      svg {
        width: setUnit(32);
        height: setUnit(32);
        path {
          stroke: $text-gray-color;
        }
      }
    }
  }
  .selectedLabel {
    color: $text-color;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.33px;
  }

  .optionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 2px;
  }
}

.assignedToOptionsPopover {
  [class*='ant-popover-content'] {
    width: 0;
    top: 0.5rem;

    [class*='ant-popover-inner'] {
      background: transparent;
      box-shadow: unset;
    }
  }

  .options {
    @include box-shadow();
    position: absolute;
    top: setUnit(32);
    right: 0;
    background: #ffffff;
    border-radius: setUnit(8);
    min-width: setUnit(180);
    padding: 0;
    z-index: 10;
    ul {
      list-style: none;
      padding: setUnit(8) 0;
      margin-bottom: 0;
      .item {
        padding: setUnit(9) setUnit(12);
        display: flex;
        align-items: center;
        width: max-content;
        min-width: 100%;
        cursor: pointer;
        @include transition();
        .icon {
          margin-right: setUnit(8);
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:hover {
          background: $background-ligh-gray;
        }
      }
    }
  }
}
