@import 'styles/functions';
@import 'styles/variables';

.info {
  margin-left: setUnit(10);

  .names {
    color: $text-color;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
    letter-spacing: -0.33px;
  }

  .address {
    font-size: setUnit(14);
    font-weight: 400;
    margin-top: setUnit(4);
  }
}
