@import 'styles/functions';
@import 'styles/variables';

.dueDateOptionsContainer {
  .optionsTitle {
    font-size: 14px;
    position: absolute;
    top: -63px;
    right: -15px;
    width: 100px !important;
    &:hover {
      background: none !important;
    }
  }

  .optionsBtn {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    color: $text-gray-color;
    padding: 0 4px;
    &:hover {
      color: $text-gray-color;
    }

    .plusIcon {
      display: flex;
      svg {
        height: 15px;
        width: 15px;
        path {
          stroke: $text-gray-color;
        }
      }
    }
  }
}

.dueDateOptionsPopover {
  [class*='options'] {
    top: -45px !important;
    left: -192px !important;
    min-width: 12rem !important;
    [class*='icon'] {
      top: 0 !important;
    }
  }
}
