@import 'styles/functions';
@import 'styles/variables';

.propertyTabContainer {
  word-break: break-word;
  &:last-child {
    border-bottom: unset;
  }
}

.accordion {
  border: 0 !important;
  background: white;
  border-radius: 16px;
  margin: 24px 0;
  padding: 32px;
}

.previewClassName {
  padding: 0 !important;
}

.titleClassName {
  color: $text-color;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.8px; /* 115.556% */
  letter-spacing: -0.18px;
}

.iconContainer {
  background-color: $background-grey-2 !important;
  svg {
    path {
      stroke: $dark-color !important;
    }
  }
}
.heading {
  color: $text-color;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.18px;
  margin: 40px 0 11px 0;
}

.content {
  margin-top: setUnit(32);
  font-size: setUnit(16);
  line-height: setUnit(24);
  color: $dark-color;
  word-break: break-word;
}

.agentInformation {
  color: $dark-color;
  margin-top: setUnit(32);
  font-size: setUnit(16);
  line-height: setUnit(24);

  &Area {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $mobile) {
      flex-direction: column;
    }

    &Section {
      flex: 1;
      width: 50%;

      @media screen and (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  .privateRemarks {
    background-color: $background-gray;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 31px;
  }

  .agentInformationTitle {
    font-weight: 600;
    font-size: setUnit(14);
    line-height: setUnit(16);
    margin-bottom: setUnit(12);
    color: $text-color;
    word-break: normal;
  }

  .agentInformationAgent {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 20px;
    color: $dark-color;
    margin-bottom: setUnit(32);
    .agentInfoItem {
      flex: 0 0 calc(100% - #{setUnit(80)});
      .agentInfoItemInner {
        word-break: break-word;
      }
    }
  }

  .agentInformationAgentAvatar {
    width: setUnit(64);
    height: setUnit(64);
    margin-right: setUnit(16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    background: $secondary-color;
    font-weight: 600;
    font-size: setUnit(20);
    line-height: setUnit(26);
    word-break: break-word;
  }
}

.twoBlocksWrapper {
  display: flex;
  flex-wrap: wrap;

  .twoBlocksBlock {
    width: 50%;
    padding-right: setUnit(20);
  }
}

.descriptionInfo {
  margin-top: setUnit(32);
  font-size: setUnit(14);
  line-height: setUnit(18);
  color: $dark-color;

  span {
    &:not(:last-of-type)::after {
      content: '•';
      padding: 0 setUnit(7);
    }
  }
}

.propertyDisclaimer {
  margin: setUnit(36) 0 0;
  .heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: setUnit(24);
    .icon {
      margin-right: setUnit(6);
      display: flex;
    }
    span {
      font-weight: 500;
      font-size: setUnit(12);
      line-height: setUnit(16);
      letter-spacing: setUnit(-0.09);
      color: $background-blue;
    }
  }
  .content {
    font-weight: 400;
    font-size: setUnit(12);
    line-height: setUnit(16);
    letter-spacing: setUnit(-0.09);
    color: $text-gray-color;
    margin-bottom: 0;
  }

  .mlsLastChecked {
    font-style: italic;
  }
}
