@import 'styles/functions';
@import 'styles/variables';

.searchButton {
  margin: 28px 40px 0px 40px;
  width: calc(100% - 80px);
  height: 52px;
  border-radius: 12px;
  border: 1px solid $light-gray !important;
  background: $background-ligh-gray-5 !important;
  &:hover {
    background: $background-ligh-gray-5 !important;
    border: 1px solid $light-gray-2 !important;
  }
  .buttonTitle {
    color: $text-color !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.18px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 8px;
    align-items: center;
    svg {
      position: relative;
      top: 1px;
      path {
        fill: #ff576d !important;
      }
    }
  }
}

.loaderContainer {
  min-height: 200px;
  display: flex;
  align-items: center;
  .loader {
    display: inline-flex;
    margin: setUnit(25);
    font-size: setUnit(8);
  }
}

.savedSearches {
  margin-top: 30px;
  position: relative;

  & [class*='title'] {
    font-size: 16px;
    font-weight: bold;
  }

  .itemsContainer {
    padding: 0 40px;
    display: flex;
    flex: auto;
    flex-direction: column;
    row-gap: 24px;
    overflow-y: scroll;
    min-height: setUnit(250);
    max-height: calc(100vh - 480px);
    overflow-y: auto;

    &.client {
      max-height: calc(100vh - 520px);
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .count {
        color: $text-color;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.18px;
        margin: 0;
      }
      .button {
        border-radius: 12px;
        border: none;
        background-color: $background-grey-2;
      }
      .dropdown {
        width: 150px;
      }
    }

    .notFound {
      text-align: center;
      margin: 20px 0;
    }

    .cardsContainer {
      width: 100%;
      border-radius: 8px;
      justify-content: space-between;

      .infoDesc {
        overflow: hidden;
        display: flex;
        align-items: center;
      }
    }
  }
}
