@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: setUnit(44);
  margin-left: 0 !important;
  margin-right: 0 !important;

  .cardContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: $white;
    box-shadow: 0 setUnit(2) setUnit(16) rgba(48, 48, 48, 0.1);
    border-radius: setUnit(16);
    text-decoration: none;
    transition: 0.1s all;
    padding: setUnit(15);
    margin: 0 auto;
    cursor: pointer;

    @media (min-width: $mobile) {
      height: 204px;
      max-width: 254px;
      width: 100%;
      padding: 0;
    }
    &:hover {
      opacity: 0.75;
    }

    .cardTitle {
      font-weight: 600;
      line-height: setUnit(20);
      color: $text-color;
      text-decoration: none;
      font-size: setUnit(16);
      @media (min-width: $mobile) {
        font-size: setUnit(18);
      }
    }

    .cardImage {
      font-size: setUnit(20);
      line-height: setUnit(26);
      color: $text-color;
      display: flex;
      justify-content: center;
      align-items: center;
      width: setUnit(50);
      height: setUnit(50);
      margin-bottom: setUnit(16);
      @media (min-width: $mobile) {
        width: setUnit(80);
        height: setUnit(80);
        margin-bottom: 1.5rem;
      }
      img {
        width: setUnit(50);
        height: setUnit(50);
        @media (min-width: $mobile) {
          width: setUnit(80);
          height: setUnit(80);
        }
        object-fit: cover;
      }
    }
  }

  .noServices {
    text-align: center;
    margin: setUnit(40) 0 setUnit(120) 0;
    font-size: setUnit(20);
    line-height: setUnit(26);
    color: $dark-color;
  }
}
