@import 'styles/functions';
@import 'styles/variables';

.filterDrawer {
  .drawerInner {
    position: relative;
    .tabs {
      justify-content: space-between;
      border-bottom: 2px solid $light-gray;
      div[class~='ant-tabs-nav'] {
        &:before {
          content: none;
        }
        div[class~='ant-tabs-nav-wrap'] {
          margin: 0 setUnit(32);
          border-bottom: 2px solid $light-gray;
        }
      }
    }
  }
}

.opacityLayer {
  background: linear-gradient(
    180deg,
    rgba(244, 245, 246, 0.8) 0%,
    rgba(244, 245, 246, 0.4) 50%,
    rgba(244, 245, 246, 0.6) 75%,
    rgba(244, 245, 246, 0.9) 100%
  );
  backdrop-filter: blur(20px);
  background-color: transparent !important;
}

.modal {
  box-shadow: 0 setUnit(24) setUnit(80) rgba(0, 0, 0, 0.2) !important;
  padding: 0;
  max-width: 700px !important;
  max-height: 85vh !important;
  @media screen and (max-height: 1000px) {
    max-height: 95vh !important;
  }
}

.innerHolder {
  padding: 0 !important;
  overflow: hidden !important;
}
