@import 'styles/variables';

.basePartnerProfile {
  & [class*='ant-modal-content'] {
    padding-top: 32px !important;
    padding-bottom: 40px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    overflow: hidden;
    background-color: $background-gray;
  }

  [class*='ant-modal-close'] {
    display: none !important;
  }

  .closeBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 16px;

    &Icon {
      cursor: pointer;
    }
  }
}

.twoPane {
  width: 1000px !important;
}

.onePane {
  width: 675px !important;
}

.avatarWrap {
  .avatar {
    height: 72px;
    width: 72px;
    font-size: 32px;
    line-height: 36px;
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  flex-direction: column;

  .businessName {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $text-color;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    text-align: center;
  }

  .categoriesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .categoriesParagraph {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: $confetti-blue;
      background-color: #4673d114;
      border-radius: 6px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 8px;
      padding-right: 8px;

      &Big {
        margin-bottom: 8px;
        margin-right: 8px;
      }
    }
  }
}

.recommendedBarClient {
  height: 48px;
  width: 100%;
  border-radius: 0.5rem !important;

  & [class*='ant-alert-message'] {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: center !important;
    white-space: pre;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: $text-color;

    .recommendedIcon {
      display: flex;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }
    }
  }

  .recommendedName {
    font-weight: 500;
  }
}

.recommendedBarClient {
  margin-bottom: 20px !important;
}

.recommendedBar,
.recommendedBarClient {
  border: 1px solid $orange !important;
  background-color: rgba(251, 145, 58, 0.08) !important;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(251, 145, 58, 0.12) !important;
  }
}

.alertDisabled {
  opacity: 0.7;
  pointer-events: none;
}

.recommendedBar,
.unRecommendedBar {
  width: 100%;
  border-radius: 0.5rem !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;

  & [class*='ant-alert-message'] {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: center !important;
    white-space: pre;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: $text-color;

    .recommendedIcon {
      display: flex;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }
    }
  }

  .recommendedName {
    font-weight: 500;
  }
}

.unRecommendedBar {
  border: 1px solid $secondary-color !important;
  background-color: #fff !important;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7) !important;
  }

  .unRecommendedIcon {
    display: flex;

    svg {
      height: 20px;
      width: 20px;
      margin-right: 8px;

      path {
        stroke: $dark-color;
        stroke-width: 2;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.partnerBody {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  max-height: 630px;
  overflow-y: auto;
  gap: 20px;

  @media (max-width: $tablet) {
    display: block !important;
  }

  &::-webkit-scrollbar {
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.3s;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $border-light-gray-2;
    box-shadow: 0 0 1px $background-ligh-gray;
  }
}

.pane {
  flex: 1;

  &Left {
    display: flex;
    flex-direction: column;

    .actionsMenu {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      background-color: transparent;
      border: none;

      &Disabled {
        opacity: 0.7;
      }

      [class*='ant-menu-submenu-selected']::after {
        border: none !important;
      }

      li:first-of-type {
        top: 0 !important;
        width: 100% !important;
        padding: 0 !important;
        opacity: 1 !important;
        order: 0 !important;
        height: 48px !important;
        overflow-y: hidden !important;
        pointer-events: auto !important;
        position: relative !important;

        div {
          width: 100%;

          span {
            display: block;
            width: 100%;

            button {
              width: 100%;
              transition: all 0.2s;
              height: 48px;

              &:hover {
                background-color: $secondary-color-hover;
              }

              div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto !important;

                span:first-of-type {
                  margin-right: 4px;
                }

                span {
                  flex: 0;
                }
              }
            }
          }
        }
      }

      li:nth-of-type(2) {
        display: none !important;
      }

      @media (max-width: $tablet) {
        margin-bottom: 20px;
      }
    }

    .clientMessageButton {
      background-color: white;
      color: $dark-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      transition: all 0.2s;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 12px;
      padding-bottom: 12px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.7);
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .clientQuoteButton {
      transition: all 0.2s;
      padding-top: 16px;
      padding-bottom: 16px;

      &:hover {
        background-color: $secondary-color-hover;
      }

      @media (max-width: $tablet) {
        margin-bottom: 20px;
      }
    }
  }

  .detailsSection {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 16px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    .detailIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
      margin-right: 12px;
      border-radius: 50%;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .detailContainer {
      .title {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: $dark-color;
        margin-bottom: 2px;
      }

      .textClamped {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        box-orient: vertical;
        overflow: hidden;
      }

      .textUnclamped {
        -webkit-line-clamp: unset;
        line-clamp: unset;
        display: flex;
      }

      .textBold {
        font-weight: 500 !important;
      }

      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: $text-color;
        word-break: break-word;

        .subText {
          font-weight: 400;
          color: $text--light-gray-color;
        }
      }

      .showAll {
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        color: $link-blue;
        margin-top: 6px;
      }
    }
  }

  .skeleton {
    background-color: $white;
    border-radius: 16px;
  }

  .section {
    background-color: $white;
    border-radius: 16px;
    padding-top: 32px;
    padding-bottom: 52px;
    padding-left: 20px;
    padding-right: 20px;

    .contactParagraphAddress {
      align-items: flex-start !important;
    }

    .contactParagraph {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      word-break: break-word !important;
      color: $text-color;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 14px;
      padding-bottom: 14px;
      margin: 0 !important;

      .contactInner {
        display: flex;
        align-items: center;
        flex: 0 0 setUnit(100);

        span {
          font-weight: 500;
        }
      }

      .contactPhoneText {
        .statuses {
          .dot {
            margin-left: 6px;
            margin-right: 6px;
          }
        }

        input {
          @media (min-width: $mobile) {
            text-align: right;
          }
        }
      }

      .contactAddressText {
        &Line {
          word-wrap: break-word;
          overflow-wrap: break-word;
          white-space: normal;
        }

        @media (min-width: $mobile) {
          text-align: right;
        }
      }
    }

    .greyRow {
      background-color: rgba(244, 246, 251, 0.8);
    }

    .cpDetail {
      flex: 0 0 100%;
      max-width: 425px;
      text-align: right;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 20px;

      &POC {
        width: 65% !important;
        white-space: normal;
      }

      a {
        font-size: 16px;
        color: $text-color;
        letter-spacing: -0.18px;
      }
      @media (min-width: $mobile) {
        flex: 0 0 auto;
      }
      &.website {
        text-align: right;

        .link {
          transition: all 0.2s;

          &:hover {
            color: $link-blue;
          }
        }
      }
    }
  }
}

.vendorActionMenuItem {
  display: flex !important;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: $dark-color !important;
  transition: all 0.2s !important;
  margin: 0 !important;
  height: 56px !important;

  &:first-of-type {
    margin-top: 12px !important;
  }

  &:last-of-type {
    margin-bottom: 12px !important;
  }

  &:hover {
    color: $text-color !important;
    background-color: rgba(244, 246, 251, 0.8);

    .vendorActionMenuItemIcon {
      filter: brightness(120%);
      transform: scale(1.1);
    }
  }

  .unarchiveIcon {
    div {
      position: relative;

      svg {
        position: absolute;
        right: -22px;
        top: -10px;
      }
    }
  }

  &Icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.infoTooltip {
  div[class~='ant-tooltip-content'] {
    div[class~='ant-tooltip-inner'] {
      max-width: 350px;

      p {
        color: white !important;
        white-space: auto;
      }
      .tooltipData {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.18px;

        .fieldTitle {
          font-weight: 500;
        }
      }
    }
  }
}
