@import 'styles/variables';
@import 'styles/mixins';

.icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 6px !important;
}

.iconText {
  width: 12px !important;
  height: 12px !important;
  margin-right: 6px !important;
}

.actionRequiredTextWrap {
  @include layout;
}

.actionRequiredText {
  margin-left: 11px;
  color: $text-color;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.actionRequiredWrap {
  @include layout;
  padding: 0 19px;
  position: absolute;
  left: 56px;
  background-color: $white;
  justify-content: space-between;
  height: 4rem;
  width: 14.5rem;
  box-shadow: 0px 32px 80px 0px rgba(0, 0, 0, 0.06), 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  margin-top: 5px;
}

.dateFieldWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  // font-size: 7cqw;
  font-size: 0.875rem;
}

.fieldText {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 2px;

  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  container-type: size;

  &:has(.checkbox) {
    justify-content: center;
  }
}

.strikeThroughField {
  width: 100%;
  height: 100%;
  container-type: size;
}

.fieldContainer {
  background-color: $white;
  padding: 0;
  z-index: 1000;
  border-radius: 4px;
}

.strikeThroughFieldContainer {
  height: 0px !important;
}

.highlightedField {
  z-index: 99999;

  &:focus-visible {
    outline: none;
  }
}

.grabPoint {
  border-radius: 100%;
  border: 4px solid #4673d1;
  background-color: white;
  transform: scale(0.5);
}

.line {
  @include flexCenter;

  &::after {
    content: '';
    height: 10px;
    width: 10px;
    border: 2px solid #4673d1;
    background-color: white;
    border-radius: 100%;
    position: absolute;
  }
}

.radioRequired::after {
  content: '*';
  color: $error-color;
  position: absolute;
  font-size: 24px;
  right: -5px;
  top: 0;
}

.highlightedRadioRequired::after {
  content: '*';
  color: $error-color;
  position: absolute;
  font-size: 24px;
  right: -20px;
  top: 0;
}
