@import 'styles/functions';
@import 'styles/variables';

.tasks {
  .title {
    padding: setUnit(28) setUnit(32) 0 setUnit(32);
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 28px 32px 0 32px;

    .rightSectionHead {
      display: flex;
      align-items: center;
      gap: setUnit(12);
    }

    .searchWrapper {
      .inputWrap {
        background: $white;
        border: 2px solid $border-light-gray-2;
        border-radius: setUnit(26);
        width: setUnit(44);
        height: setUnit(44);
        font-size: setUnit(14);
        font-weight: 500;
        color: $text--light-gray-color;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          border: 2px solid $text--light-gray-color;
        }
        @media (max-width: 991px) {
          height: setUnit(36);
        }
        &:focus {
          border-width: 2px;
        }
        .icon {
          display: flex;
          cursor: pointer;
          position: relative;
          top: 0;
          left: 0;
          transform: unset;
          svg {
            width: setUnit(20);
            height: setUnit(20);
            path {
              stroke: $dark-color;
            }
          }
        }
        .searchInput {
          background-color: transparent;
          border: 0;
          padding-left: setUnit(8);
          display: none;
          width: 0;
        }
      }
    }
  }

  .taskList {
    padding: 0 32px;
  }

  .multiSelectTaskList {
    height: calc(100vh - $header-height - 108px - 80px);
    overflow: auto;
    padding: 0 32px 32px;
  }

  &.disable {
    opacity: 0.3;
    pointer-events: none;
  }

  .taskHeaderAndContent {
    padding: setUnit(32) setUnit(40) 0 setUnit(32);
    .taskTable {
      padding: 0;
    }
  }
}

.includeFooter {
  height: calc(100vh - $header-height - $footer-height);
}
