@import 'styles/variables';
@import 'styles/mixins';

.actionButtons {
  display: flex;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6px;

  background-color: $text-color;
  border-radius: 8px;
  height: 36px;
  position: relative;

  .actionButton {
    @include flexCenter;
    background-color: #262626;
    width: 36px;
    color: white;
    font-size: 24px;
    cursor: pointer;

    &:last-child {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    &:hover {
      background-color: rgba($dark-color, 0.5);
    }
  }
}

.actionRequiredTextWrap {
  @include layout;
}

.actionRequiredText {
  margin-left: 11px;
  color: $text-color;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: -0.00563rem;
}

.actionRequiredWrap {
  @include layout;
  padding: 0 19px;
  position: absolute;
  left: 56px;
  background-color: $white;
  justify-content: space-between;
  height: 4rem;
  width: 14.5rem;
  box-shadow: 0px 32px 80px 0px rgba(0, 0, 0, 0.06), 0px 8px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  margin-top: 5px;
}

.strikeThroughActionButtons {
  margin-top: 14px;
}
