@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.promotion {
  position: relative;
  color: $text-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  border-radius: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow: hidden;
  background: linear-gradient(
    102deg,
    rgba(81, 191, 225, 0.22) 37.33%,
    rgba(0, 177, 82, 0.22) 119.14%
  );

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: $background-gray;
    border-radius: 50%;
  }

  &::before {
    left: -10px;
  }

  &::after {
    right: -10px;
  }

  .heading {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  .text,
  .expiry {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .text {
    color: $dark-color;
    margin: 10px 50px;
  }
}
