@import 'styles/functions';
@import 'styles/variables';

.serviceAreaActions {
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  position: relative;
  margin-left: 12px;

  &:hover {
    background-color: $background-grey-2;

    .hbIcon {
      fill: $dark-color;
    }
  }

  .hbIcon {
    fill: $text--light-gray-color;
  }

  .menu {
    position: absolute;
    box-shadow: 0px 4px 16px 0px #00000024;
    border-radius: 8px;
    background-color: $white;
    width: max-content;
    top: 28px;
    right: 0;
    z-index: 100;

    .item {
      display: flex;
      align-items: center;
      border-radius: 6px;
      height: 44px;
      width: 160px;

      &:hover {
        background-color: $background-grey-2;
      }

      .icon {
        color: $primary-color;
        margin-left: 16px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: $text-color;
      }
    }
  }
}
