@import 'styles/functions';
@import 'styles/variables';

.assignItem {
  display: flex;
  align-items: center;
  min-height: setUnit(44);

  .assignedToLabel {
    font-size: 14px;
    font-weight: 400;
    color: $dark-color;
    opacity: 80%;
    margin-bottom: 0;
  }

  .avatar {
    width: setUnit(32);
    height: setUnit(32);
  }

  .avatarPlaceholder {
    font-size: setUnit(15);
  }

  .name {
    margin-left: setUnit(16);
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.18px;
    color: $text-color;
  }
}

.multipleAssignItem {
  align-items: flex-start !important;
  margin-top: 1rem;
}

.singleAssignItem {
  [class*='ant-avatar'] {
    font-size: 1rem !important;
  }
}
