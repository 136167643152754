@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.placesAutocompleteWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 11;
  .wrapper {
    .dropdownWrapper {
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 99;
      border-radius: setUnit(8);
      overflow: hidden;
      margin-top: 0.5rem;

      &.menuTop {
        top: auto;
        bottom: 100%;
      }

      .values {
        background-color: #ffffff;
        padding: setUnit(20) setUnit(20) setUnit(12) setUnit(20);
        flex-wrap: wrap;
        height: auto;
        & > div {
          margin-bottom: setUnit(8);
        }
      }
    }

    .suggestions {
      position: relative;
      z-index: 99;
      background: #ffffff;
      border-top: 1px solid $light-gray;
      max-height: 200px;
      overflow-y: auto;

      .searchResults {
        color: $text--light-gray-color;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        text-align: left;
        margin-top: 16px;
        margin-bottom: 8px;
        padding-left: 20px;
        padding-right: 20px;
      }

      .suggestion {
        font-weight: normal;
        min-height: 56px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .option {
      display: flex;
      align-items: center;

      .icon {
        margin-right: setUnit(11);
      }
    }

    &.light,
    &.lightRound {
      .dropdownWrapper {
        @include box-shadow();
      }
      width: 100%;
    }

    &.lightRound input {
      border-radius: 100px;
    }

    &.square {
      input {
        border-radius: 8px;
        width: 100%;
        border: 1px solid $border-light-gray-2;
        height: 48px;
        padding: 10px 16px 10px 44px;
        font-size: setUnit(16);
        line-height: setUnit(24);
        letter-spacing: setUnit(-0.09);
        transition: $transition;
        &:focus {
          outline: 0;
          border-color: $text-gray-color;
        }
        &::placeholder {
          font-size: setUnit(16);
          line-height: setUnit(24);
          letter-spacing: setUnit(-0.09);
          color: $border-middle-gray;
        }
        & + div {
          width: 44px;
          height: 48px;
          position: absolute;
          top: 0;
          left: 0;
          [class~='loader'] {
            margin: 0;
            font-size: 6px;
          }
        }
      }
      .dropdownWrapper {
        @include box-shadow();
      }
    }

    &.round {
      input {
        border-radius: 100px;
        width: 100%;
        border: 1px solid $border-light-gray-2;
        height: 48px;
        padding: 10px 16px 10px 44px;
        font-size: setUnit(16);
        line-height: setUnit(24);
        letter-spacing: setUnit(-0.09);
        transition: $transition;
        &:focus {
          outline: 0;
          border-color: $text-gray-color;
        }
        &::placeholder {
          font-size: setUnit(16);
          line-height: setUnit(24);
          letter-spacing: setUnit(-0.09);
          color: $border-middle-gray;
        }
        & + div {
          width: 44px;
          height: 48px;
          position: absolute;
          top: 0;
          left: 0;
          [class~='loader'] {
            margin: 0;
            font-size: 6px;
          }
        }
      }
      .dropdownWrapper {
        @include box-shadow();
      }
    }

    &.lightFull {
      .dropdownWrapper {
        @include box-shadow();
      }
      width: 100%;
    }

    &.full {
      .dropdownWrapper {
        @include box-shadow();
      }
      width: 100%;
    }
  }
}

.baseLocationInput {
  > input {
    min-height: 36px !important;
  }
}
