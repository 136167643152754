@import 'styles/functions';
@import 'styles/variables';

.messageActionMenu {
  width: 180px;
  border-radius: 8px;
  padding: 0;

  .menuItem {
    display: flex;
    align-items: center;
    padding: setUnit(10) setUnit(22) setUnit(8) setUnit(22);
    height: 40px;

    * {
      font-family: $font-family;
    }

    color: $text-color !important;

    &:hover {
      background: $background-grey-2 !important;
      color: $text-color !important;
    }

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.09px;
  }

  .menuItemSpan {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
  }
}

.messageWrapper {
  width: 100%;
  margin-bottom: setUnit(24);
  padding-right: setUnit(16);

  &:last-child {
    margin-bottom: 0;
  }

  .messageText {
    margin-top: 2px;
    color: var(--REBRAND-Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;

    .emoji {
      font-size: setUnit(25);
      height: setUnit(25);
      vertical-align: middle;
      display: inline-block;
      padding-top: setUnit(2);
    }

    .tagHyperLink {
      color: #2b59da;

      &:hover {
        text-decoration: underline;
        text-decoration-color: #2b59da;
      }
    }

    :global {
      .ant-tooltip-inner {
        border-radius: 16px;
        padding: setUnit(12) setUnit(16) setUnit(12) setUnit(16);
      }
    }
  }

  .attachmentHolder {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .attachmentInner {
    display: flex;
    align-items: center;
  }

  .edit {
    margin-left: setUnit(12);
    // visibility: hidden;
  }

  .messageHolder {
    display: flex;
    align-items: center;
    position: relative;
  }

  .messageInner {
    border-radius: setUnit(16);
    word-break: break-word;
  }

  .message {
    line-height: setUnit(21);
  }

  .avatarWrapper {
    margin-right: setUnit(8);
    position: absolute;
    top: setUnit(3);
    font-size: setUnit(14);
  }

  .avatar {
    width: setUnit(32);
    height: setUnit(32);

    &.hideAvatar {
      visibility: hidden !important;
    }
  }

  .messageOuterHolder {
    display: flex;
    align-items: center;
  }

  .senderName {
    color: var(--REBRAND-Grayscale-100, #262626);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
  }

  .name {
    margin-right: setUnit(4);
    font-weight: 600;
  }

  &:last-child {
    .avatar {
      visibility: visible;
    }
  }

  .messageOuter {
    margin: setUnit(2) 0 setUnit(2) 0;
    padding-left: setUnit(42);

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date {
        font-size: setUnit(12);
        margin-left: setUnit(8);
      }

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        position: absolute;
        right: 0px;
      }

      .messageAction {
        &:hover {
          background: #f4f5f6;
          border-radius: 8px;
        }
      }
    }

    .messageFooter {
      display: flex;
      margin-top: 2px;
      color: var(--REBRAND-Grayscale-50, #747475);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.09px;
      height: 20px;
      display: flex;
      align-items: center;

      .dateIcon {
        margin-left: 8px;
      }
    }
  }

  &.isEditMessage {
    opacity: 0.5;
  }

  .messageHolder {
    padding-right: setUnit(40);
  }

  .messageHolder,
  .attachmentHolder {
    &:hover {
      .edit {
        visibility: visible;

        &.notEditable {
          visibility: hidden;
        }
      }
    }
  }

  &.isOwnMessage {
    .attachmentOuter {
      margin-left: auto;

      &.withActions {
        cursor: pointer;
      }
    }

    .messageOuter {
      &.withActions {
        cursor: pointer;
      }
    }

    .avatarWrapper {
      margin-right: setUnit(8);
    }
  }
}
