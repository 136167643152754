@import 'styles/functions';
@import 'styles/variables';

.photos {
  display: flex;
  align-items: center;

  .address1 {
    color: $text-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.18px;
    margin-bottom: 0;
  }

  .address2 {
    color: $dark-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.09px;
    margin-bottom: 0;
  }

  .inner {
    display: flex;
    align-items: center;
  }

  .imageWrapper {
    position: relative;
    margin-right: setUnit(12);
    margin-left: setUnit(-45);
    border: 2px solid $white;
    border-radius: 10px;

    &:first-child {
      margin-left: 0;
    }

    &.overflow {
      position: relative;

      .overflowCount {
        display: none;
      }

      &:last-child {
        .overflowCount {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 40px;
          height: 40px;
          background: rgba(0, 0, 0, 0.5);
          color: #ffffff;
          z-index: 99;
          border-radius: 8px;
        }
      }
    }

    .photo {
      position: relative;
      width: setUnit(40);
      height: setUnit(40);
      object-fit: cover;
      border-radius: setUnit(8);
      vertical-align: middle;
    }
  }

  .text {
    font-size: setUnit(14);
    font-weight: 400;
    white-space: nowrap;
    margin-left: setUnit(20);

    .count {
      font-weight: 700;
    }
  }
}
