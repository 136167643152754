@import 'styles/functions';
@import 'styles/variables';

.emailTemplateHeader {
  .headerText {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding-bottom: setUnit(16);
    border-bottom: 1px solid $light-gray;
  }
}

.templateContent {
  padding: setUnit(16) 0;

  .marginTop {
    margin-top: setUnit(16);
  }

  .bodyContainer {
    [class*='rdw-editor-main'] {
      height: 320px !important;
    }
  }

  .checkbox {
    margin-top: setUnit(16);

    span {
      font-size: 14px;
    }
  }
}

.templateFooter {
  margin-top: setUnit(16);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .footerBtn {
    height: 52px;
    width: 140px;
    border-radius: setUnit(8);
    &.disabled {
      opacity: 0.8;
    }
  }
}
