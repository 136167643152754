@import 'styles/functions';
@import 'styles/variables';

.clientNotes {
  padding-right: 30px;
  height: 80vh;

  .holder {
    position: relative;
    display: flex;
    height: 95%;
  }

  .avatarPlaceholder {
    font-size: setUnit(14);
  }

  .pendingWrapper {
    height: 100%;

    .loader {
      font-size: setUnit(5);
      margin: setUnit(75) auto;
    }
  }
  .CommentsViewWrapper {
    min-height: 11rem;
    flex: 1;
    overflow-y: scroll;
    height: 100%;
  }
  .messages,
  .pendingWrapper {
    &::-webkit-scrollbar {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $border-light-gray-2;
      }
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .message {
      font-size: setUnit(16);
      font-weight: 100;
      color: $text--light-gray-color;
    }
  }

  .commentsForm {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    .footer {
      max-width: 100%;
      padding: 1rem 0 0 0 !important;
      background: none;
      position: relative;
      align-items: flex-start;
      .footerInner {
        padding: 0 !important;
        height: 0px !important;
      }

      .inputGroup {
        max-width: 100% !important;
        & > div {
          padding-left: 0;
          padding-right: 0;
          & > div {
            height: auto;
            & > div {
              .commentPlaceholder {
                padding-top: 8px !important;
              }

              p {
                font-size: 14px;
                width: 100% !important;
                min-width: 390px;
                margin-bottom: 5px !important;
                padding: 6px 0px 0px !important;
                max-height: 50px;
                min-height: 50px;
                overflow: auto;

                > br {
                  display: none;
                }

                @media (max-width: 768px) {
                  width: 19rem !important;
                  min-width: 19rem !important;
                }
              }
            }
          }
        }
      }
    }
    .footer::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 0.5px;
      background-color: $light-gray;
      box-sizing: border-box;
    }
  }

  .sendButton {
    background: none !important;
    bottom: 0;
    align-items: flex-end !important;
    svg {
      path {
        stroke: $dark-grey-color !important;
      }
    }
  }
}
