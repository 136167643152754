@import 'styles/functions';
@import 'styles/variables';

.requestsWrap {
  border: 1px solid $background-grey-2 !important;
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px #30303014;
  margin-bottom: 40px;
  overflow: hidden;
  padding: setUnit(0);
  background-color: $white;
  padding: 20px 0;
}
.headContainer {
  display: flex;
  position: relative;

  .title {
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    font-size: 20px;
    margin-bottom: 28px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.33px;
    color: $text-color;
    .count {
      color: $text--light-gray-color;
    }
  }
  .headButtons {
    position: absolute;
    right: 0;
    bottom: setUnit(2);
  }
}

.title {
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.33px;
  color: $text-color;
  margin-bottom: 24px;
  .count {
    color: $text--light-gray-color;
  }
}
.buttonReject {
  border-radius: 100px !important;
  border-width: 1px !important;
  .icon {
    display: flex;
  }
}
.noTours {
  margin-bottom: 20px;
  color: #747475;
  font-weight: 400;
  text-align: center;
  margin-top: setUnit(80);
  font-size: setUnit(16);
}
