@import 'styles/variables';
@import 'styles/mixins';

.radioEditorField {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  container-type: size;
  position: relative;

  .radioField {
    width: 80%;
    height: 80%;
    cursor: pointer;
  }

  .disabledRadioInput {
    cursor: not-allowed;
    opacity: 0.3;
  }
}
