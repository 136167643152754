@import 'styles/functions';
@import 'styles/variables';

.inviteModal {
  & [class*='ant-modal-content'] {
    padding: 40px 0;
  }

  .wrapper {
    max-height: 85vh;
    overflow-y: auto;
  }
  [class*='ant-modal-close'] {
    z-index: 1001;
  }
}

.inviteClientContainer {
  position: relative;
  height: calc(100vh - #{setUnit(72)});
  overflow: auto;

  .crossButton {
    height: setUnit(16);
    width: setUnit(16);
    color: $text-color;
  }

  .progressBackground {
    display: none !important;
  }

  .progressBar {
    display: none !important;
  }

  .stagesWrapper {
    height: unset;
  }
}
