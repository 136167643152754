@import 'styles/mixins';
@import 'styles/variables';

.closeBtn {
  height: 100%;
  margin: 0;
  margin-top: 4px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabButton {
  all: unset;
  cursor: pointer;
  height: 64px !important;
  border: none !important;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  font-weight: 500 !important;
  outline: none !important;
  min-width: 56px;

  .darkIcon {
    svg {
      path {
        stroke: $dark-color;
      }
    }
  }

  &.active {
    border-bottom: 2px solid $primary-color !important;
    &:hover {
      border-bottom: 2px solid $primary-color !important;
    }
    .btnText {
      color: $text-color !important;
      font-weight: 600 !important;
      text-align: center !important;
      white-space: nowrap !important;
    }
    svg {
      path {
        stroke: $text-color;
      }
    }
  }

  &:hover,
  &:focus {
    .btnText {
      color: $text-color !important;
      text-align: center !important;
      white-space: nowrap !important;
    }
    svg {
      path {
        stroke: $text-color;
      }
    }
  }

  .btnText {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: $dark-color;
    text-align: center !important;
    white-space: nowrap !important;
  }
}

.opacityControl {
  opacity: 0.7 !important;
  &:hover {
    opacity: 1 !important;
  }
  span {
    color: #252525 !important;
  }
}

.loti {
  position: relative;
  width: 32px;
  height: 32px;
  canvas {
    width: 32px !important;
    height: 32px !important;
  }
  .whiteLayer {
    position: absolute;
    bottom: 0px;
    right: -5px;
    background: #ffff;
    width: 40px;
    height: 3px;
    z-index: 2;
  }
}

.margin {
  margin-right: 16px !important;
}

.criteria {
  margin: 0 24px 0 16px !important;

  [class*='btnText'] {
    opacity: 0.7 !important;
    color: #252525 !important;
  }
  .opacityControl {
    opacity: 0.7 !important;
  }
  &:hover {
    .opacityControl {
      opacity: 1 !important;
    }
    [class*='btnText'] {
      opacity: 1 !important;
    }
  }
}

.closeDiv {
  padding-left: 14px;
}

.clientHeader {
  padding: 12px 40px;
  @media (max-width: 1200px) {
    padding: setUnit(12) 20px;
  }
  @media (max-width: 450px) {
    padding: setUnit(12) 0px;
  }
  border-bottom: 1px solid $light-gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 1500px) {
    justify-content: center;
  }
  width: 100%;

  .tabSection {
    position: relative;
    display: flex;
    align-items: center;

    &.flexEnd {
      justify-content: flex-end;
    }

    &:after {
      content: '';
      position: absolute;
      height: 60%;
      width: 1px;
      background-color: $light-gray;
      top: 20%;
      right: -5px;
    }

    &.feed {
      width: calc(100% - 440px) !important;
      @media (max-width: 1250) {
        width: calc(100% - 390px) !important;
      }
      max-width: 920px;
    }

    &.search {
      width: calc(100% - 640px) !important;
      @media (max-width: 1262px) {
        width: calc(100% - 560px) !important;
      }
      max-width: 765px;
    }
    &.scrollable {
      .arrowSpacing {
        div[class~='slick-prev'] {
          left: 20px !important;
        }
        div[class~='slick-next'] {
          right: 10px !important;
          svg {
            transform: scaleX(-1);
          }
        }
      }

      .subfeedSlider {
        position: relative;
        width: 100%;
        left: 3px;

        .tabButtonContainer {
          display: flex;
          justify-content: center;
        }

        div[class*='slick-slide'] {
          display: flex;
          justify-content: center;
          width: auto !important;
        }

        div[class~='slick-list'] {
          overflow: hidden;
          div[class~='slick-track'] {
            display: flex;
            justify-content: center;
            gap: 44px;
          }
        }

        div[class~='slick-arrow'] {
          opacity: 1;
          visibility: visible;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: $white;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: $transition;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;

          .icon {
            display: flex;
            border: 2px solid #e8e9ea;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            svg {
              width: setUnit(16);
              height: setUnit(16);
              path {
                stroke: $dark-color;
              }
            }
          }
        }
        div[class~='slick-prev'] {
          left: 10px;
          &::after {
            transform: translate(-20px, -20px);
            content: '';
            position: absolute;
            top: 0;
            left: 9px;
            background: linear-gradient(90deg, #fff 70%, rgba(255, 255, 255, 0) 100%);
            z-index: -5;
            height: 80px;
            width: 90px !important;
            opacity: 0.8;
          }
        }
        div[class~='slick-next'] {
          right: 10px;
          &::before {
            transform: translate(-20px, -20px);
            content: '';
            position: absolute;
            top: 0;
            right: -32px;
            background: linear-gradient(270deg, #fff 70%, rgba(255, 255, 255, 0) 100%);
            z-index: -5;
            height: 80px;
            width: 90px !important;
            opacity: 0.8;
          }
          svg {
            transform: scaleX(-1);
          }
        }
        div[class~='slick-disabled'] {
          opacity: 0;
          visibility: hidden;
          display: none;
          transition: $transition;
        }
        .buttonCheckbox {
          position: relative;
          display: inline-flex !important;
          justify-content: center;
          margin-right: 0;
          span[class~='ant-radio'] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $background-grey-2;
            border-radius: setUnit(18);
            transition: $transition;
            span[class~='ant-radio-inner'] {
              display: none;
            }
            & + span {
              position: relative;
              z-index: 1;
              color: $dark-grey-color;
              font-size: setUnit(12);
              font-weight: 500;
              line-height: setUnit(20);
              letter-spacing: setUnit(-0.09);
              padding: setUnit(8) setUnit(14);
              display: block;
              transition: $transition;
            }
          }
          span[class~='ant-radio-checked'] {
            background-color: $background-blue;
            & + span {
              color: $white;
            }
            &:after {
              content: none;
            }
          }
          &:hover {
            span[class~='ant-radio'] {
              background-color: $background-ligh-gray-4;
              & + span {
                color: $dark-grey-color;
              }
            }
          }
        }
      }
    }

    &.noBorder {
      &:after {
        content: none;
      }
    }

    .rightTabSectionBtn {
      flex-direction: row !important;
      gap: setUnit(5);
      margin: 0 0.25rem !important;
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important;

      &:hover {
        border-radius: 8px;
        background-color: $background-ligh-gray-3 !important;
      }

      .btnText {
        font-weight: 600 !important;
        text-align: center !important;
        white-space: nowrap !important;
      }
    }
  }
  .searchInputSection {
    display: flex;
    @media (max-width: 500px) {
      width: 100%;
    }
    margin-right: 20px;
    max-width: 360px;
    .searchContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      min-width: 360px;

      div[class*='geoTagIcon'] {
        svg {
          position: relative;
          right: 7px;
        }
      }

      .formWrapper {
        width: 100%;
        .locationValue {
          border-radius: 30px;
          border: 1.5px solid $light-grey-color;
          background: $background-grey-2;
          color: $dark-color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 153.846% */
          letter-spacing: -0.09px;
        }
      }

      .valuesWrapper {
        box-shadow: none !important;
        border-radius: 12px;
        border: 1px solid $light-grey-color;
      }
    }
  }
}
