@import 'styles/functions';
@import 'styles/variables';

.checklists {
  .spacing {
    margin-bottom: 1rem;
    padding: 1rem;
  }

  .dragMenuList {
    & > div {
      flex-direction: row !important;
    }
  }

  .label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: -0.18px;
    color: $text-color;
    margin-bottom: 0;
    display: inline-block;
  }

  .addChecklistsBtn {
    margin-top: setUnit(12);
    display: flex;
    align-items: center;
    gap: 4px;
    border: none !important;
    padding: 0 !important;
    div {
      display: flex;
    }
  }

  .checklistsInput {
    margin: 0 !important;

    .input {
      padding: 0;
      padding-right: 30px;
    }
  }

  .removeBtn {
    right: 0.4rem !important;
  }

  .editIcon {
    display: flex;
  }

  .addOptionDiv {
    display: flex;
    margin-bottom: 1.5rem;
    cursor: pointer;

    .addLabel {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
      color: $text-color;
    }
  }

  .checklistItem {
    margin-top: setUnit(12);

    .valueInput {
      height: 48px;
    }

    .removeBtn {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .title {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: $black !important;
  }
}
