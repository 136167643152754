@import 'styles/functions';
@import 'styles/variables';

.viewSwitcher {
  padding: 10px 16px;
  background: $white;
  box-shadow: 0px 8px 16px 0px rgba(61, 61, 61, 0.1);
  border-radius: 8px;
  min-width: 8rem;
  height: 44px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  transition: $transition;

  &.active,
  &:focus,
  &:active {
    background: $background-grey-2;
  }

  .icon {
    display: flex;
    align-items: center;
  }
  span {
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $text-color;
  }
  .viewSwitcherDropdown {
    width: setUnit(140);
    div[class~='ant-popover-inner'] {
      background: $white;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
      border-radius: setUnit(8);
      div[class~='ant-popover-inner-content'] {
        padding: setUnit(8) 0;
        .option {
          display: flex;
          align-items: center;
          padding: setUnit(10) setUnit(16);
          cursor: pointer;
          background: $white;
          transition: $transition;
          &:hover {
            background-color: $background-grey-2;
          }
          &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }
          &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }
}
