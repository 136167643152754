@import 'styles/functions';
@import 'styles/variables';

.multipleMode {
  padding-bottom: setUnit(100);
}

.pageWrapper {
  padding: 0px !important;
  background: $background-ligh-gray-3 !important;
}

.wrapper {
  width: 100%;
  min-height: calc(100vh - $header-height - $responsive-footer-height - 143px - 2px);
  @media (min-width: $tablet) {
    min-height: calc(
      100vh - $header-height - $footer-height - $transaction-creation-header-height - 2px
    );
  }
}

.contentContainer {
  width: 100% !important;
  max-width: initial !important;
  margin: initial !important;
  display: initial !important;
}

.btnWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 40px;
  row-gap: 20px;

  @media screen and (max-width: $tablet-horizontal) {
    position: initial;
    bottom: initial;
  }
}

.btnWrapper > button {
  padding: 13px 59px 15px 58px !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.09px;
  height: 52px !important;
  width: 100% !important;
  white-space: nowrap;
}

.addVendorBtn {
  background: $white !important;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);

  & .addVendorBtnTitle {
    color: $dark-color !important;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    white-space: nowrap;
  }
}

.inviteVendorIcon {
  color: $dark-color;
  width: 20px;
  height: 20px;
}

.contentWrapper {
  display: grid;
  grid-template-columns: 23.5% 53% 23.5%;
  margin: 0 auto;

  &Multi {
    margin-bottom: 100px !important;
  }

  & > div:last-child {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  @media screen and (max-width: $tablet-horizontal) {
    grid-template-columns: 1fr;
    padding: 0 1.25rem;
  }
}

.col1 {
  order: 1;
  @media screen and (max-width: $tablet-horizontal) {
    order: 3;
  }
}
.col2 {
  order: 2;
  margin-bottom: 40px;
}
.col3 {
  order: 3;
  @media screen and (max-width: $tablet-horizontal) {
    order: 1;
  }
}
