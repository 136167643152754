@import 'styles/functions';
@import 'styles/variables';

.wrapper {
  height: setUnit(148);
}

.header {
  padding: setUnit(22) setUnit(32) setUnit(32);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .actions {
    display: flex;
    align-items: center;

    .editIcon {
      svg {
        path {
          stroke: $black;
          stroke-width: 1.5px;
        }
      }
    }

    .editIcon,
    .archiveIcon {
      margin-right: setUnit(12);
      cursor: pointer;
      width: setUnit(40);
      height: setUnit(40);
      border-radius: 50%;
      background: $background-grey-2;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.9;
      }

      .svg {
        vertical-align: middle;
      }
    }

    .archiveIcon {
      background: $hover-light-gray;
    }
  }

  .titleWrapper {
    display: flex;
    align-items: center;

    .commentIcon {
      svg {
        width: setUnit(32);
        height: setUnit(32);

        path {
          stroke: $primary-color;
        }
      }
    }

    svg {
      vertical-align: middle;
    }
  }
}

.searchMessages {
  padding: 0 setUnit(32) setUnit(14) setUnit(32);
  display: flex;

  .searchMessagesBtn {
    flex: 1;
    font-size: setUnit(14);
    line-height: setUnit(16);
    margin: 0;
    height: setUnit(40);
    margin-left: setUnit(8);
    background: $hover-light-gray;
    color: #aaabab;
    font-weight: 600;
    // background: $secondary-color;
    // color: $white;
    border-radius: setUnit(8);
    transition: all 0.1s;

    &.active,
    &:hover {
      color: $white;
      background: $secondary-color;
      // color: #262626;
    }
  }

  .searchMessagesInput {
    flex: 3;
  }
}
