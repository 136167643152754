@import 'styles/functions';
@import 'styles/variables';

.roundedCheckbox {
  border-radius: 50% !important;
}

#new-vendor-category-dd {
  input {
    border: 1px solid #7676 !important;
    border-radius: 8px !important;
    padding-left: 8px;
    transition: all 0.2s;

    &:focus {
      border-color: $text-gray-color !important;
    }
  }

  ul {
    height: 200px !important;
  }
}

.ant-dropdown-menu-item {
  border-radius: 16px;
}
