@import 'styles/functions';
@import 'styles/variables';

.formTaskActions {
  .title {
    font-weight: 600;
    font-size: setUnit(16);
  }
  .actionsContainer {
    display: flex;
    flex-direction: column;
    gap: setUnit(16);

    .linkFormBtn,
    .uploadBtn,
    .signFormBtn {
      height: 52px;
      width: 100%;
      border: none !important;
      border-radius: setUnit(12);
      font-size: setUnit(15) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: setUnit(8);
    }

    .uploadBtn {
      background-color: #928cda14 !important;

      .cloudIcon {
        display: flex;
        svg {
          height: 24px;
          width: 24px;
          path {
            stroke: #928cda;
          }
        }
      }

      &:focus,
      &:hover {
        background-color: #8d86d229 !important;
      }
    }

    .linkFormBtn {
      background-color: #fb913a14 !important;
    }

    .signFormBtn {
      background-color: #04a45114 !important;
    }
  }
}
