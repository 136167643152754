@import 'styles/variables';
@import 'styles/mixins';

.PDFFieldOverlay {
  position: absolute !important;
  scroll-margin-top: 6rem;
}

.checkboxField,
.radioField {
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    visibility: visible;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 0.6;
    text-align: center;
  }
}

.checkboxField {
  &::before {
    border: 2px solid var(--border-color);
    border-radius: 3px;
  }
}

.textField {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  border: 2px solid black;
  border-radius: 8px;

  overflow: hidden;
}

.dateBoxContainer {
  overflow: visible;
}

.textBoxField {
  overflow: inherit;
}

.activeTextField {
  border-radius: 8px;
}

.signatureContainer {
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  .clickToSignContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    overflow: hidden;
    font-size: xx-large;
    width: 100%;
    text-align: left;
    padding: 0px 5px;
    color: $text-color;
    border-radius: 8px;

    .signText {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      text-align: left;
      margin-left: 4px;
      color: $text-color;
    }
  }
}

.editorSignContainer {
  height: 100%;
}

.signatureImage {
  position: relative;

  width: 100%;
  height: 100%;

  &:hover {
    .imageEditIcon {
      display: block;
    }
  }
}

.fieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  background-color: $white;
}

.editorViewSignatureFieldContainer {
  background-color: transparent !important;
}

.textFit {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  background-color: $white;
  display: flex;
  align-items: center;

  div {
    white-space: nowrap;
    width: 100% !important;
  }
}

.dateSignedTextFit {
  padding-left: 3px !important;
  height: 100%;
}

.imageEditIcon {
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.editIcon {
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
}

.requiredError {
  border: 3px dotted $error-color !important;
  border-radius: 8px;
}

.required {
  [class~='ant-picker-suffix'] {
    display: none;
  }
}

.required::after {
  content: '*';
  color: $error-color;
  position: absolute;
  font-size: 22px;

  right: 4%;
  top: 60%;
  height: inherit;
  line-height: 0;
}

.requiredPopover {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  gap: 4px;
}

.requiredPopover::before {
  content: '*';
  color: $error-color;
  font-size: 22px;
  line-height: 0;
  margin-top: 5px;
}

.prefixContainer {
  display: flex;
  align-items: center;
  padding: 0 0 0 5px;
  background-color: $white !important;

  input {
    width: -webkit-fill-available;
    border: unset;

    border-radius: 5px;
    left: 13px;
    margin-right: 0px;
    padding-right: 0px;

    &:focus-visible {
      border: none !important;
      outline: none;
    }

    // height: fit-content;
    // line-height: 0px;
  }
}

.currencyEditorField {
  overflow: hidden;
  background-color: transparent !important;
}

.numberContainer {
  &:focus-visible {
    border: none !important;
    outline: none;
  }

  input {
    &:focus-visible {
      border: none !important;
      outline: none;
    }
  }
}

.currencyPrefix {
  z-index: 1;
}

.dateBoxContainer {
  .formDatePicker {
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;

    padding: 0 5px;

    min-height: 100%;
    border: unset;
    box-shadow: unset;
    border-radius: inherit;

    display: block;

    &:focus,
    &:hover {
      border: unset;
      box-shadow: unset;
    }

    [class~='ant-picker-input'] {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      // height: fit-content;
      // line-height: 0px;

      input {
        font-size: inherit;
        position: static;
      }
    }

    [class~='ant-picker-clear'] {
      background: inherit;
      font-size: 14px;
      &:hover {
        color: $dark-color;
        opacity: 1;
      }
    }

    [class~='ant-picker-suffix'] {
      font-size: 14px;
    }
  }
}

.formDatePickerPopup {
  [class*='ant-picker-cell-inner'] {
    font-weight: 500 !important;
  }

  [class*='ant-picker-header'] {
    [class*='ant-picker-header-super-next-btn'] {
      display: inline-block;
    }
  }
}

.phoneNumberField,
.numberField,
.inputTextField {
  border: 0 !important;
  background-color: unset !important;
  width: 100% !important;
  height: 100% !important;
}

.inputTextField {
  resize: none;
  padding-top: 6px;
  border-radius: 0px;
  overflow: visible;
  outline: none;
}

.smallInputTextField {
  padding-top: 3px;
}

.phoneNumberField {
  padding: 0px !important;

  &::placeholder {
    font-size: 13px !important;
    margin-right: 5px;
  }
}

.textFieldTooltip {
  width: fit-content !important;
  height: fit-content !important;
}

.signatureFieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  border-radius: 0px;
  background-color: $white;

  &:hover {
    .signedEditIcon {
      display: block;
    }
  }
}

.dateSignedTextFitFieldContainer {
  display: block;
}

.editorDateSignedField {
  background-color: transparent !important;

  .dateSignedTextFit {
    display: flex;
    align-items: center;
  }

  .editorDateSignedInputField {
    height: unset !important;
    display: block;
    width: fit-content !important;
    input {
      height: unset !important;
      caret-color: transparent;
    }
  }
}

.signedInfo {
  position: absolute;
  width: 100%;
  white-space: nowrap;

  font-size: 10px;

  line-height: 12px;
  letter-spacing: -0.09px;

  color: $text-color;

  overflow: hidden;
  max-width: 98%;
  text-overflow: ellipsis;
  cursor: default;
  z-index: 2;

  div {
    width: fit-content;
    margin: auto;
    background-color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 10px;
  }
}

.signedTop {
  font-weight: 600;
  top: -5px;
}

.signedBottom {
  font-weight: 500;
  bottom: -5px;
}

.numberField,
.inputTextField {
  padding-left: 5px !important;
}

.currencyPrefix {
  font-size: 11px;
}

.actionButtons {
  margin-top: 6px;
  max-width: 196px;
  min-width: 160px;
  width: 100%;

  background-color: #fff;
  border-radius: 8px;

  position: relative;
  box-shadow: 0px 4px 16px 0px #00000024;
  z-index: 99999;

  .actionButton {
    display: flex;
    align-items: center;
    gap: 8px;

    padding: 24px 16px;

    height: 44px;
    cursor: pointer;
    border-radius: inherit;

    &:last-child {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.09px;
      text-align: left;
      color: $text-color;
    }

    &:hover {
      background-color: $background-grey-2;
    }

    @media screen and (max-width: $mobile-small) {
      padding-left: 14px;
      padding-right: 14px;

      height: 34px;
    }
  }
}
