@import 'styles/functions';
@import 'styles/variables';

.searchContainer {
  display: flex;
  padding: 8px;
  border-radius: 72px;
  background: $white;
  box-shadow: 0px 2px 16px 0px rgba(48, 48, 48, 0.08);
  max-width: 810px;
  width: 100%;
  margin: 32px auto 40px auto;
  position: relative;
  @media screen and (max-width: $mobile-small) {
    flex-direction: column;
    border-radius: 1rem;
  }

  @media screen and (max-width: $tablet-horizontal) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &.isResult {
    min-width: 600px;
  }
  .searchItems {
    display: grid;
    column-gap: 40px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding-top: 6px;
    padding-bottom: 11px;
    padding-left: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px !important;
    letter-spacing: -0.09px;
    color: $text-color;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-size: 16px;

    @media screen and (max-width: $mobile-small) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      padding-left: 0;
    }

    &.isResult {
      padding-top: 11px;
    }

    .disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }

    .searchItemContainer {
      position: relative;
      cursor: pointer;

      @media screen and (max-width: $mobile-small) {
        padding: 8px 0;
      }

      &:not(:first-child)::before {
        /* Applying to direct child divs except the first one */
        content: '';
        background-color: $border-light-gray-3;
        height: 100%;
        width: 1px;
        position: absolute;
        left: 0;
        transform: translateX(-20px);

        @media screen and (max-width: $mobile-small) {
          top: 0;
          width: 100%;
          height: 1px;
          transform: none;
        }
      }

      .error {
        color: $error-color;
      }

      .locationItems {
        display: flex;
        column-gap: 6px;
        overflow-y: scroll;

        & div {
          white-space: nowrap;
        }
      }

      .serviceField {
        padding: 0;
        margin: 0;
        width: 300px;

        &.isResult {
          input {
            width: 140px;
          }
        }

        input {
          width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-width: 0;
          padding: 0;
          height: fit-content;
          font-style: normal;
          font-weight: 500;
          line-height: 20px !important;
          letter-spacing: -0.09px;
          color: $text-color;
          font-feature-settings: 'clig' off, 'liga' off !important;
          font-size: 16px;
        }
      }
    }

    .heading {
      font-size: 12px;
      color: $dark-color;
      &.isResult {
        display: none;
      }
    }
    .placeholder {
      color: $text--light-gray-color;
    }
  }

  .searchBtnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;

    .searchBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 64px;
      width: 56px;
      height: 56px;
      background: $primary-color;
      transition: all 0.3s ease;
      transition-property: width, height;

      &.isResult {
        width: 40px;
        height: 40px;
      }
      &.inActive {
        opacity: 0.3;
      }
    }
  }

  .errorMessage {
    position: absolute;
    color: $error-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.09px;
    bottom: -30px;
    left: 32px;
  }
}
