@import 'styles/functions';
@import 'styles/variables';

.rightSectionTabs {
  height: 100%;
  min-height: 500px;
  position: relative;

  &.disabled {
    opacity: 0.5;
  }

  .overlaySheet {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    opacity: 0;
  }

  .taskComments {
    height: 100%;

    .taskCommentsList {
      min-height: 309px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .viewTaskTabs {
    padding: 8px 20px 0px 20px;
    height: 500px;
    background-color: $white;
    border-radius: 12px;

    [class*='ant-tabs-content'],
    [class*='ant-tabs-tabpane'] {
      height: 100%;
    }
    [class*='ant-tabs-tab'] {
      text-shadow: none !important;
    }
    [class*='ant-tabs-tab-active'] {
      text-shadow: none !important;
      .grayText {
        color: $text--light-gray-color;
      }
    }

    .checklistContainer,
    .documentsContainer {
      margin: 12px 0;
    }

    .documentsContainer {
      .noFiles {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 52px 0px 56px 0px;

        color: var(--Grayscale-40, #aaabab);
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        letter-spacing: -0.18px;
      }
      overflow-y: auto;
      height: 415px !important;
      max-height: 415px !important;
      padding-right: 10px;
      margin-right: -10px;
    }

    .checklistContainer {
      .taskViewChecklist {
        height: 415px !important;
        max-height: 415px !important;
      }
    }

    .addFilesBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: setUnit(8);
      width: 100%;
      height: 52px;
      border-radius: 8px;
      margin-top: setUnit(16);

      .btnIcon {
        display: flex;
      }
    }
  }
}
