@import 'styles/functions';
@import 'styles/variables';

.templateItem {
  padding: setUnit(16) 0;
  border-bottom: 1px solid #e8e9ea;

  .subjectHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .subjectText {
      font-weight: 600;
      margin-bottom: setUnit(4);
      font-size: 16px;
    }

    .dotsTitle {
      height: 32px;
      width: 32px;
      .dotsIcon {
        display: flex;
      }
    }
  }

  .bodyText {
    font-weight: 400;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    * {
      opacity: 0.8;
      color: $dark-grey-color !important;
    }
  }

  .publishedTeam {
    margin-top: setUnit(4);
    display: flex;
    align-items: center;
    gap: setUnit(8);

    .icon {
      display: flex;
    }

    span {
      font-weight: 500;
      color: $dark-grey-color;
    }
  }
}

.templateOptions {
  .optionIcon {
    display: flex;
    margin-top: setUnit(2);

    svg {
      height: 18px;
      width: 18px;
      * {
        stroke-width: 2px;
      }
    }
  }
}
