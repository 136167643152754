@import 'styles/functions';
@import 'styles/variables';

.recommendedTo {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  padding: 8px 5px;
  cursor: pointer;
  position: relative;
  bottom: 1px;
  &:hover {
    background: $background-grey-2;
  }
  svg {
    position: relative;
    bottom: 4px;
  }

  .tooltipButton {
    cursor: pointer !important;
    background: transparent;
    border: none;
  }
  .recommended {
    svg {
      fill: $orange;
      height: 22px;
      width: 22px;
      position: relative;
      top: -3px;
      path {
        stroke: $orange;
      }
    }
  }
}

.recommendedToTooltip {
  [class*='ant-tooltip-inner'] {
    border-radius: setUnit(8);
    padding: setUnit(8);
    color: $text-color;
  }
}
